import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import EmptyNotification from '../../assets/JackNotification.svg';

import './Notifications.css';
import Notification from './Notification/Notification';

const notifications = (props) =>{
     let notifications;
     if(props.notifications.length> 0){
        notifications = props.notifications.map(noti => {
            return<Notification
                    key={noti._id}
                    id={noti._id}
                    notifyText={noti.notifyText}
                    link={noti.link}
                    imgLink={noti.imgLink}
                    orderId={noti.orderId}
                    notificationHandler={props.notificationHandler}
                  />
        });
     }else{
         notifications = <div style={{textAlign:"center"}}>
             <img className="baz-empty-notification-img" style={{marginTop:"20%"}} src={EmptyNotification} alt="notifications"/>
             <p>No new notifications</p>
         </div>
     }
    
    return(
        <div className="Notifications">
            <div className="NotiHeader">
                <button onClick={props.notificationHandler}><FontAwesomeIcon icon={faChevronLeft}/></button>
                <h3>Notifications</h3>
            </div>
           <div className="allNotifications">
               {notifications}
           </div>
        </div>
    );
};

export default notifications;