import React from "react";
import "./Loader.css";
import Backdrop from "./LoaderBackdrop";

const spinnerModal = (props) => (
  <div>
    <Backdrop show={props.show} />
    <div
      className="Spinner"
      style={{
        transform: props.show ? "translateY(0)" : "translateY(-100vh)",
        opacity: props.show ? "1" : "0",
      }}
    >
      <div className="Loader" />
    </div>
  </div>
);

export default spinnerModal;
