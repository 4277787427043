import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Logo from "../../../assets/wordmark.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

import * as actionTypes from "../../../store/actions/actionTypes";

import ProcessingModal from "../../../components/Modals/Loader/Loader";
import "./Signin.css";

import { url } from "../../../utlis/server";

class SignIn extends Component {
  state = {
    phone: "",
    password: "",
    passType: "password",
    processing: false,
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  showPasswordHandler = () => {
    if (this.state.passType === "password") {
      this.setState({
        passType: "text",
      });
    } else {
      this.setState({
        passType: "password",
      });
    }
  };
  signInHandler = async (data) => {
    try {
     
      if (this.state.phone && this.state.password) {
        this.setState({
          processing: !this.state.processing,
        });
        const res = await axios.post(url, data);
        this.props.signIn(res.data);
        this.setState({
          processing: !this.state.processing,
        });
      }
    } catch (error) {
      this.setState({
        processing: !this.state.processing,
      });
      throw error;
    }
  };
  keyHandler = async (e, data) => {
    try {
      if (this.state.phone && this.state.password) {
        if (e.keyCode === 13) {
          this.setState({
            processing: !this.state.processing,
          });
          const res = await axios.post(url, data);
          console.log("Response ", res);
          this.props.signIn(res.data);
          this.setState({
            processing: !this.state.processing,
          });
        }
      }
    } catch (error) {
      throw error;
    }
  };
  render() {
    
    const user_data = {
      query: `
                mutation{
                    login(phone:"+88${this.state.phone}", password:"${this.state.password}", deviceMacAddress:"device-mac-address-test2"){
                        success
                        error_message
                        token
                        userId
                        userName
                        cartTotal
                        interests
                        phone
                    }
                }
              `,
    };
    let icon;
    if (this.state.passType === "password") {
      icon = <FontAwesomeIcon icon={faEyeSlash} />;
    } else {
      icon = <FontAwesomeIcon icon={faEye} />;
    }
    return (
      <div className="baz-SignIn">
        {this.props.redirect}
        <ProcessingModal show={this.state.processing} />
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <Link to="/">
            {" "}
            <img className="baz-signin-logo" src={Logo} alt="bazban" />
          </Link>
          <p
            // className="login-text"
            style={{
              margin: "0",
              pading: "30px 5px",
              fontSize: "20px",
              color: "rgb(6, 109, 87)",
            }}
          >
            Log in
          </p>
        </div>
        <div className="baz-signin-phone">
          <input
            type="number"
            placeholder="Mobile number"
            name="phone"
            onChange={this.handleChange}
          />
        </div>
        <div className="baz-signin-pass">
          <input
            onKeyUp={(e) => this.keyHandler(e, user_data)}
            type={this.state.passType}
            placeholder="Password"
            name="password"
            onChange={this.handleChange}
          />
          <button onClick={this.showPasswordHandler}>{icon}</button>
        </div>
        <div style={{ textAlign: "center" }}>
          <p style={{ color: "red", margin: "0" }}>
            {this.props.error_message}
          </p>
          <button
            className="baz-signin-button"
            onClick={() => this.signInHandler(user_data)}
          >
            Log In
          </button>
        </div>
        <p
          style={{
            display: "block",
            padding: "5px 0",
            margin: "0",
            color: "gray",
            textAlign: "center",
          }}
        >
          Haven't account?{" "}
          <Link
            style={{ color: "#028562", margin: "0 5px" }}
            to="/user/sign-up"
          >
            {" "}
            Create account
          </Link>
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error_message: state.auth.login_error,
    auth: state.auth.auth,
    redirect: state.auth.redirectUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signIn: async (data) => {
      try {
        if (data.data.login.success !== true) {
          dispatch({
            type: actionTypes.LOGIN_ERROR,
            message: data.data.login.error_message,
          });
        } else {
          dispatch({
            type: actionTypes.AUTH_SIGNIN,
            userId: data.data.login.userId,
            userName: data.data.login.userName,
            cartTotal: data.data.login.cartTotal,
            interests: data.data.login.interests,
            phone: data.data.login.phone,
          });
          localStorage.setItem("TOKEN", data.data.login.token);
        }
      } catch (error) {
        dispatch({
          type: actionTypes.LOGIN_ERROR,
          message: "Something went wrong!",
        });
      }
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
