import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHeart, faHistory, faCogs, faQuestionCircle, faSignOutAlt,faStar,faServer, faUser} from '@fortawesome/free-solid-svg-icons';

import {Link} from 'react-router-dom';

import './Menu.css';

const menu = (props) =>{
    let auth, logout, privacy;
   if(props.auth){
       privacy =  <li onClick={props.menuModalHandler} className="SubLink">
                     <Link to="/privacy-settings"> <span><FontAwesomeIcon icon={faCogs}/></span> Privacy & settings</Link>
                  </li>;
       auth = <Link  to={"/profile/"+props.userId}>
                  <div className="UserProf">
                       <h1>{props.userName[0]}</h1>
                  </div>
                  <div className="UserInf">
                     <h3 style={{margin:"0", padding:"10px 0 0 0", color:"black"}}>{props.userName}</h3>
                     <p  style={{margin:"0", padding:"0", color:"gray"}}>See your profile</p>
                  </div>
              </Link>
        logout = <Link to="/" onClick={props.logOutHandler}> <span><FontAwesomeIcon icon={faSignOutAlt}/></span> Log out</Link>
   }else{
    auth = <Link className="SignLink" to="/user/sign-in"><span style={{paddingRight:"10px"}}><FontAwesomeIcon icon={faUser}/></span>Log in</Link>;
   }
    return(
        <div className="Menu">
            <ul>
                <li style={{borderBottom:"1px solid #eee"}} onClick={props.menuModalHandler} className="AuthLink">
                  {auth}
                </li>
                <li onClick={props.menuModalHandler} className="SubLink">
                  <Link to="/interests"><span><FontAwesomeIcon icon={faStar}/></span>Interests</Link>
                </li>
                <li onClick={props.menuModalHandler} className="SubLink">
                  <Link to="/orders"><span><FontAwesomeIcon icon={faServer}/></span>Orders</Link>
                </li>
                <li onClick={props.menuModalHandler} className="SubLink">
                  <Link to="/wishlist"> <span><FontAwesomeIcon icon={faHeart}/></span> Wishlist</Link>
                </li >
                <li onClick={props.menuModalHandler} className="SubLink">
                  <Link to="/history"> <span><FontAwesomeIcon icon={faHistory}/></span> History</Link>
                </li>
               {privacy}
                <li onClick={props.menuModalHandler} className="SubLink">
                  <Link to="/contact"> <span><FontAwesomeIcon icon={faQuestionCircle}/></span> Help & support</Link>
                </li>
                <li className="SubLink">
                   {logout}
                </li>
            </ul>
            <a className="baz-other-link" onClick={props.menuModalHandler} href="/terms-conditions" > Terms </a>
            <a className="baz-other-link"onClick={props.menuModalHandler} href="/privacy-policy" > Privacy </a>
            <a className="baz-other-link"onClick={props.menuModalHandler} href="/about" > About </a>
            <a className="baz-other-link" onClick={props.menuModalHandler} href="/contact" > Contact </a>
        </div>
    );
};

export default menu;