import React, { Component } from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';

import OthersSkeleton from '../../components/OthersSleleton/OthersSkeleton';

import './WishList.css';

import SuperbProduct from '../../components/BazProduct3/BazProduct3';

import { url} from '../../utlis/server';

class SuperbProducts extends Component{
    state={
        wishList:[],
        complete: false
    }
 
    componentDidMount = async() =>{
        try {
            window.scrollTo(0,0);
           if(localStorage.TOKEN){
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.TOKEN
              }
            const wishList = await axios.post(
                url,
                {
                    query:`
                       query{
                          userSuperbProudcts{
                                _id
                               product{
                                _id
                                coverImage
                                price
                                title
                                offer
                               }
                             }
                          
                       }
                    `
                },
                {"headers": headers}
            );
           console.log(wishList);
           this.setState({
               wishList: wishList.data.data.userSuperbProudcts,
               complete: true
           });
 
           }
        } catch (error) {
            throw error;
        }
    }
    render(){
        let products, superb;
       if(localStorage.TOKEN){
        if(this.state.complete){
            products = this.state.wishList.map(prod =>{
                return <SuperbProduct
                            key={prod._id}
                            id={prod.product._id}
                            title={prod.product.title}
                            coverImage={prod.product.coverImage}
                            price={prod.product.price}
                            offer={prod.product.offer}
                      />
            });
            this.state.wishList.length > 0 ? 
            superb =  <div className="AllSuperb">
                            <h3>Your wishlist</h3>
                            {products}
                        </div>:
            superb = <div className="EmptySaved">
                        <h3>Your wishlist is empty.</h3>
                        <Link to="/">Explore</Link>
                    </div>;
        }else{
            superb = <OthersSkeleton />
        }

       }else{
           superb = <div className="baz-logout-interest">
                        <h2>Log in to see your Wishlist</h2>
                        <p>Without login wishlist aren't available. To see wishlist log in to your account.</p>
                        <Link to="/user/sign-in" className="baz-interest-login">Log in</Link>
                    </div>;
         }
        return(
            <div className="SuperbProducts">
               {superb}
            </div>
        );
    };
};

export default SuperbProducts;