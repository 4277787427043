import React from "react";

import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./ProductView2.css";
const imageTest ="https://hips.hearstapps.com/vader-prod.s3.amazonaws.com/1631805748-educational-toys-for-toddlers-desk-1631805697.jpg?crop=1xw:1xh;center,top&resize=480:*";
const productView2 = (props) => {
  let offer, price, title;
  if (props.title.length > 20) {
    title = props.title.substring(0, 18) + "...";
  } else {
    title = props.title;
  }

  if (props.offer > 0) {
    offer = (
      <span style={{ fontWeight: "400", fontSize: "14px", color: "#0DA591" }}>
        {" "}
        ( {props.offer}% off )
      </span>
    );
    let offerPrice = props.price - (props.price * props.offer) / 100;
    price = (
      <h4
        className="baz-product2-price"
        style={{ textAlign: "center", margin: "0", padding: "0" }}
      >
        {" "}
        BDT:{" "}
        <span
          style={{
            textDecoration: "line-through",
            color: "gray",
            fontSize: "15px",
          }}
        >
          {props.price}{" "}
        </span>{" "}
        {Math.ceil(offerPrice)} {offer}
      </h4>
    );
  } else {
    price = (
      <h4
        className="baz-product2-price"
        style={{ textAlign: "center", margin: "0", padding: "0" }}
      >
        {" "}
        BDT: {props.price}
      </h4>
    );
  }
  return (
    <Link to={"/product/" + props.id} className="baz-productview-2">
      <div className="baz-productview-2-imagecontainer">
        <LazyLoadImage
          // effect="blur"
          // src={props.coverImage}
          src={imageTest}
          alt={"img"}
        />
      </div>
      <p style={{ textAlign: "center" }}>{title}</p>
      {price}
    </Link>
  );
};

export default productView2;
