import React from 'react';

import Logo from '../../../assets/logo.svg';
import './About.css';
import Footer from '../../Footer/Footer';


const about = () =>{
    return(
        <div className="baz-about">
           <div className="baz-about-header">
              <img src={Logo} alt="bazban"/>
              <p>Our mission is to give freedom and exitement in shopping.</p>
            
           </div>
           <div className="bazban-featured-talk">
               <div  className="bazban-featured-talk-section">
                   <img style={{width:"100%", height:"62%"}}  src={"https://c8.alamy.com/comp/RJG5FX/illustration-of-two-hands-making-a-pinky-promise-by-holding-the-little-fingers-together-RJG5FX.jpg"} alt="img"/>
                  <h1 style={{margin:"0", padding:"5px 15px"}}>We are Promissed</h1>
                  <p>
                     We are promised about our service.We want to make a place where people can shopping in online with exitement and trust.
                 </p>
                  
               </div>
               <div  className="bazban-featured-talk-section">
               <img style={{width:"100%", height:"62%"}}  src={"https://media.istockphoto.com/vectors/dedicated-team-concept-vector-illustration-vector-id1169395479?k=6&m=1169395479&s=612x612&w=0&h=3Nr56eYB2bre-_Sa1DFeax--6FZt5evudLv-KZw8ySY="} alt="img"/>
                  <h1 style={{margin:"0", padding:"5px 15px"}}>We are Dedicated</h1>
                  <p>
                     We are dedicated.We have a team, they are dedicated to giving their all for Bazban.
                      
                   </p>
               </div>
               <div  className="bazban-featured-talk-section">
               <img style={{width:"100%", height:"62%"}}  src={"https://cdn.dribbble.com/users/656667/screenshots/7905544/untitled_artwork_8.jpg"} alt="img"/>
               <h1 style={{margin:"0", padding:"5px 15px"}}>We believe in Truth</h1>
                  <p>
                    We believe in truth. We are hopefull about our ethics and we will make a change in E-commerce.
                   </p>
               </div>
           </div>
           
           <img alt="img" style={{width:"100%"}} src={"https://i.pinimg.com/originals/46/c3/0a/46c30a48535fe7919ccee17a57184e94.png"} />
           {/* <ul>
               <li style={{background:"#0DA591", color:"white"}}>
                   <h1 style={{margin:"0", padding:"0"}}>We are Promissed</h1>
                   <p>
                       We are promised about our service.We want to make a place where people can shopping in online with exitement and trust.
                   </p>
               </li>
               <li style={{background:"#37C4EE", color:"white"}}>
                   <h1 style={{margin:"0", padding:"0"}}>We are Dedicated</h1>
                   <p>
                     We are dedicated.We have a team, they are dedicated to giving their all for Bazban.
                      
                   </p>
               </li>
               <li style={{background:"#EAB12C", color:"white"}}>
                   <h1 style={{margin:"0", padding:"0"}}>We Believe in Truth</h1>
                   <p>
                    We believe in truth. We are hopefull about our ethics and we will make a change in E-commerce.
                   </p>
               </li>
           </ul> */}
           <p className="baz-about-details">
              Online shops (e-commerce) are growing day by day in the world,
              The people of Bangladesh are also accepting it very fast.
              Online shopping can be more attractive if it is possible to deliver a product to the buyer in a short time as per the demand. With this goal ‘Bazban’ started its e-commerce journey. ‘Bazban’  committed to providing the best products and maximum opportunities to the buyers.
           </p>
           
          <Footer />
        </div>
    );
};

export default about;