import React from 'react';

import { Link } from 'react-router-dom';
import './OrderedProduct.css';

const orderedProduct = (props) =>{
  let title = props.product.product.title.substring(0, 20) + "..";
    return(
        <div className="baz-OrderedProduct">
           <Link to={"/product/"+props.product.product._id}>
            <img src={props.product.product.images[0]} alt="porduct"/>
            <div style={{width:"60%", height:"100%", float:"right"}}>
              <p>{title}</p>
              <p >Product code: <span style={{fontWeight:"bold"}}>{props.product.product.productCode}</span></p>
              {props.size}
              <p>Quantity : {props.product.quantity}</p>
            </div>
           </Link>
        </div>
    );
};

export default orderedProduct;