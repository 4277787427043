import React, { Component } from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';

import './UserReturnedOrders.css';
import Order from '../../../components/OrdersSection/Order/Order';
import EmptyList from '../../../components/OrdersSection/EmptyList/EmptyList';
import OrderDetails from '../../../components/Modals/OrderViewModal/OrderViewModal';
import OrderView from '../../../components/OrdersSection/OrderView/OrderView';

import { url} from '../../../utlis/server';

class UserReturnedOrders extends Component{
    state={
        orders:[],
        complete: false,
        order: {},
        orderComplete: false,
        detailsView: false
    }
    componentDidMount = async() =>{
        try {
           if(localStorage.TOKEN){
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.TOKEN
              };
            const orders = await axios.post(
               url,
                {
                    query:`
                        query{
                            userReturnedOrders{
                                _id
                                orderNo
                                total
                                user{
                                  _id
                                  user_name
                                }
                                orderedProducts{
                                  quantity
                                  size
                                  product{
                                    title
                                    _id
                                    images
                                    price
                                  }
                                }
                            }
                        }
                    `
                },
                {"headers": headers}
            );
            this.setState({
                 orders: orders.data.data.userReturnedOrders,
                 complete: true
            });
           }
        } catch (error) {
            throw error;
        }
    };

    orderDetailsHandler = async(id) =>{
        try {
            if(!this.state.detailsView){
                
                const order = await axios.post(
                   url,
                    {
                        query:`
                           query{
                               order(orderId:"${id}"){
                                _id
                                orderNo
                                subTotal
                                deliveryFee
                                total
                                paid
                                date
                                city
                                address
                                country
                                shipped
                                pending
                                confirmed
                                returned
                                delivered
                                contact_name
                                contact_phone
                                user{
                                  _id
                                  user_name
                                }
                                orderedProducts{
                                  quantity
                                  size
                                  product{
                                    title
                                    _id
                                    productCode
                                    images
                                    price
                                  }
                                }
                               }
                           }
                        `
                    }
                );
               this.setState({
                   order: order.data.data.order,
                   orderComplete: true,
                   detailsView: true
               });
                
            }else{
                this.setState({
                    detailsView: false
                });
            }
        } catch (error) {
            throw error;
        }
    };
    render(){
        let orders, header, order;
        if(this.state.complete){
            orders = this.state.orders.map(order => {
                return <Order
                            key={order._id}
                            id={order._id}
                            orderNo={order.orderNo}
                            total={order.total}
                            products={order.orderedProducts}
                            orderDetailsHandler={this.orderDetailsHandler}
                        />
            });
            this.state.orders.length > 0 ? 
            header = <h3 className="baz-all-orders-header" style={{padding:"0 0 10px 0", margin:"0"}}>You have {this.state.orders.length} returned orders</h3>:
            header =<div className="NoOrders">
                <h3>No returned orders</h3>
                <Link to="/">Explore</Link>
            </div>;
        }else{
             orders =<EmptyList />
        }
        if(this.state.orderComplete){
            order = <OrderView
                        orderDetailsHandler={this.orderDetailsHandler}
                        order={this.state.order}
                     />
        }
        return(
            <div className="UserReturnedOrders">
                 <OrderDetails show={this.state.detailsView} orderViewHandler={this.orderDetailsHandler}>
                   {order}
                </OrderDetails>
                {header}
                <div className="AllOrders">
                  {orders}
               </div>
            </div>
        );
    };
};

export default UserReturnedOrders;