import React, { Component } from "react";

import { connect } from "react-redux";
import * as actionTypes from "../../../store/actions/actionTypes";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";

import { LazyLoadImage } from "react-lazy-load-image-component";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import "./SubCategory.css";

import { url } from "../../../utlis/server";
const imageTest =
  "https://wparena.com/wp-content/uploads/2016/08/subcategories.jpg";
class SubCategory extends Component {
  state = {
    interested: this.props.interested,
    redirect: null,
  };
  addInterestHandler = async (id) => {
    try {
      if (localStorage.TOKEN) {
        this.setState({
          interested: true,
        });
        const interest = await axios.post(url, {
          query: `
                      mutation{
                        interestSubCategory(userId:"${this.props.userId}", subId:"${id}"){
                            interests
                        }
                      }
                    `,
        });
        this.props.addInterest(
          interest.data.data.interestSubCategory.interests
        );
      } else {
        this.setState({
          redirect: <Redirect to="/user/sign-in" />,
        });
      }
    } catch (error) {
      throw error;
    }
  };
  removeInterestedHandler = async (id) => {
    try {
      if (localStorage.TOKEN) {
        this.setState({
          interested: false,
        });
        const interest = await axios.post(url, {
          query: `
                          mutation{
                            removeInterestSubCategory(userId:"${this.props.userId}", subId:"${id}"){
                                interests
                            }
                          }
                        `,
        });
        this.props.addInterest(
          interest.data.data.removeInterestSubCategory.interests
        );
      } else {
        this.setState({
          redirect: <Redirect to="/user/sign-in" />,
        });
      }
    } catch (error) {
      throw error;
    }
  };
  render() {
    let button;
    this.state.interested
      ? (button = (
          <button
            onClick={() => this.removeInterestedHandler(this.props.id)}
            className="InterestedButton"
          >
            Interested
          </button>
        ))
      : (button = (
          <button
            onClick={() => this.addInterestHandler(this.props.id)}
            className="NotInterestedButton1"
          >
            <span style={{ padding: "0 10px" }}>
              <FontAwesomeIcon icon={faPlus} />
            </span>
            Interest
          </button>
        ));
    return (
      <div className="BazSubCategory">
        {this.state.redirect}
        <Link
          onClick={() => this.props.subCatSetHandler(this.props.id)}
          to={"/categories/" + this.props.mainCategory}
        >
          {/* <div style={{backgroundImage:`url(${this.props.coverImage})`, width:"100%",position:"relative", height:"68%", marginBottom:"7%",backgroundPosition: 'center', backgroundSize: 'cover',}}>
                     <p className="baz-sub-category-tagname">{this.props.name}</p>
                   </div> */}
          <div className="baz-sub-cover-img">
            <LazyLoadImage
              // src={this.props.coverImage}
              src={imageTest}
              alt={"img"}
            />
          </div>
          <p className="baz-sub-category-tagname">{this.props.name}</p>
        </Link>
        {button}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userId: state.auth.userId,
    interests: state.auth.interests,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    homeSetup: async (data) => {
      dispatch({
        type: actionTypes.HOME_DATA,
        data: data,
      });
    },
    addInterest: (data) => {
      dispatch({
        type: actionTypes.UP_INTEREST,
        userInterests: data,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubCategory);
