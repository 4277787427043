import React, { Component } from 'react';

import axios from 'axios';

import'./UserPrivacyAndSettings.css';

import { url} from '../../utlis/server';

class UserPrivacyAndSettings extends Component{
    state={
        current_pass:'',
        new_pass:'',
        message:''
    }
    handleChange = event => {
        const { name, value} = event.target
        this.setState({ [name]: value})
    }
    passChangeHandler = async() =>{
        try {
          if(this.state.current_pass && this.state.new_pass){
            let headers = {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+localStorage.TOKEN
              }
            const resetPass = await axios.post(
                url,
                {
                    query:`
                        mutation{
                            resetPassword(newPassword:"${this.state.new_pass}", currentPassword:"${this.state.current_pass}"){
                                success
                                message
                            }
                        }
                    `
                },
                {"headers": headers}

            );
            this.setState({
                message: resetPass.data.data.resetPassword.message
            });
          }

        } catch (error) {
            throw error;
        }
    }
    showMacAddress=()=> {
        // eslint-disable-next-line no-undef
        var obj = new ActiveXObject("WbemScripting.SWbemLocator");
        var s = obj.ConnectServer(".");
        var properties = s.ExecQuery("SELECT * FROM Win32_NetworkAdapterConfiguration");
        // eslint-disable-next-line no-undef
        var e = new Enumerator(properties);
        var output;
        output = '<table border="0" cellPadding="5px" cellSpacing="1px" bgColor="#CCCCCC">';
        output = output + '<tr bgColor="#EAEAEA"><td>Caption</td><td>MACAddress</td></tr>';
        while (!e.atEnd()) {
            e.moveNext();
            var p = e.item();
            if (!p) continue;
            output = output + '<tr bgColor="#FFFFFF">';
            // eslint-disable-next-line no-unused-expressions
            output = output + '<td>' + p.Caption; +'</td>';
            output = output + '<td>' + p.MACAddress + '</td>';
            output = output + '</tr>';
        }
        output = output + '</table>';
        document.getElementById("box").innerHTML = output;
    }
    logoutFromOtherDevice = async() =>{
        try {
            this.showMacAddress();
            // let headers = {
            //     'Content-Type': 'application/json',
            //     'Authorization': 'Bearer '+localStorage.TOKEN
            //   }
            // const logoutFromOthersDevices = await axios.post(
            //     url,
            //     {
            //         query:`
            //             mutation{
            //                 logoutFromOtherDevice(currentDevice:"device-mac-address-test2"){
            //                     userId
            //                 }
            //             }
            //         `
            //     },
            //     {"headers": headers}

            // );
            // console.log("logoutFromOthersDevices",logoutFromOthersDevices);
        } catch (error) {
            console.log(error);
        }
    }
    render(){
        return(
            <div className="PrivacySettings">
                <h3> Privacy and settings</h3>
                <div className="ChangePassword"> 
                   <h4 style={{ padding:"15px", margin:"0", textAlign:"center"}}>Change password</h4>
                   <input name="current_pass" onChange={this.handleChange} type="password" placeholder="Current password" />
                   <input name="new_pass" onChange={this.handleChange} type="password" placeholder="New password" />
                   <p>{this.state.message}</p>
                   <button onClick={this.passChangeHandler}>Save changes</button>
                   <button onClick={this.logoutFromOtherDevice} className='logout-from-other-device-button'>Log out from other devices</button>
                </div>
            </div>
        );
    };
};

export default UserPrivacyAndSettings;