import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './OrderView.css';
import OrderedProduct from '../../ControlPanelSection/OrderedProduct/OrderedProduct';

const orderView = (props) =>{
    let orderStatus, button;
    if(props.order.pending){
        orderStatus = <p style={{margin:"0", padding:"5px 20px"}}>Order status : Pending</p>;
        button = <button onClick={()=>props.cancelOrder(props.order._id)} className="baz-or-OrderCancelButton">Cancel order</button>
    }
    if(props.order.confirmed){
        orderStatus = <p style={{margin:"0", padding:"5px 20px"}}>Order status : confirmed</p>;
    }
     if(props.order.shipped){
        orderStatus = <p style={{margin:"0", padding:"5px 20px"}}>Order status : Shipped</p>;
    }
    if(props.order.delivered){
        orderStatus = <p style={{margin:"0", padding:"5px 20px"}}>Order status : Delivered</p>;
    }
    if(props.order.cancelled){
        orderStatus = <p style={{margin:"0", padding:"5px 20px"}}>Order status : Cancelled</p>;
    }
    if(props.order.returned){
        orderStatus = <p style={{margin:"0", padding:"5px 20px"}}>Order status : returned</p>;
    }
    const products = props.order.orderedProducts.map((product, id )=>{
        let size;
        if(product.size){
             size=<p> Size : {product.size}</p>
        }
      return<OrderedProduct
               key={id}
               product={product}
               size={size}
             />
  }); 
    return(
        <div className="OrderView">
            <div className="OrderHeader">
                <h4 style={{float:"left", margin:"0", padding:" 20px"}}>Order NO : {props.order.orderNo}</h4>
                <button onClick={props.orderDetailsHandler} className="baz-or-OrderCancelButton1"><FontAwesomeIcon icon={faTimes}/></button>
            </div>
           <div className="OrderDet">
             {orderStatus}
              <h4 style={{margin:"0", padding:"5px 20px"}}>Ordered products</h4>
              {products}
              <h4 style={{margin:"0", padding:"5px 20px"}}>Total Amount & Payment</h4>
              <p style={{margin:"0", padding:"3px 20px"}}>Sub Total : {props.order.subTotal}</p>
              <p style={{margin:"0", padding:"3px 20px"}}>Delivery Fee : {props.order.deliveryFee}</p>
              <p style={{margin:"0", padding:"3px 20px"}}>Total : {props.order.total}</p>

              <h4 style={{margin:"0", padding:"5px 20px"}}>Shipping Address</h4>
              <p style={{margin:"0", padding:"3px 20px"}}>{props.order.contact_name}</p>
              <p style={{margin:"0", padding:"3px 20px"}}>Phone : {props.order.contact_phone}</p>
              <p style={{margin:"0", padding:"3px 20px"}}>{props.order.address}</p>
              <p style={{margin:"0", padding:"3px 20px"}}>{props.order.city},{props.order.country}</p>
             {button}
           </div>
        </div>
    );
};

export default orderView;