import React from "react";

import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./Related.css";
const imagesTest ="https://m.media-amazon.com/images/I/81rgY3ecZqL._AC_SX679_.jpg";
const related = (props) => {
  let offer, price, title;
  if (props.title.length > 20) {
    title = props.title.substring(0, 20) + "..";
  } else {
    title = props.title;
  }
  if (props.offer > 0) {
    offer = (
      <span style={{ fontWeight: "400", fontSize: "14px", color: "#0DA591" }}>
        {" "}
        ( {props.offer}% off )
      </span>
    );
    let offerPrice = props.price - (props.price * props.offer) / 100;
    price = (
      <h4 className="baz-related-product-price">
        BDT:{" "}
        <span
          style={{
            textDecoration: "line-through",
            color: "gray",
            fontSize: "12px",
          }}
        >
          {props.price}{" "}
        </span>{" "}
        {Math.ceil(offerPrice)}
        {offer}
      </h4>
    );
  } else {
    price = <h4 className="baz-related-product-price"> BDT: {props.price}</h4>;
  }
  return (
    <Link to={"/product/" + props.id} className="baz-0-Related">
      <div className="baz-RelImageContainer">
        <LazyLoadImage
          // src={props.coverImage}
          src={imagesTest}
          alt={"img"}
        />
      </div>
      <p style={{ padding: "10px 0 5px 0", margin: "0", textAlign: "center" }}>
        {/* {title} */}
        Test Title
      </p>
      {price}
    </Link>
  );
};

export default related;
