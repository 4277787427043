import React, { Component } from "react";

import axios from "axios";
import * as actionTypes from "../../store/actions/actionTypes";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faStar,
  faSearch,
  faChevronLeft,
  faServer,
  faTh,
  faHome,
  faCartArrowDown,
  faBell,
  faUser,
  faTimes,
  faArrowLeft,
  faBars,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

import { NavLink, Link, Redirect } from "react-router-dom";
import { io } from "socket.io-client";

import Logo from "../../assets/logo.svg";
import CashOn from "../../assets/cashOn.svg";

import "./Layout.css";
import NotificationModal from "../../components/Modals/NotificationModal/NotificationModal";
import MenuModal from "../../components/Modals/MenuModal/MenuModal";
import CartModal from "../../components/Modals/CartModal/CartModal";
import Cart from "../../components/Cart/Cart";
import Notifications from "../../components/Notificatons/Notifications";
import Menu from "../../components/Menu/Menu";
import CheckOutModal from "../../components/Modals/CheckOutModal/CheckOutModal";
import ProcessingModal from "../../components/Modals/ProcessingModal/ProcessingModal";
import SearchModal from "../../components/Modals/SearchModal/SearchModal";
import BottomNavigation from "../../components/BottomNavigation/BottomNavigation";
import MobileSearchModal from "../../components/Modals/MobileSearchModal/MobileSearchModal";

import { url } from "../../utlis/server";

class Layout extends Component {
  state = {
    complete: false,
    notificationModal: false,
    searchModal: false,
    cartModal: false,
    menuModal: false,
    notifications: [],
    notiDone: false,
    cartProducts: [],
    cartLoad: false,
    del: true,
    pay: false,
    country: "Bangladesh",
    city: "",
    address: "",
    contactName: "",
    contactPhone: null,
    cartTotal: 0,
    checkOutModal: false,
    processing: false,
    mobileSearchModal: false,
    deliveryFee: null,
  };
  componentDidMount = async () => {
    try {
      if (localStorage.TOKEN) {
        const socket = io();
        let headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.TOKEN,
        };
        const user = await axios.post(
          url,
          {
            query: `
                          query{
                              user{
                                  _id
                                  user_name
                                  interests
                                  cartTotal
                                  newNotifications
                                  phone
                              }
                          }`,
          },
          { headers: headers }
        );
        this.props.setupUser(user.data.data.user);
        // this.setState({
        //     contactName: user.data.data.user.user_name,
        //     contactPhone: user.data.data.user.phone.slice(3,14)
        // });
      } else {
        this.props.setupUser2();
      }
      const subCategories = await axios.post(url, {
        query: `
                        query{
                           allSubCategory{
                               _id
                               name
                               mainCategory
                           }
                        }
                    `,
      });
      this.props.allCatSet(subCategories.data.data.allSubCategory);
    } catch (error) {
      this.props.logOut();
      throw error;
    }
  };
  searchModalHandler = () => {
    this.setState({
      searchModal: !this.state.searchModal,
    });
  };
  handleChange = async (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  notificationModalHandler = async () => {
    if (!this.state.notificationModal) {
      this.props.notificationHandle();
      this.setState({
        notificationModal: true,
        searchModal: false,
        cartModal: false,
        menuModal: false,
      });
      const notifications = await axios.post(url, {
        query: `
                       query{
                           userNotifications(userId:"${this.props.userId}"){
                               _id
                               notifyText
                               link
                               imgLink
                               date
                           }
                       }
                    `,
      });
      this.setState({
        notifications: notifications.data.data.userNotifications,
        notiDone: true,
      });
    } else {
      this.setState({
        notificationModal: false,
      });
    }
  };
  menuModalHandler = () => {
    this.setState({
      menuModal: !this.state.menuModal,
      notificationModal: false,
      searchModal: false,
      cartModal: false,
    });
  };
  cartHandler = async () => {
    try {
      if (this.props.auth) {
        if (!this.state.cartModal) {
          this.setState({
            cartModal: true,
            notificationModal: false,
            searchModal: false,
            menuModal: false,
            del: true,
            pay: false,
          });
          const cartProducts = await axios.post(url, {
            query: `
                            query{
                                viewCart(userId:"${this.props.userId}"){
                                    _id
                                    quantity
                                    size
                                    sizeId
                                    productId
                                    product{
                                        _id
                                        title
                                        stock
                                        price
                                        coverImage
                                        price
                                        offer
                                        sizes{
                                            _id
                                          name
                                          quantity
                                        }
                                      }
                                }
                            }
                        `,
          });
          this.setState({
            cartProducts: cartProducts.data.data.viewCart,
            cartLoad: true,
          });
        } else {
          this.setState({
            cartModal: false,
            cartLoad: false,
          });
        }
      } else {
        this.setState({
          cartLoad: true,
          cartModal: !this.state.cartModal,
        });
      }
    } catch (error) {
      throw error;
    }
  };
  mobileSearchHandler = () => {
    this.setState({
      mobileSearchModal: !this.state.mobileSearchModal,
    });
  };
  quantityIncreaseHandler = async (cartId) => {
    try {
      this.setState({
        cartLoad: false,
      });
      const incQuantity = await axios.post(url, {
        query: `
                  mutation{
                    incQuantity(userId:"${this.props.userId}",cartId:"${cartId}"){
                        _id
                        quantity
                        size
                        sizeId
                        productId
                        product{
                            _id
                            title
                            stock
                            price
                            coverImage
                            price
                            offer
                            sizes{
                                _id
                              name
                              quantity
                            }
                          }
                      }
                  }
                `,
      });
      this.setState({
        cartProducts: incQuantity.data.data.incQuantity,
        cartLoad: true,
      });
      let cartTotal = 0;
      this.state.cartProducts.map((cart) => {
        let cartPrice = Math.ceil(
          cart.quantity *
            (cart.product.price -
              (cart.product.price * cart.product.offer) / 100)
        );
        return (cartTotal = cartTotal + cartPrice);
      });
      this.setState({
        cartTotal: cartTotal,
      });
    } catch (error) {
      throw error;
    }
  };
  quantityDecreaseHandler = async (cartId) => {
    try {
      this.setState({
        cartLoad: false,
      });
      const decQuantity = await axios.post(url, {
        query: `
                      mutation{
                        decQuantity(userId:"${this.props.userId}", cartId:"${cartId}"){
                            _id
                            quantity
                            size
                            sizeId
                            productId
                            product{
                                _id
                                title
                                stock
                                price
                                coverImage
                                price
                                offer
                                sizes{
                                    _id
                                  name
                                  quantity
                                }
                              }
                          }
                      }
                    `,
      });
      this.setState({
        cartProducts: decQuantity.data.data.decQuantity,
        cartLoad: true,
      });
      let cartTotal = 0;
      this.state.cartProducts.map((cart) => {
        let cartPrice = Math.ceil(
          cart.quantity *
            (cart.product.price -
              (cart.product.price * cart.product.offer) / 100)
        );
        return (cartTotal = cartTotal + cartPrice);
      });
      this.setState({
        cartTotal: cartTotal,
      });
    } catch (error) {
      throw error;
    }
  };
  removeCartHandler = async (delId, productId) => {
    try {
      const products = this.state.cartProducts.filter((porduct, id) => {
        return delId !== id;
      });
      this.props.cartRemove();
      this.setState({
        cartProducts: products,
      });

      axios.post(url, {
        query: `
                      mutation{
                          deleteCartProduct(productId:"${productId}", userId:"${this.props.userId}"){
                                user_name
                          }
  
                      }
                   `,
      });
    } catch (error) {
      throw error;
    }
  };
  removeAllCartHandler = async () => {
    try {
      this.setState({
        cartProducts: [],
      });
      this.props.emptyCart();
      await axios.post(url, {
        query: `
                     mutation{
                         deleteCart(userId:"${this.props.userId}"){
                              _id
                         }
                     }
                  `,
      });
    } catch (error) {
      throw error;
    }
  };
  notificationModalHandler = async () => {
    if (!this.state.notificationModal) {
      this.props.notificationHandle();
      this.setState({
        notificationModal: true,
        searchModal: false,
        cartModal: false,
        menuModal: false,
      });
      const notifications = await axios.post(url, {
        query: `
                   query{
                       userNotifications(userId:"${this.props.userId}"){
                           _id
                           notifyText
                           link
                           imgLink
                           orderId
                           date
                       }
                   }
                `,
      });
      this.setState({
        notifications: notifications.data.data.userNotifications,
        notiDone: true,
      });
    } else {
      this.setState({
        notificationModal: false,
      });
    }
  };
  logOutHandler = () => {
    this.setState({
      menuModal: !this.state.menuModal,
    });
    this.props.logOut();
  };
  checkOutHandler = () => {
    this.setState({
      cartModal: false,
      checkOutModal: !this.state.checkOutModal,
    });
  };
  deliveryHandler = () => {
    if (
      this.state.city &&
      this.state.address &&
      this.state.contactName &&
      this.state.contactPhone &&
      this.state.contactPhone.length === 11 &&
      this.state.contactPhone[0] === "0"
    ) {
      let cartTotal = 0;
      this.state.cartProducts.map((cart) => {
        let cartPrice = Math.ceil(
          cart.quantity *
            (cart.product.price -
              (cart.product.price * cart.product.offer) / 100)
        );
        return (cartTotal = cartTotal + cartPrice);
      });
      this.setState({
        cartTotal: cartTotal,
      });
      if (this.state.city.toLowerCase() === "sylhet") {
        this.setState({
          deliveryFee: 40,
          del: false,
          pay: true,
        });
      } else {
        this.setState({
          deliveryFee: 80,
          del: false,
          pay: true,
        });
      }
    }
  };
  paymnetHandler = () => {
    this.setState({
      pay: false,
      del: true,
    });
  };
  createOrderHandler = async (delFee) => {
    try {
      const total = this.state.cartTotal + delFee;
      this.setState({
        checkOutModal: false,
        processing: true,
      });

      const queryNew = {
        query: `
        mutation{
           createOrder(
               orderInput:{
                   country:"${this.state.country}",
                   city:"${this.state.city}",
                   address:"${this.state.address}",
                   contact_name:"${this.state.contactName}",
                   contact_phone:"${this.state.contactPhone}",
                   subTotal:${this.state.cartTotal},
                   deliveryFee:${delFee},
                   total:${total}
               },
               userId:"${this.props.userId}"
           ){
               country
           }
        }
       `,
      };
      console.log("Order", queryNew);

      await axios.post(url, {
        query: `
                 mutation{
                    createOrder(
                        orderInput:{
                            country:"${this.state.country.replace(
                              /(?:\r\n|\r|\n)/g,
                              ""
                            )}",
                            city:"${this.state.city.replace(
                              /(?:\r\n|\r|\n)/g,
                              ""
                            )}",
                            address:"${this.state.address.replace(
                              /(?:\r\n|\r|\n)/g,
                              ""
                            )}",
                            contact_name:"${this.state.contactName.replace(
                              /(?:\r\n|\r|\n)/g,
                              ""
                            )}",
                            contact_phone:"${this.state.contactPhone.replace(
                              /(?:\r\n|\r|\n)/g,
                              ""
                            )}",
                            subTotal:${this.state.cartTotal},
                            deliveryFee:${delFee},
                            total:${total}
                        },
                        userId:"${this.props.userId}"
                    ){
                        country
                    }
                 }
                `,
      });

      this.setState({
        processing: false,
        redirect: <Redirect to="/orders" />,
      });
      this.props.createOrder();
    } catch (error) {
      throw error;
    }
  };
  keyHandler = async (e) => {
    try {
      if (this.state.searchText) {
        if (e.keyCode === 13) {
          this.props.searchReady();
          localStorage.setItem("searchKey", this.state.searchText);
          this.setState({
            redirect: <Redirect to={"/results/" + this.state.searchText} />,
            searchModal: false,
            mobileSearchModal: false,
          });
          const results = await axios.post(url, {
            query: `
                      query{
                          search(searchText:"${this.state.searchText}"){
                            _id
                            coverImage
                            title
                            price
                            offer
                          }
                      }
                    `,
          });
          this.props.searchDone(
            results.data.data.search,
            this.state.searchText
          );
        }
      }
    } catch (error) {
      throw error;
    }
  };
  subCatSetHandler = (id) => {
    localStorage.setItem("subCat", id);
  };
  render() {
    let DekstopRight,
      searchSuggestion,
      mobileSearch,
      mobileOptions,
      cart,
      notifications,
      menu,
      delStyle,
      payStyle,
      nextButton,
      total,
      cartClear;

    total = this.state.cartTotal + this.state.deliveryFee;

    if (this.state.del) {
      delStyle = "CheckDel";
      payStyle = "DisplayNone";
    } else {
      delStyle = "DisplayNone";
      payStyle = "CheckPay";
    }
    nextButton = (
      <button onClick={this.deliveryHandler} className="NextButton">
        Next
      </button>
    );
    if (this.props.userSetup) {
      if (this.props.cartItem > 0) {
        cartClear = (
          <button
            className="ClearAllButton"
            onClick={this.removeAllCartHandler}
          >
            Clear All
          </button>
        );
      }
      if (this.props.auth) {
        let notification, mobileNotification, cartItem;
        if (this.props.cartItem > 9) {
          cartItem = "9+";
        } else {
          cartItem = this.props.cartItem;
        }
        if (this.props.notification > 0) {
          if (this.props.notification > 9) {
            notification = <span className="baz-Badge">9+</span>;
            mobileNotification = <span className="baz-Badge-mobile">9+</span>;
          } else {
            notification = (
              <span className="baz-Badge">{this.props.notification}</span>
            );
            mobileNotification = (
              <span className="baz-Badge-mobile">
                {this.props.notification}
              </span>
            );
          }
        }
        DekstopRight = (
          <div className="NavRightAuthOptions">
            <button onClick={this.cartHandler}>
              <span>
                <FontAwesomeIcon icon={faCartArrowDown} />
              </span>
              <span className="baz-Badge">{cartItem}</span>
            </button>
            <button onClick={this.notificationModalHandler}>
              <span>
                <FontAwesomeIcon icon={faBell} />
              </span>
              {notification}
            </button>
            <button
              onClick={this.menuModalHandler}
              style={{ color: "rgb(5, 156, 136)" }}
            >
              {this.props.userName[0]}
            </button>
          </div>
        );
        mobileOptions = (
          <div className="baz-mobile-right-options">
            <button
              style={{ margin: "11px 10px 0 15px" }}
              onClick={this.cartHandler}
            >
              <span>
                <FontAwesomeIcon icon={faCartArrowDown} />
              </span>
              <span className="baz-Badge-mobile">{cartItem}</span>
            </button>
            <button onClick={this.notificationModalHandler}>
              <span>
                <FontAwesomeIcon icon={faBell} />
              </span>
              {mobileNotification}
            </button>
            <button onClick={this.mobileSearchHandler}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        );
      } else {
        DekstopRight = (
          <div style={{ height: "100%", float: "right", marginTop: "10px" }}>
            <button
              onClick={this.cartHandler}
              style={{
                padding: "10px 13px",
                margin: "0px 7px 0 0",
                outline: "none",
                fontSize: "17px",
                cursor: "pointer",
                // fontWeight: "bold",
                // borderRadius: "100%",
                border: "none",
                background: "none",
              }}
            >
              My Cart
              <FontAwesomeIcon icon={faCartArrowDown} />
            </button>
            <Link to="/user/sign-in" className="baz-access-link">
              Log in
            </Link>
            <Link to="/user/sign-up" className="baz-access-link">
              Sign up
            </Link>
          </div>
        );
        mobileOptions = (
          <div className="baz-mobile-right-options">
            <Link
              to="/user/sign-in"
              style={{
                float: "right",
                padding: "13px 0 0 20px",
                color: "rgb(117, 114, 114)",
                fontSize: "20px",
              }}
            >
              <FontAwesomeIcon icon={faUser} />
            </Link>
            <button onClick={this.cartHandler}>
              <span>
                <FontAwesomeIcon icon={faCartArrowDown} />
              </span>
            </button>
            <button onClick={this.mobileSearchHandler}>
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        );
      }

      menu = (
        <Menu
          auth={this.props.auth}
          userId={this.props.userId}
          userName={this.props.userName}
          menuModalHandler={this.menuModalHandler}
          logOutHandler={this.logOutHandler}
        />
      );
    } else {
      DekstopRight = (
        <div style={{ height: "100%", float: "right" }}>
          <button
            style={{
              padding: "20px",
              margin: "12px 0 0 7px",
              borderRadius: "100%",
              outline: "none",
              border: "none",
            }}
          ></button>
          <button
            style={{
              padding: "20px",
              margin: "7px 0 0 7px",
              borderRadius: "100%",
              outline: "none",
              border: "none",
            }}
          ></button>
          <button
            style={{
              padding: "20px",
              margin: "7px 0 0 7px",
              borderRadius: "100%",
              outline: "none",
              border: "none",
            }}
          ></button>
        </div>
      );
      mobileOptions = <div className="baz-mobile-right-options"></div>;
    }
    if (this.state.cartLoad) {
      cart = (
        <Cart
          auth={this.props.auth}
          cartProducts={this.state.cartProducts}
          cartItem={this.props.cartItem}
          checkOutHandler={this.checkOutHandler}
          removeCart={this.removeCartHandler}
          removeAllCartHandler={this.removeAllCartHandler}
          cartHandler={this.cartHandler}
          quantityIncreaseHandler={this.quantityIncreaseHandler}
          quantityDecreaseHandler={this.quantityDecreaseHandler}
          notAvailProducts={this.state.notAvailProducts}
        />
      );
    } else {
      cart = (
        <div style={{ width: "100%", height: "100%" }}>
          <div className="baz-skeleton-cart-left-view">
            <div
              style={{
                width: "40%",
                height: "100%",
                background: "#EFF3EF",
                float: "left",
              }}
            ></div>
            <div style={{ width: "57%", height: "100%", float: "right" }}>
              <div
                style={{
                  width: "100%",
                  height: "35px",
                  marginBottom: "5px",
                  background: "#EFF3EF",
                }}
              ></div>
              <div
                style={{
                  width: "100%",
                  height: "35px",
                  marginBottom: "5px",
                  background: "#EFF3EF",
                }}
              ></div>
            </div>
          </div>
          <div className="baz-skeleton-cart-left-view"></div>
          <div className="baz-skeleton-cart-left-view"></div>
          <div className="baz-skeleton-cart-left-view"></div>
        </div>
      );
    }
    if (this.state.notiDone) {
      notifications = (
        <Notifications
          notificationHandler={this.notificationModalHandler}
          notifications={this.state.notifications}
        />
      );
    }
    if (this.props.subCatComplete) {
      const allCats = this.props.allSubCategory.map((sub) => {
        return (
          <li onClick={() => this.subCatSetHandler(sub._id)} key={sub._id}>
            <a
              onClick={this.searchModalHandler}
              href={"/categories/" + sub.mainCategory}
            >
              <span style={{ paddingRight: "5px" }}>
                <FontAwesomeIcon icon={faSearch} />
              </span>
              {sub.name}
            </a>
          </li>
        );
      });
      searchSuggestion = (
        <div className="SearchSugguest">
          <h4 style={{ padding: "0 0 10px 0", margin: "0" }}>Explore</h4>
          {allCats}
        </div>
      );
      mobileSearch = (
        <div style={{ width: "100%", height: "100%" }}>
          <div
            style={{
              width: "100%",
              height: "52px",
              background: "white",
              borderBottom: "1px solid rgb(199, 199, 199)",
            }}
          >
            <button
              onClick={this.mobileSearchHandler}
              style={{
                float: "left",
                padding: "10px",
                margin: "5px 0 0 5px",
                fontSize: "18px",
                border: "none",
                outline: "none",
                background: "none",
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <input
              onKeyUp={(e) => this.keyHandler(e)}
              onChange={this.handleChange}
              name="searchText"
              style={{
                float: "right",
                width: "77%",
                padding: "10px 12px",
                margin: "8px 5px 0 0",
                outline: "none",
                background: "#F3F1F1",
                border: "none",
                borderRadius: "25px",
                fontSize: "15px",
              }}
              type="text"
              placeholder="Search Bazban"
            />
          </div>
          <div className="MobileSuggest">
            <h4 style={{ padding: "5px 0 10px 0", margin: "0" }}>Explore</h4>
            {allCats}
          </div>
        </div>
      );
    }
    return (
      <div style={{ width: "100%", height: "auto" }}>
        {this.state.redirect}
        <NotificationModal
          show={this.state.notificationModal}
          notificationHandler={this.notificationModalHandler}
        >
          {notifications}
        </NotificationModal>
        <MobileSearchModal
          show={this.state.mobileSearchModal}
          searchHandler={this.mobileSearchHandler}
        >
          {mobileSearch}
        </MobileSearchModal>
        <MenuModal
          show={this.state.menuModal}
          menuHandler={this.menuModalHandler}
        >
          {menu}
        </MenuModal>
        <CartModal show={this.state.cartModal} cartHandler={this.cartHandler}>
          <div className="baz-cart-header">
            <div
              style={{
                width: "60%",
                height: "80%",
                float: "left",
                marginTop: "10px",
              }}
            >
              <span
                onClick={this.cartHandler}
                className="baz-cart-mobile-back-2"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
              <span
                style={{
                  padding: "0 10px",
                  fontSize: "20px",
                  fontWeight: "bold",
                  margin: "0",
                }}
              >
                Cart
              </span>
            </div>
            {cartClear}
          </div>
          <div className="baz-cart-body">{cart}</div>
        </CartModal>
        <ProcessingModal show={this.state.processing}></ProcessingModal>
        <CheckOutModal show={this.state.checkOutModal}>
          <div style={{ width: "100%", height: "100%" }}>
            <div className="CheckOutHeader">
              <h3>Checkout</h3>
              <button onClick={this.checkOutHandler}>
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <div className="baz-chcekout-form">
              <div className={delStyle}>
                <h4
                  style={{
                    padding: "10px 0",
                    margin: "0",
                    textAlign: "center",
                  }}
                >
                  Delivery Information
                </h4>
                <h5>Country</h5>
                <button className="SelectButton">
                  <span style={{ float: "left", paddingLeft: "10px" }}>
                    Bangladesh
                  </span>
                </button>
                <h5>City / Town</h5>
                <input
                  type="text"
                  placeholder="Your city name"
                  name="city"
                  onChange={this.handleChange}
                />
                <h5>Address</h5>
                <textarea
                  type="text"
                  placeholder="Area name, road no, house no."
                  name="address"
                  onChange={this.handleChange}
                />
                {/* <h3 style={{padding:"10px 0", margin:"0", textAlign:"center"}}>Contact Details</h3> */}
                <h5>Contact name</h5>
                <input
                  type="text"
                  placeholder="Contact name"
                  name="contactName"
                  onChange={this.handleChange}
                />
                <h5>Contact number</h5>
                <input
                  type="tel"
                  placeholder="Mobile number"
                  name="contactPhone"
                  onChange={this.handleChange}
                />
                {nextButton}
              </div>
              <div className={payStyle}>
                <h4
                  style={{
                    padding: "10px 0 30px 0",
                    margin: "0",
                    textAlign: "center",
                  }}
                >
                  Payment
                </h4>
                <div
                  style={{ width: "100%", height: "30px", marginBottom: "5px" }}
                >
                  <h5 style={{ float: "left", margin: "0", padding: "0" }}>
                    Sub total
                  </h5>
                  <p style={{ float: "right", margin: "0", padding: "0" }}>
                    BDT : {this.state.cartTotal}
                  </p>
                </div>
                <div
                  style={{ width: "100%", height: "30px", marginBottom: "5px" }}
                >
                  <h5 style={{ float: "left", margin: "0", padding: "0" }}>
                    Delivery fee
                  </h5>
                  <p style={{ float: "right", margin: "0", padding: "0" }}>
                    BDT : {this.state.deliveryFee}
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "30px",
                    marginBottom: "5px",
                    paddingTop: "10px",
                    borderTop: "1px solid #ccc",
                  }}
                >
                  <h5 style={{ float: "left", margin: "0", padding: "0" }}>
                    Total
                  </h5>
                  <p style={{ float: "right", margin: "0", padding: "0" }}>
                    BDT : {total}
                  </p>
                </div>
                <img src={CashOn} alt="cash on" />
                <p style={{ textAlign: "center" }}>
                  Cash on delivery. Pay when you recieve your order
                </p>
                <p style={{ textAlign: "center" }}>
                  <FontAwesomeIcon icon={faClock} /> Delivey will be made within
                  1 to 7 days depending on product and place.
                </p>
                <div style={{ textAlign: "center" }}>
                  <button className="BackButton" onClick={this.paymnetHandler}>
                    Back
                  </button>
                  <button
                    onClick={() =>
                      this.createOrderHandler(this.state.deliveryFee)
                    }
                    className="PlaceOrder"
                  >
                    Place order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </CheckOutModal>
        <div className="DekstopNav">
          <div className="DekstopNavCanvas">
            <div
              style={{
                width: "80%",
                height: "75%",
                marginTop: "9px",
                float: "left",
              }}
            >
              <Link
                style={{
                  width: "10%",
                  height: "30px",
                  margin: "0",
                  float: "left",
                }}
                to="/"
              >
                {" "}
                <img
                  style={{ width: "110px", height: "40px" }}
                  src={Logo}
                  alt="BazBan"
                />
              </Link>
              <div className="DekstopNavRightOptions">
                <ul>
                  <li>
                    <NavLink
                      activeStyle={{
                        color: "rgb(6, 109, 87)",
                        borderBottom: "3px solid rgb(5, 156, 136)",
                      }}
                      exact
                      to="/"
                    >
                      <span style={{ fontSize: "20px" }}>
                        <FontAwesomeIcon icon={faHome} />
                      </span>
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeStyle={{
                        color: "rgb(6, 109, 87)",
                        borderBottom: "3px solid rgb(5, 156, 136)",
                      }}
                      exact
                      to="/interests"
                    >
                      <span style={{ fontSize: "20px" }}>
                        <FontAwesomeIcon icon={faStar} />
                      </span>
                      Interests
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeStyle={{
                        color: "rgb(6, 109, 87)",
                        borderBottom: "3px solid rgb(5, 156, 136)",
                      }}
                      exact
                      to="/categories"
                    >
                      <span style={{ fontSize: "20px" }}>
                        <FontAwesomeIcon icon={faTh} />
                      </span>
                      Categories
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeStyle={{
                        color: "rgb(6, 109, 87)",
                        borderBottom: "3px solid rgb(5, 156, 136)",
                      }}
                      to="/orders"
                    >
                      <span style={{ fontSize: "20px" }}>
                        <FontAwesomeIcon icon={faServer} />
                      </span>
                      Orders
                    </NavLink>
                  </li>
                </ul>
                <div className="SearchBox">
                  <div
                    style={{
                      width: "10%",
                      float: "left",
                      textAlign: "center",
                      margin: "0 0 0 3px",
                    }}
                  >
                    <button
                      style={{
                        outline: "none",
                        cursor: "pointer",
                        color: "gray",
                        fontSize: "15px",
                      }}
                      onClick={this.mobileSearch}
                    >
                      <FontAwesomeIcon icon={faSearch} />
                    </button>
                  </div>
                  <input
                    autoComplete="off"
                    onKeyUp={(e) => this.keyHandler(e)}
                    onChange={this.handleChange}
                    onClick={this.searchModalHandler}
                    type="text"
                    name="searchText"
                    placeholder="Search Bazban"
                  ></input>

                  <SearchModal
                    show={this.state.searchModal}
                    searchHandler={this.searchModalHandler}
                  >
                    {searchSuggestion}
                  </SearchModal>
                </div>
              </div>
            </div>
            <div style={{ width: "19%", height: "100%", float: "right" }}>
              {DekstopRight}
            </div>
          </div>
        </div>
        <div className="MobileNav">
          <div style={{ width: "94%", height: "100%", margin: "0 0 0 3%" }}>
            <div style={{ width: "130px", height: "100%", float: "left" }}>
              <div style={{ float: "left" }}>
                <button
                  onClick={this.menuModalHandler}
                  style={{
                    border: "none",
                    background: "none",
                    outline: "none",
                    margin: "15px 0 0 0",
                    fontSize: "18px",
                    padding: "0",
                    color: "rgb(117, 114, 114)",
                  }}
                >
                  <FontAwesomeIcon icon={faBars} />
                </button>
              </div>
              <Link to="/" style={{ float: "right" }}>
                <img
                  style={{ width: "110px", height: "35px", marginTop: "7px" }}
                  src={Logo}
                  alt="Bazban"
                />
              </Link>
            </div>
            {mobileOptions}
          </div>
        </div>
        <div className="LayoutContent">{this.props.children}</div>
        <div className="BottomNav">
          <BottomNavigation />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userSetup: state.auth.userSetup,
    auth: state.auth.auth,
    cartItem: state.auth.cartItem,
    notification: state.auth.notification,
    redirect: state.auth.redirectSignIn,
    userName: state.auth.userName,
    userId: state.auth.userId,
    subCatComplete: state.data.subCatComplete,
    allSubCategory: state.data.allSubCategory,
    phone: state.auth.phone,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setupUser: (data) => {
      dispatch({
        type: actionTypes.USER_SETUP,
        userId: data._id,
        userName: data.user_name,
        cartTotal: data.cartTotal,
        notification: data.newNotifications,
        interests: data.interests,
        phone: data.phone,
      });
    },
    setupUser2: () => {
      dispatch({
        type: actionTypes.USER_SETUP2,
      });
    },
    logOut: async () => {
      dispatch({
        type: actionTypes.LOG_OUT,
      });
      localStorage.removeItem("TOKEN");
      localStorage.removeItem("userId");
    },
    notificationHandle: () => {
      dispatch({
        type: actionTypes.NOTICATION_HANDLE,
      });
    },
    cartRemove: () => {
      dispatch({
        type: actionTypes.DELETE_CART,
      });
    },
    emptyCart: () => {
      dispatch({
        type: actionTypes.EMPTY_CART,
      });
    },
    allCatSet: (data) => {
      dispatch({
        type: actionTypes.ALL_SUB_CAT,
        allSubCat: data,
      });
    },
    searchReady: () => {
      dispatch({
        type: actionTypes.SEARCH_READY,
      });
    },
    searchDone: (data, searchData) => {
      dispatch({
        type: actionTypes.SEARCH_DONE,
        results: data,
        searchText: searchData,
      });
    },
    createOrder: () => {
      dispatch({
        type: actionTypes.CREATE_ORDER,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Layout);
