import React from 'react';

import  './FeedbackDeleteModal.css';
import Backdrop from '../BackDrop2/BackDrop2';

const feedbackDeleteModal = (props) => (
   <div>
     <Backdrop show={props.show} clicked={props.cancelHandler}/>
     <div
     className="FeedbackDeleteModal"
     style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1': '0'

    }}
     >
         <p style={{margin:"0", padding:"0 0 10px 0"}}>Are you sure do you want to delete this feedback?</p>
         <button onClick={props.cancelHandler} className="baz-feedback-delete-cancelbutton">Cancel</button>
         <button onClick={props.deleteReviewHandler} className="baz-feedback-delete-button">Delete</button>      
    </div>
   </div>
);

export default feedbackDeleteModal;