import React from 'react';

import { Link } from 'react-router-dom';
import './FeedBack.css';

const userFeedBack = (props) =>{
    let title = props.product.title.substring(0, 25) + "..";
    let text = props.text.substring(0, 220) + "..";
    //    console.log(date.getFullYear()+'-' + (date.getMonth()+1) + '-'+date.getDate());
    const date = props.date.split("T")[0];
    return(
        <Link to={"/product/"+props.product._id} className="UserFeedBack">
            <div className="FUser">
                <div className="FUserPic">
                   <h1 style={{padding:"0", margin:"0", color:"white"}}>{props.user.user_name[0]}</h1>
                </div>
                <div className="FUserD">
                    <h5 style={{padding:"5px 0 0 0 ", margin:"0"}}>{props.user.user_name}</h5>
                    <p style={{padding:"0 ", margin:"0", fontSize:"13px", color:"gray"}}>{date}</p>
                </div>
            </div>
            <div className="FText">
                <p style={{padding:"10px"}}>{text}</p>
            </div>
            <div className="FProduct">
                <div className="FProductContainer">
                <img src={props.product.images[0]} alt="img"/>
                </div>
                <div className="FProductD">
                  <p style={{padding:"5px 0 ", margin:"0"}}>{title}</p>
                  <h4 style={{padding:"0", margin:"0"}}>BDT : {props.product.price}</h4>
                </div>
            </div>
        </Link>
    );
};

export default userFeedBack;