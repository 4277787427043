import React, { useState, useEffect } from "react";
import { Editor, EditorState, RichUtils } from "draft-js";
import "./AddProduct.css";

function AddProduct(props) {
  const [images, setImages] = useState([]);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );

  const editor = React.useRef(null);

  function focusEditor() {
    editor.current.focus();
  }
  const _onBoldClick = () => {
    console.log("Editor state", editorState, editor);
    RichUtils.toggleInlineStyle(editorState, "BOLD");
  };
  React.useEffect(() => {
    focusEditor();
  }, []);

  const handleFileChangeMore = (e) => {
    try {
      const newImages = [];
      for (let i = 0; i < e.target.files.length; i++) {
        // images.map(image=>{
        //   if
        // })
        let image = {
          uri: window.URL.createObjectURL(e.target.files[i]),
          file: e.target.files[i],
        };
        newImages.push(image);
      }
      setImages([...images, ...newImages]);
    } catch (error) {
      console.log(error);
    }
  };
  const handleFileChange = (e) => {
    try {
      console.log("FIles", e.target.files);
      const images = [];
      for (let i = 0; i < e.target.files.length; i++) {
        let image = {
          uri: window.URL.createObjectURL(e.target.files[i]),
          file: e.target.files[i],
        };
        images.push(image);
      }
      setImages(images);
    } catch (error) {
      console.log(error);
    }
  };
  const removeImage = (index) => {
    console.log("INdex", index);
    const allImages = images.filter((imgae, id) => {
      return id != index;
    });
    // allImages.pop(index);
    console.log("New images", allImages);
    setImages(allImages);
  };

  return (
    <div className="AddProduct-main">
      <input
        id="file-input"
        className="custom-file-input"
        required="true"
        type="file"
        multiple
        onChange={handleFileChange}
        accept="image/x-png,image/gif,image/jpeg"
      />
      <div className="preview-images">
        {/* <h2>Product images</h2> */}
        {images.length > 0 &&
          images.map((image, index) => {
            return (
              <div className="preview-image">
                <img src={image.uri} />
                <button
                  onClick={() => {
                    removeImage(index);
                  }}
                >
                  Remove
                </button>
              </div>
            );
          })}
        {images.length > 0 && (
          <div className="preview-image">
            <input
              id="file-input"
              className="custom-file-input"
              required="true"
              type="file"
              multiple
              onChange={handleFileChangeMore}
              accept="image/x-png,image/gif,image/jpeg"
            />
          </div>
        )}
      </div>
      <button onClick={_onBoldClick.bind()}>Bold</button>
      <div className="editor-area" onClick={focusEditor}>
        <Editor
          ref={editor}
          editorState={editorState}
          onChange={(editorState) => setEditorState(editorState)}
        />
      </div>
    </div>
  );
}

export default AddProduct;
