import React from "react";

import { Link } from "react-router-dom";

import "./Cat.css";
const imageTest = "https://cdn-icons-png.flaticon.com/512/3843/3843517.png";
const cat = (props) => (
  <Link
    onClick={() => props.catModalHandler(props.id)}
    to={"/categories/" + props.id}
    className="ex-Cat"
  >
    <img
      // src={props.logo}
      src={imageTest}
      alt={props.name}
    />
    <p>{props.name}</p>
  </Link>
);

export default cat;
