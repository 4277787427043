import React from 'react';

import './EmptyList.css';

const emptyList = () =>(
    <div className="baz-or-EmptyList">
        <div className="baz-or-EmptyList-order"></div>
        <div className="baz-or-EmptyList-order"></div>
        <div className="baz-or-EmptyList-order"></div>
        <div className="baz-or-EmptyList-order"></div>
        <div className="baz-or-EmptyList-order"></div>
        <div className="baz-or-EmptyList-order"></div>
    </div>
);

export default emptyList;