import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";

import Logo from "../../../assets/wordmark.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

import ProcessingModal from "../../../components/Modals/Loader/Loader";
import "./SignUp.css";
import * as actionTypes from "../../../store/actions/actionTypes";

import { url } from "../../../utlis/server";

class SignUp extends Component {
  state = {
    passType: "password",
    buttonStatus: false,
    user_name: "",
    password: "",
    gender: "",
    phone: null,
    error: "",
    processing: false,
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  showPasswordHandler = () => {
    if (this.state.passType === "password") {
      this.setState({
        passType: "text",
      });
    } else {
      this.setState({
        passType: "password",
      });
    }
  };
  signUpHandler = async (data) => {
    try {
      if (this.state.phone.length === 11 && this.state.phone[0] === "0") {
        this.setState({
          processing: !this.state.processing,
        });
        const res = await axios.post(url, data);
        this.props.signUp(res.data);
        this.setState({
          processing: !this.state.processing,
        });
      } else {
        this.setState({
          error: "Type a valid number",
        });
      }
    } catch (error) {
      throw error;
    }
  };
  render() {
    const user_data = {
      query: `
            mutation{
                createUser(userInput:
                    {
                    user_name:"${this.state.user_name}",
                    phone:"+88${this.state.phone}",
                    password:"${this.state.password}",
                    gender:"${this.state.gender}"
                    }
                    ){
                    success
                    error_message
                    token
                    userId
                    userName,
                    phone
                }
            }
            `,
    };
    let icon, button;
    if (this.state.passType === "password") {
      icon = <FontAwesomeIcon icon={faEyeSlash} />;
    } else {
      icon = <FontAwesomeIcon icon={faEye} />;
    }
    this.state.user_name &&
    this.state.phone &&
    this.state.password &&
    this.state.gender
      ? (button = (
          <button
            className="SignUpMainButton"
            onClick={() => this.signUpHandler(user_data)}
          >
            Create Account
          </button>
        ))
      : (button = (
          <button
            className="SignUpSubButton"
            onClick={this.partHandler}
            disabled
          >
            Create Account
          </button>
        ));
    return (
      <div className="baz-SignUp">
        {this.props.redirect}
        <ProcessingModal show={this.state.processing} />
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <Link to="/">
            {" "}
            <img className="baz-signin-logo" src={Logo} alt="bazban" />
          </Link>
          <p
            style={{
              margin: "0",
              pading: "5px",
              fontSize: "20px",
              color: "rgb(6, 109, 87)",
            }}
          >
            Create an account
          </p>
        </div>
        <div className="baz-signin-phone">
          <input
            style={{ border: "none" }}
            type="text"
            placeholder="Your name"
            name="user_name"
            onChange={this.handleChange}
          />
        </div>

        <div className="baz-signin-phone">
          <input
            type="number"
            placeholder="Mobile number"
            name="phone"
            onChange={this.handleChange}
          />
        </div>
        <p
          style={{
            textAlign: "center",
            color: "red",
            margin: "0",
            padding: "0 0 10px 0",
          }}
        >
          {this.state.error}
        </p>
        <div className="baz-signin-pass">
          <input
            type={this.state.passType}
            placeholder="Password"
            name="password"
            onChange={this.handleChange}
          />
          <button onClick={this.showPasswordHandler}>{icon}</button>
        </div>
        <div style={{ margin: "10px 6%" }}>
          <h5 style={{ margin: "0", padding: "3px" }}>Gender</h5>
          <input
            style={{ cursor: "pointer" }}
            onChange={this.handleChange}
            type="radio"
            name="gender"
            value="male"
          />{" "}
          Male
          <input
            style={{ cursor: "pointer" }}
            onChange={this.handleChange}
            type="radio"
            name="gender"
            value="female"
          />{" "}
          Female
          <input
            style={{ cursor: "pointer" }}
            onChange={this.handleChange}
            type="radio"
            name="gender"
            value="other"
          />{" "}
          Others
        </div>
        <p
          style={{
            color: "red",
            textAlign: "center",
            margin: "0",
            padding: "0",
          }}
        >
          {this.props.error_message}
        </p>
        <div style={{ textAlign: "center" }}>{button}</div>
        <p style={{ textAlign: "center" }}>
          Already have an account?{" "}
          <span>
            <Link style={{ color: "rgb(6, 109, 87)" }} to="/user/sign-in">
              Sign in
            </Link>
          </span>
        </p>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    error_message: state.auth.signup_error,
    email: state.auth.email,
    redirect: state.auth.redirectUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    signUp: async (data) => {
      try {
        if (data.data.createUser.success !== true) {
          dispatch({
            type: actionTypes.SIGNUP_ERROR,
            message: data.data.createUser.error_message,
          });
        } else {
          dispatch({
            type: actionTypes.AUTH_SIGN_UP,
            userId: data.data.createUser.userId,
            userName: data.data.createUser.userName,
            phone: data.data.createUser.phone,
          });
          localStorage.setItem("TOKEN", data.data.createUser.token);
        }
      } catch (error) {
        dispatch({
          type: actionTypes.SIGNUP_ERROR,
          message: "Something went wrong!",
        });
      }
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
