import React from "react";

import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

import "./Category.css";
const imageTest = "https://cdn-icons-png.flaticon.com/512/3843/3843517.png";
const category = (props) => {
  return (
    <Link
      onClick={props.mainCatHandler}
      to={"/categories/" + props.id}
      className="BazCategory"
    >
      <LazyLoadImage
        effect="blur"
        alt={"img"}
        //src={props.logo}
        src={imageTest}
      />
      <p>{props.name}</p>
    </Link>
  );
};

export default category;
