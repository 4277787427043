import React from 'react';

import Footer from '../../Footer/Footer';

const terms = () =>(
   <div>
      <div  style={{width:"93%", marginLeft:"3.5%"}}>
         <h2 style={{textAlign:"center"}}>Terms & Conditions</h2>
         <p>
           Welcome to Bazban Terms & conditions.
         </p>
         <p>
             Bazban.com is fully maintain through legal document. Before confirm your order please read Terms & conditions. Bazban.com reserves the right to change all terms and conditions of this website at any time.
         </p>
         <ul>
             <li>
                 Provide your actual information when you open your account .
             </li>
             <li>
             Bazban.com can change the price of any product at any time, without advertisement. 
             </li>
             <li>
             Before confirm your order, please read the product description.
             </li>
             <li>
             Which product have warranty, the warranty card will send with your product package. Replacement depends on warranty policy. 
             </li>
             <li>
               If the account holder engaged with any occurrence then Bazban will banned your account for security.  
             </li>
             <li>
             Copyrights will not be acceptable. Bazban has the rights to take action against copyrights.

             </li>
             <li>
             Color maybe shown ligher or deeper due to the different pc display & environment. So you will be responsible when taken the product. 
             </li>
             
         </ul>
         <h3>Purchasing policy</h3>
         <li>
          Select number of product you want to buy.
        </li>
        <li>
          Click on add to bag button.
        </li>
        <li>
        If you are a new user, please click on sign up. Then sign up by providing the required information.
        </li>
        <li>
        Use your user name & password if you are a registered customer. 
        </li>
        <li>
        Choose your payment checkout method.

        </li>
        <li>
        Please check your product at the time of delivery.

        </li>
         
        <li>
          Before order confirm, Bazban will contact with you.
        </li>
        <li>
        Disclaimer: Product color may slightly vary due to photography, Lighting sources or your monitor settings.
        </li>
         <h4>How to pay:</h4>
         <p>
            Cash on Delivery, pay when you recieve your order.
         </p>
         <p>
            You can make your purchases on Bazban and get delivery from anywhere in the Bangladesh. Delivery charge varies according to customers' place.
         </p>
         <p>
            Customer Service of Bazban: Email: <span style={{color:"rgb(6, 109, 87)", padding:"0 10px", fontWeight:"500"}}>bazbanhelps@gmail.com</span>

         </p>

         <h3>Return Policy</h3>
         <p>
         Welcome to our return & replacement policy. 
Before returning or replacing an item, please read through our return & replacement policy.100% Return guaranty, we place utmost importance on product returns. 
We can return within 5-6 days in Sylhet. Outside Sylhet, it will take maximum 10 days.
If your product is defective, damaged or inaccurate at the time of delivery, please contact our helpline on the inside 24 hours. Bazban will replace the product.
 Customers can buy other products if they want (at the price of the returned product)

         </p>
         <p style={{fontWeight:"bold"}}>★★ No return charge from anywhere★★ </p>
         <ul>
           <li>
           In 24 hours of receiving the products you have to confirm this to our help desk.
           </li>
           <li>
           You have to pack the product in the same package.
           </li>
           <li>
           Order number
           </li>
           <li>
           Invoice code number
           </li>
           <li>
           Send unboxing video.
           </li>
           <li>	Warranty card.</li>
         </ul>
         <p>At delivery time you can return the product.</p>
         <p>
            Customer Service of Bazban: Email: <span style={{color:"rgb(6, 109, 87)", padding:"0 10px", fontWeight:"500"}}>bazbanhelps@gmail.com</span>
         </p>
         <h3>Delivery policy</h3>
           <p>
           Delivery policy
Delivery will be made within 1 to 7 days depending on product & place. Cash on delivery are available. 

If your delivery address is Sylhet Metropoliton city, products will be delivered within 1 business days & delivery charge is BDT-40. If outside of Sylhet then it will take 1-7 business days & delivery charge is BDT-80. If you order after 2pm, it will be considered as an order of next business day.

           </p>
           <h4>Note</h4>
           <ul>
               <li>
               Delivery place & products may be conditioned by view.
                </li>
               <li>
               Delivery may be delayed due to political, Social, Natural restrictions or other issues.
               </li>
               <li>
               Please check your product at the time of delivery
               </li>
               <li>
               The organization is not responsible if someone else accepts the delivery..
               </li>
               <li>
               Delivery may be delayed due to political, social, natural restrictions or other issues.
               </li>
               <li>
               Delivery may delay due to covid-19.
               </li>
           </ul>

         
           <p>Charge will be shown at the check out page</p>
           <p>The organization is not responsible if someone else accepts the delivery.</p>
           <h2>Stay home, Stay safe</h2>
    </div>
    <Footer />
   </div>
);

export default terms;