import React from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import './PendingOrder.css';

import OrderedProduct from '../OrderedProduct/OrderedProduct';

const pendingOrder = (props) =>{
    let orderAction;
    if(props.order.pending){
         orderAction = <div className="baz-ad-OrderActions">
            <button  className="baz-ad-AcceptButton" onClick={()=> props.orderConfirmHandler(props.order._id)}>Accept</button>
            <button className="baz-ad-CancelButton" onClick={()=>props.orderCancelHandler(props.order._id)}>Cancel</button>
        </div>
    }
    if(props.order.confirmed){
        orderAction = <div className="baz-ad-OrderActions">
           <button  className="baz-ad-AcceptButton" onClick={()=> props.shippedHandler(props.order._id)}>Shipped</button>
       </div>
   }
   if(props.order.shipped){
    orderAction = <div className="baz-ad-OrderActions">
                      <button  className="baz-ad-AcceptButton" onClick={()=> props.orderDeliverHandler(props.order._id)}>Delivered</button>
                       <button className="baz-ad-AcceptButton" onClick={()=>props.orderReturnHandler(props.order._id)}>Returned</button>
                    </div>
   }
    const products = props.order.orderedProducts.map((product, id) =>{
          let size;
          if(product.size){
               size=<p> Size : {product.size}</p>
          }
        return<OrderedProduct
                 key={id}
                 product={product}
                 size={size}
               />
    }); 
    return(
        <div className="baz-ad-PendingOrder">
            <div className="baz-ad-OrderHeader">
                <p style={{padding:"10px", margin:"0", float:"left", fontWeight:"bold"}}>Order ID: {props.order.orderNo}</p>
                <button onClick={props.orderViewHandler} style={{padding:"8px 10px",cursor:"pointer",outline:"none", margin:"10px", float:"right", borderRadius:"100%", border:"none"}}><FontAwesomeIcon icon={faTimes}/></button>
            </div>
            <div className="baz-ad-OrderDetails">
                 <h4 style={{margin:"10px 5% 5px 5%", color:"green"}}>Customer</h4>
                 <Link to={"/profile/"+props.order.user._id}  style={{margin:"0 5%"}}>{props.order.user.user_name}</Link>
                 {/* <p  style={{margin:"0 5%"}}>{props.order.user.user_name}</p> */}
                 <p  style={{margin:"0 5%"}}>{props.order.user.location}</p>

                  <h4 style={{margin:"30px 5% 10px 5%",color:"green"}}>Products details</h4>
                    {products}
                    
                  <h4 style={{margin:"30px 5% 0px 5%",color:"green"}}>Delivery details</h4>
                  <p  style={{margin:"0 5%"}}>{props.order.country}</p>
                  <p  style={{margin:"0 5%"}}>{props.order.city}</p>
                  <p  style={{margin:"0 5%"}}>{props.order.address}</p>

                  <h4 style={{margin:"30px 5% 0px 5%",color:"green"}}>Amount</h4>
                  <p style={{margin:"0 5%"}}>Sub Total : {props.order.subTotal}</p>
                  <p style={{margin:"0 5%"}}>Delivery fee : {props.order.deliveryFee}</p>
                  <p style={{margin:"0 5%"}}>Total : {props.order.total}</p>

                  <h4 style={{margin:"30px 5% 0px 5%", color:"green"}}>Contact details</h4>
                  <p  style={{margin:"0 5%"}}>Name: {props.order.contact_name}</p>
                  <p  style={{margin:"0 5%"}}>Phone: {props.order.contact_phone}</p>
            </div>
            {orderAction}
        </div>
    );
};

export default pendingOrder;