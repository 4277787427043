import React, {Component} from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';

import OthersSkeleton from '../../components/OthersSleleton/OthersSkeleton';

import './UserHistory.css';
import Product from './HistoryProduct/HistoryPorduct';

import { url} from '../../utlis/server';

class UserHistory extends Component{
    state={
        history:[],
        complete: false
    }
    componentDidMount = async() =>{
        try {
            window.scrollTo(0,0);
            if(localStorage.TOKEN){
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.TOKEN
                  };
                const history = await axios.post(
                    url,
                    {
                        query:`
                           query{
                               userHistory{
                                   _id
                                   product{
                                       _id
                                       title
                                       price
                                       coverImage
                                       offer
                                   }
                               }
                           }
                        `
                    },
                    {"headers": headers}
                );
                this.setState({
                    history: history.data.data.userHistory,
                    complete: true
                });
            }
        } catch (error) {
            throw error;
        }
    } 
    render(){
        let history, historyBody;
        if(localStorage.TOKEN){
            if(this.state.complete){
              if(this.state.history.length > 0){
                let historyProduct=[];
                history = this.state.history.map(his=>{
                  if(!historyProduct.includes(his.product._id)){
                        historyProduct.push(his.product._id)
                        return<Product
                        key={his._id}
                        id={his.product._id}
                        title={his.product.title}
                        coverImage={his.product.coverImage}
                        price={his.product.price}
                        offer={his.product.offer}
                    />
                  }else{
                      return null;
                  }
                });
                 historyBody = <div className="baz-browse-history" >
                                    <h3 style={{padding:"0 0 10px 0", margin:"0"}}>Your browsing history</h3>
                                    {history}
                               </div>;
              }else{
                  historyBody = <div className="EmptySaved">
                                    <h3>Your History is empty.</h3>
                                    <Link to="/">Explore</Link>
                                </div>;
               }
          }else{
              historyBody = <OthersSkeleton />
          }
        }else{
            historyBody =<div className="baz-logout-interest">
                            <h2>Log in to see your History</h2>
                            <p>Without login history aren't available. To see history log in to your account.</p>
                            <Link to="/user/sign-in" className="baz-interest-login">Log in</Link>
                        </div>;
        }
        return<div>
              { historyBody}
            </div>
    }
};

export default UserHistory;