import React from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faCopyright } from '@fortawesome/free-solid-svg-icons';

import './Footer.css';
import Logo from '../../assets/wordmark.png';
import Instagram from '../../assets/instagram.svg';
import Facebook from '../../assets/facebook.svg';
import Youtube from '../../assets/youtube.svg';
import LinkedIn from '../../assets/linkedin.svg';

const footer = () =>{
    return(
        <div className="baz-footer">
           <div className="baz-footer-ul">
              <div className="baz-footer-part1">
                  <Link className="baz-footer-appicon" to="/"><img src={Logo} alt="bazban"/></Link>
                  <p style={{fontSize:"17px", color:"gray", margin:"0", padding:"0"}}>Comfort Shopping</p>
                  <p style={{fontSize:"17px", color:"gray", margin:"0", padding:"20px 0 0 0"}}>Our mission is to give freedom and exitement in shooping. We are promised, we are dedicated and we believe in truth.</p>
              </div>
              <div className="baz-footer-part2">
                  <li>
                      <Link to="/"> Home</Link>
                  </li>
                  <li>
                      <Link to="/interests"> Interests</Link>
                  </li>
                  <li>
                      <Link to="/orders"> Orders</Link>
                  </li>
                  <li>
                      <Link to="/categories"> Categories</Link>
                  </li>
              </div>
              <div className="baz-footer-part2">
                 <li>
                      <a href="/about" > About</a>
                  </li>
                  <li>
                      <a href="/privacy-policy"> Privacy policy</a>
                  </li>
                  <li>
                      <a href="/terms-conditions">Terms and conditions</a>
                  </li>
                  
                  <li>
                      <Link to="/contact">Contact</Link>
                  </li>
              </div>
              <div className="baz-footer-part2">
                  {/* <p style={{margin:"5px 0", fontWeight:"500"}}>Contact no: 01571-731874</p> */}
                  <p style={{margin:"5px 0", fontWeight:"500"}}>Contact</p>
                  <p style={{margin:"5px 0", fontWeight:"500"}}>Email: bazbanhelps@gmail.com</p>
              </div>
           </div>
           <div className="baz-footer-bottom">
                  <p style={{margin:"5px 0", padding:"0 0 0 3.5%", width:"50%", float:"left", color:"white"}}><FontAwesomeIcon icon={faCopyright}/> bazban 2021 - All rights reserved</p>
                  <div style={{width:"30%", float:"right", marginRight:"3.4%"}}>
                     <a rel="noopener noreferrer" href="https://www.linkedin.com/company/bazban" target="_blank" className="baz-footer-social-link"><img src={LinkedIn} alt="linkedIn"/></a> 
                     <a rel="noopener noreferrer" href="https://www.facebook.com/bazbanofficial" target="_blank" className="baz-footer-social-link"><img src={Facebook} alt="facebook"/></a>
                     <a rel="noopener noreferrer" href="https://www.instagram.com/bazbanofficial/" target="_blank" className="baz-footer-social-link"><img src={Instagram} alt="insagram"/></a>
                     <a rel="noopener noreferrer" href="https://www.youtube.com/channel/UC9RsKicaZzG0K6FgdOYwpnQ?fbclid=IwAR3LI9rEYsBBU6SO7kMu4ltR1tB4_-DX2dGMV3-iKSVN9pyA4rsVSaeGLlo" target="_blank" className="baz-footer-social-link"><img src={Youtube} alt="youtube"/></a>
                  </div>
           </div>
        </div>
    );
};

export default footer;