import React from 'react';
import { NavLink} from 'react-router-dom';

import './BotItem.css';

const botItem = (props)=>(
          <NavLink to={props.to} exact activeStyle={{color:"rgb(13, 165, 145)"}} className="BotItem">
              <p style={{padding:"0", margin:"0", fontSize:"20px"}}>{props.icon}</p>
              <span style={{fontSize:"12px"}}>{props.name}</span>
          </NavLink>
);

export default botItem;