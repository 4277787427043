import * as actionTypes from '../actions/actionTypes';

const initialState ={
    homeComplete: false,
    newsFeed:{},
    allSubCategory:[],
    subCatComplete: false,
    searchResults:[],
    searchComplete: false,
    searchText:'',
    categoryListComplete: false,
    categoryList:[]
}
const dataReducer = (state=initialState, action ) => {
    switch(action.type){
        case actionTypes.HOME_DATA:
           return{
               ...state,
               newsFeed: action.data,
               homeComplete: true
           } 
           case actionTypes.ALL_SUB_CAT:
               return{
                   ...state,
                   allSubCategory: action.allSubCat,
                   subCatComplete: true
            }
            case actionTypes.SEARCH_READY:{
                return{
                    ...state,
                    searchComplete: false
                }
            }
            case actionTypes.SEARCH_DONE:
                return{
                    ...state,
                    searchResults: action.results,
                    searchText: action.searchText,
                    searchComplete: true
                }
            case actionTypes.CATEGORY_LIST:
                return{
                    ...state,
                    categoryList: action.data,
                    categoryListComplete: true
                }
          default:
              return state;
    }
}

export default dataReducer;