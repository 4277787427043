import React, { Component } from 'react';

import { connect } from 'react-redux';

import OthereSkeleton from '../../components/OthersSleleton/OthersSkeleton';

import EmptySearch from '../../assets/JackSearch.svg';
import './SearchResult.css';

import SearchProduct from '../../components/BazProduct3/BazProduct3';

class SearchResult extends Component{
    componentDidMount = () =>{
        window.scrollTo(0, 0)
    }
    
    render(){
        let results, title;
        if(this.props.searchComplete){
            if(this.props.results.length > 0){
                title = <p style={{padding:"0 0 15px 0", margin:0}}> Results shows for<span>{localStorage.searchKey}</span></p>;
                results = this.props.results.map(product =>{
                    return <SearchProduct 
                                key={product._id}
                                id={product._id}
                                title={product.title}
                                coverImage={product.coverImage}
                                price={product.price}
                                offer={product.offer}
                           />
                });
            }else{
                results = <div style={{textAlign:"center"}}>
                    <img style={{width:"150px",marginTop:"40px", height:"150px", borderRadius:"100%"}}  src={EmptySearch} alt="not found"/>
                    <h3>Opps no result found!</h3> 
                    <p className="baz-not-found-message">We cannot find the item you are searching for, maybe a little spelling mistake or haven't in our collection.</p>            
                </div>
            }
            
        }else{
            results = <OthereSkeleton />
        }
        return(
            <div style={{width:"100%", height:"auto", background:"#F0F2F5"}}>
                <div className="SearchMain">
                     {title}
                     {results}
                </div>
            </div>
        );
    };
};
const mapStateToProps = state =>{
    return{
      searchComplete: state.data.searchComplete,
      results: state.data.searchResults,
      searchText: state.data.serachText
    }
}
export default connect(mapStateToProps)(SearchResult);