import React, { Component } from 'react';

import  axios from 'axios';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import './ExploreCategory.css';

import Product from '../../components/ExploreCatSection/Product/Product';
import Product2 from '../../components/ExploreCatSection/Product2/Product2';
import Cat from '../../components/ExploreCatSection/Cat/Cat';
import AllCatModal from '../../components/Modals/AllCatModal/AllCatModal';

import { url} from '../../utlis/server';

class Categories extends Component{
    state={
        complete: false,
        order:1,
        exploreCategory:{},
        catModal: false
    }
    componentDidMount = async() =>{
        try {
            window.scrollTo(0, 0);
            if(localStorage.subCat){
                const category = await axios.post(
                    url,
                    {
                        query:`
                           query{
                            exploreCategory(category:"${this.props.match.params.catId}", order:${this.state.order}, subCategory:"${localStorage.subCat}"){
                                categories{
                                  _id
                                  name
                                  logo
                                }
                                subCategories{
                                  _id
                                  name
                                }
                                products{
                                  _id
                                  coverImage
                                  price
                                  title
                                  offer
                                }
                              } 
                           }
                        `
                    }
                );
                this.setState({
                    exploreCategory: category.data.data.exploreCategory,
                    complete: true
                });
            }else{
                const category = await axios.post(
                    url,
                    {
                        query:`
                           query{
                            exploreCategory(category:"${this.props.match.params.catId}", order:${this.state.order}){
                                categories{
                                  _id
                                  name
                                  logo
                                }
                                subCategories{
                                  _id
                                  name
                                }
                                products{
                                  _id
                                  coverImage
                                  price
                                  title
                                  offer
                                }
                              } 
                           }
                        `
                    }
                );
                this.setState({
                    exploreCategory: category.data.data.exploreCategory,
                    complete: true
                });
            }
           
            
        } catch (error) {
            throw error;
        }
    };
    catModalHandler = () =>{
        this.setState({
            catModal: !this.state.catModal
        });
    };
    catUpdatehandler = async(id) =>{
       try {
        window.scrollTo(0, 0);
        localStorage.removeItem("subCat");
        this.setState({
            catModal: !this.state.catModal,
            complete: false
        });
      
        const category = await axios.post(
            url,
            {
                query:`
                   query{
                    exploreCategory(category:"${id}", order:${this.state.order}){
                        categories{
                          _id
                          name
                          logo
                        }
                        subCategories{
                          _id
                          name
                        }
                        products{
                          _id
                          coverImage
                          price
                          title
                          offer
                        }
                      } 
                   }
                `
            }
        );
        this.setState({
            exploreCategory: category.data.data.exploreCategory,
            complete: true
        });
       } catch (error) {
           throw error;
       }
    };
    allHandler = async() =>{
        try {
            localStorage.removeItem('subCat');
            const category = await axios.post(
                url,
                {
                    query:`
                       query{
                        exploreCategory(category:"${this.props.match.params.catId}", order:${this.state.order}){
                            categories{
                              _id
                              name
                              logo
                            }
                            subCategories{
                              _id
                              name
                            }
                            products{
                              _id
                              coverImage
                              price
                              title
                              offer
                            }
                          } 
                       }
                    `
                }
            );
            this.setState({
                exploreCategory: category.data.data.exploreCategory,
                complete: true
            });
        } catch (error) {
            throw error;
        }
    }
    subCatUpdateHandler = async(id) =>{
        try {
            localStorage.setItem("subCat", id);
           
            const category = await axios.post(
                url,
                {
                    query:`
                       query{
                        exploreCategory(category:"${this.props.match.params.catId}", order:${this.state.order}, subCategory:"${localStorage.subCat}"){
                            categories{
                              _id
                              name
                              logo
                            }
                            subCategories{
                              _id
                              name
                            }
                            products{
                              _id
                              coverImage
                              price
                              title
                              offer
                            }
                          } 
                       }
                    `
                }
            );
            this.setState({
                exploreCategory: category.data.data.exploreCategory,
                complete: true
            });
            window.scrollTo(0, 0);
        } catch (error) {
            throw error;
        }
    };
    lowToHighHandler = async() =>{
        try {
               this.setState({
                   order: 1
               });
               console.log("Low to high", this.state.order);
               if(localStorage.subCat){
                const category = await axios.post(
                    url,
                    {
                        query:`
                           query{
                            exploreCategory(category:"${this.props.match.params.catId}", order:1, subCategory:"${localStorage.subCat}"){
                                categories{
                                  _id
                                  name
                                 logo
                                }
                                subCategories{
                                  _id
                                  name
                                }
                                products{
                                  _id
                                  coverImage
                                  price
                                  title
                                  offer
                                }
                              } 
                           }
                        `
                    }
                );
                this.setState({
                    exploreCategory: category.data.data.exploreCategory,
                    complete: true
                });
            }else{
                const category = await axios.post(
                    url,
                    {
                        query:`
                           query{
                            exploreCategory(category:"${this.props.match.params.catId}", order:1){
                                categories{
                                  _id
                                  name
                                  logo
                                }
                                subCategories{
                                  _id
                                  name
                                }
                                products{
                                  _id
                                  coverImage
                                  price
                                  title
                                  offer
                                }
                              } 
                           }
                        `
                    }
                );
                this.setState({
                    exploreCategory: category.data.data.exploreCategory,
                    complete: true
                });
            }
            
        } catch (error) {
            throw error;
        }
    }
    highToLowHandler = async() =>{
        try {
                this.setState({
                    order: -1
                });
                console.log("High to low", this.state.order);
                if(localStorage.subCat){
                    const category = await axios.post(
                        url,
                        {
                            query:`
                               query{
                                exploreCategory(category:"${this.props.match.params.catId}", order: -1, subCategory:"${localStorage.subCat}"){
                                    categories{
                                      _id
                                      name
                                      logo
                                    }
                                    subCategories{
                                      _id
                                      name
                                    }
                                    products{
                                      _id
                                      coverImage
                                      price
                                      title
                                      offer
                                    }
                                  } 
                               }
                            `
                        }
                    );
                    this.setState({
                        exploreCategory: category.data.data.exploreCategory,
                        complete: true
                    });
                }else{
                    const category = await axios.post(
                        url,
                        {
                            query:`
                               query{
                                exploreCategory(category:"${this.props.match.params.catId}", order:-1){
                                    categories{
                                      _id
                                      name
                                      logo
                                    }
                                    subCategories{
                                      _id
                                      name
                                    }
                                    products{
                                      _id
                                      coverImage
                                      price
                                      title
                                      offer
                                    }
                                  } 
                               }
                            `
                        }
                    );
                    this.setState({
                        exploreCategory: category.data.data.exploreCategory,
                        complete: true
                    });
                }
        } catch (error) {
            throw error;
        }
    }
    render(){
        let products, catMain, subCategories,subCategories2, categoryList, allCat, lowToHigh,highToLow, all, mobileAll, mobileSubCatSkeleton;
       
        if(this.state.order === 1){
            lowToHigh = <button onClick={this.lowToHighHandler} className="PriceFilter1">Price Low to High</button>;
            highToLow = <button onClick={this.highToLowHandler} className="PriceFilter">Price High to Low</button>;
        }
        if(this.state.order === -1){
            lowToHigh = <button  onClick={this.lowToHighHandler} className="PriceFilter">Price Low to High</button>;
            highToLow = <button  onClick={this.highToLowHandler} className="PriceFilter1">Price High to Low</button>;
        }
        if(this.state.complete){
            if(localStorage.subCat){
                all = <li className="ex-CategoryList20" onClick={this.allHandler}>All</li>;
                mobileAll =  <li onClick={this.allHandler} className="CategoryListM20">All</li>;
            }else{
                all = <li className="ex-CategoryList1">All</li>;
                mobileAll =  <li   className="CategoryListM1">All</li>;
            }
           if(this.state.exploreCategory.products.length > 0){
            products =  <div className="AllCatPro">
            {  this.state.exploreCategory.products.map(product =>{
                 return <Product2 
                          key={product._id}
                          id={product._id}
                          coverImage={product.coverImage}
                          title={product.title}
                          price={product.price}
                          offer={product.offer}
                        />
              })
            }
         </div>;
           }else{
               products = <div className="baz-explorecategory-empty">
                    <h2>Unfortunately stock out</h2>
                    <p>Don't worry we will stock in very soon. Be patient and Happy Shopping </p>
                    <Link to="/">Go to Home</Link>
               </div>
           }
            catMain = this.state.exploreCategory.categories.map(category =>{
                if(this.props.match.params.catId === category._id){
                    return<button key={category._id} className="ex-CatMain" onClick={this.catModalHandler}>
                            <span style={{float:"left", padding:"10px 20px"}}>{category.name}</span>
                            <span style={{float:"right", padding:"10px 20px"}}><FontAwesomeIcon icon={faChevronDown}/></span>
                         </button>
                }else{
                    return null;
                }
            });
            subCategories = this.state.exploreCategory.subCategories.map(subCat =>{
                 let style;
                 if(subCat._id === localStorage.subCat){
                     style = "ex-CategoryList1"
                 }else{
                    style = "ex-CategoryList20"
                 }
                 return<li  onClick ={()=>this.subCatUpdateHandler(subCat._id)} className={style} key={subCat._id}>{subCat.name}</li>
            });

            subCategories2 = this.state.exploreCategory.subCategories.map(subCat =>{
                let style;
                if(subCat._id === localStorage.subCat){
                    style = "CategoryListM1"
                }else{
                   style = "CategoryListM20"
                }
                return<li  onClick ={()=>this.subCatUpdateHandler(subCat._id)} className={style} key={subCat._id}>{subCat.name}</li>
           });
            categoryList = <div className="ex-CategoryList">
                            {catMain}
                            {lowToHigh}
                            {highToLow}
                            <ul>
                                {all}
                                {subCategories}
                            </ul>
                     </div>;
           allCat = <div className="ex-AllCat">
                         {this.state.exploreCategory.categories.map(category =>{
                                 return<Cat
                                           key={category._id}
                                           id={category._id}
                                           name={category.name}
                                           catModalHandler={this.catUpdatehandler}
                                           logo={category.logo}
                                         />
                         })
                         }
                         
                   </div>
        }else{
            products =  <div className="AllCatPro-skeleton">
                                <Product />
                                <Product />
                                <Product />
                                <Product />
                                <Product />
                                <Product />
                        </div>;
            categoryList = <div className="ex-CategoryList2"></div>;
            mobileSubCatSkeleton=<div className="baz-mobile-subcat-skeleton">
                <button style={{width:"100%", height:"45px", border:"none",borderRadius:"5px", background:"white"}}></button>
                <button style={{width:"25%", height:"45px", border:"none",borderRadius:"25px", background:"white", margin:"10px 5px 0 0"}}></button>
                <button style={{width:"25%", height:"45px", border:"none",borderRadius:"25px", background:"white", margin:"10px 5px 0 0"}}></button>
                <button style={{width:"25%", height:"45px", border:"none",borderRadius:"25px", background:"white", margin:"10px 0 0 0"}}></button>
            </div>
        };
       
        return(
            <div className="ex-Categories">
                <AllCatModal show={this.state.catModal} catHandler={this.catModalHandler}>
                    {allCat}
                </AllCatModal>
                {mobileSubCatSkeleton}
                {categoryList}
                <div className="ex-MobileCats">
                    {catMain}
                    <div className="MobileSubCats">
                        {mobileAll}
                        {subCategories2}
                    </div>
                </div>
                <div className="ex-CatProducts">
                   {products}
                </div>
            </div>
        );
    };
};

export default Categories;