export const AUTH_SIGN_UP = 'AUTH_SIGN_UP';
export const AUTH_SIGNIN ="AUTH_SIGNIN";
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const SIGNUP_ERROR = 'SIGNUP_ERROR';
export const LOG_OUT ='LOG_OUT';
export const CART_ADD = "CART_ADD";
export const DELETE_CART ="DELETE_CART";
export const EMPTY_CART ="EMPTY_CART";
export const CREATE_ORDER ="CREATE_ORDER";
export const NOTICATION_HANDLE = "NOTICATION_HANDLE";
export const HOME_DATA ="HOME_DATA";
export const ALL_SUB_CAT = "ALL_SUB_CAT";
export const SEARCH_READY = "SEARCH_READY";
export const SEARCH_DONE = "SEARCH_DONE";
export const UP_INTEREST ="UP_INTEREST";
export const USER_SETUP = "USER_SETUP";
export const USER_SETUP2 = "USER_SETUP2";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const USER_UPDATE_SETUP = "USER_UPDATE_SETUP";
