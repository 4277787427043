import React from 'react';

import './OtherSkeleton.css';

const othersSkeleton = () =>(
    <div className="baz-other-skeleton">
        <div style={{width:"320px", height:"40px", background:"white", marginBottom:"20px"}}></div>
        <div className="baz-other-skeleton-product"></div>
        <div className="baz-other-skeleton-product"></div>
        <div className="baz-other-skeleton-product"></div>
        <div className="baz-other-skeleton-product"></div>
    </div>
);

export default othersSkeleton;