import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faTh, faServer, faStar} from '@fortawesome/free-solid-svg-icons';

import './BottomNavigation.css';
import BotItem from './BotItem/BotItem';


const bottomNavigation = () =>{
    const options = [
        {
            id:1,
            name:"Home",
            icon:<FontAwesomeIcon icon={faHome}/>,
            to:"/"
        },
         {
            id:3,
            name:"Interests",
            icon:<FontAwesomeIcon icon={faStar}/>,
            to:"/interests"
        },
        {
            id:2,
            name:"Categories",
            icon:<FontAwesomeIcon icon={faTh}/>,
            to:"/categories"
        },
       
        {
            id:4,
            name:"Orders",
            icon:<FontAwesomeIcon icon={faServer}/>,
            to:"/orders"
        },
    ];
    const navOptions = options.map(option =>{
        return<BotItem key={option.id} name={option.name} icon={option.icon} to={option.to}/>
    })
    return(
        <div className="BottomNavigation">
           {navOptions}
        </div>
    );
};

export default bottomNavigation;