import React from 'react';

import './AlLCatOnLoad.css';
import OnCat from '../../HomeSection/OnLoad/OnCat/OnCat';
import CatOff from './CatOff/CatOff';

const onLoad = () =>(
   <div className="baz-catesection-skeleton">
       <div className="baz-catesection-skeleton-header"></div>
       <div className="OnLoadCategory-home">
          <OnCat />
          <OnCat />
          <OnCat />
          <OnCat />
          <OnCat />
          <OnCat />
       </div>
       <div className="OnCatDetails">
           <div className="baz-category-skeleton-main-header"></div>
           <div style={{width:"100%", marginTop:"20px", display:"flex", flexFlow:"wrap", justifyContent:"space-between"}} >
               <CatOff />
               <CatOff />
               <CatOff />
               <CatOff />
               <CatOff />
               <CatOff />
               <CatOff />
               <CatOff />
               <CatOff />
               <CatOff />
           </div>
       </div>
   </div>
);

export default onLoad;