import React, { Component } from 'react';

import axios from 'axios';

import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions/actionTypes';

import './allCategories.css';
import OnLoad from '../../components/AllCategoriesSection/AllCatOnLoad/AllCatOnLoad';
import Category from '../../components/HomeSection/Category/Category';
import SingleCategory from '../../components/AllCategoriesSection/SingleCategory/SingleCategory';
import Footer from '../../components/Footer/Footer';

import { url} from '../../utlis/server';

class AlLCategory extends Component{
    state = {
        complete: false
    }
    componentDidMount = async() =>{
        try {
            document.title = "Categories-Bazban"
            window.scrollTo(0, 0);
            if(!this.props.categoryListComplete){
                const categories = await axios.post(
                    url,
                    {
                        query:`
                         query{
                            categories{
                                _id
                                name
                                logo
                                subCategories{
                                    _id
                                    name
                                    mainCategory
                                    customers
                                    coverImage
                                }
                              }
                         }
                        `
                    }
                );
                this.props.categorySetup(categories.data.data.categories);
            }
            if(localStorage.TOKEN){
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.TOKEN
                  };
                const user = await axios.post(
                    url,
                    {
                        query:`
                          query{
                              user{
                                  cartTotal
                                  newNotifications
                              }
                          }`
                    },
                     {"headers": headers}
                );
                this.props.userUpdateSetup(user.data.data.user);
               }

        } catch (error) {
            throw error;
        }
    };
    mainCatHandler = () =>{
        localStorage.removeItem("subCat");
    }
    subCatSetHandler = (id) =>{
        localStorage.setItem("subCat", id);
    }
    render(){
        let category, topCategories, allCat;
        if(this.props.categoryListComplete){
            topCategories = this.props.categoryList.map(category =>{
                return<Category
                        key={category._id}
                        id={category._id}
                        name={category.name}
                        mainCatHandler={this.mainCatHandler}
                        logo={category.logo}
                      />
            });
            allCat = this.props.categoryList.map(category =>{
                if(category.subCategories.length > 0){
                    return<SingleCategory 
                    interests={this.props.interests}
                    key={category._id}
                    name={category.name}
                    subCategories={category.subCategories}
                    subCatSetHandler={this.subCatSetHandler}
                 />
                }else{
                    return null;
                }
            });
            category = <div>
                         <h3 className="baz-catlist-top-headline">Top Categories</h3>
                         <div className="baz-catlist-top">
                             {topCategories}
                         </div>
                         {allCat}
                     </div>
        }else{
            category = <OnLoad />
        }
        return(
            <div className="baz-catlist-main">
               {category}
               <Footer />
            </div>
        );
    };
};

const mapStateToProps = state =>{
    return{
         categoryListComplete:state.data.categoryListComplete,
         categoryList: state.data.categoryList,
         interests: state.auth.interests
    }
}
const mapDispatchToProps = (dispatch) => {
    return{
        categorySetup: async(data) =>{
            dispatch({
                type: actionTypes.CATEGORY_LIST,
                data: data
            });  
        },
        userUpdateSetup: (data) =>{
            dispatch({
                type: actionTypes.USER_UPDATE_SETUP,
                data:data
            });
        }
}
}


export default connect(mapStateToProps, mapDispatchToProps)(AlLCategory);