import React, { Component } from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';

import EmptyInterest from '../../assets/JackInterest.svg';

import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions/actionTypes';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTimes, faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

import NoFeedBack from '../../assets/noFeedback.svg';

import './UserProfile.css';
import OnLoad from '../../components/ProfileSection/OnLoadProfile/OnLoadProfile';
import SubCategory from '../../components/ProfileSection/SubCategory/SubCategory';
import Feedback from '../../components/ProfileSection/FeedBack/FeedBack';
import UpdateProfileModal from '../../components/Modals/UpdateProfileModal/UpdateProfileModal';
import ProcessingModal from '../../components/Modals/ProcessingModal/ProcessingModal'; 
import Footer from '../../components/Footer/Footer';

import { url} from '../../utlis/server';

class UserProfile extends Component{
    state={
        topScroll: 0,
        user:{},
        complete: false,
        updateProfile: false,
        userName:"",
        location: "",
        user_name:"",
        location2:"",
        processing: false
      }
    
      handleChange = event => {
        const { name, value} = event.target
        this.setState({ [name]: value})
     }
    
      componentDidMount = async() =>{
        try {
         window.scrollTo(0,0);
         const user = await axios.post(
          url,
          {
             query:`
              query{
                userProfile(userId:"${this.props.match.params.userId}"){
                  _id
                  user_name
                  location
                  interestedSubcat{
                    _id
                    name
                    mainCategory
                    coverImage
                  }
                  reviews{
                    _id
                    text
                    date
                    user{
                      _id
                      user_name
                    }
                    productD{
                      _id
                      title
                      images
                      price
                    }
                    
                  }
                }
              }
             `
          }
        );
        this.setState({
          user: user.data.data.userProfile,
          userName: user.data.data.userProfile.user_name,
          location: user.data.data.userProfile.location,
          user_name: user.data.data.userProfile.user_name,
          location2: user.data.data.userProfile.location,
          complete: true
        });
        } catch (error) {
           throw error;
        }
      };
      componentDidUpdate = async(prevProps) =>{
        if(this.props.match.params.userId !== prevProps.match.params.userId){
          window.scrollTo(0,0);
          this.setState({
            complete: false
          });
          const user = await axios.post(
           url,
           {
              query:`
               query{
                 userProfile(userId:"${this.props.match.params.userId}"){
                   _id
                   user_name
                   location
                   interestedSubcat{
                     _id
                     name
                     mainCategory
                     coverImage
                   }
                   reviews{
                     _id
                     text
                     date
                     user{
                       _id
                       user_name
                     }
                     productD{
                       _id
                       title
                       images
                       price
                     }
                     
                   }
                 }
               }
              `
           }
         );
         this.setState({
           user: user.data.data.userProfile,
           userName: user.data.data.userProfile.user_name,
           location: user.data.data.userProfile.location,
           user_name: user.data.data.userProfile.user_name,
           location2: user.data.data.userProfile.location,
           complete: true,
         });
        }
      }
      subCatSetHandler = (id) =>{
        localStorage.setItem("subCat", id);
      };
      updateProfileModalHandler = () =>{
        this.setState({
          updateProfile: !this.state.updateProfile
        });
      };
    
      profileUpdateHandler = async() =>{
         try {
             this.setState({
                 updateProfile: false,
                 processing: true
             });

            if(this.state.location){
              const user = await axios.post(
                url,
                {
                  query:`
                    mutation{
                      updateUser(userInput:{user_name:"${this.state.userName}", location:"${this.state.location}"}, userId:"${this.state.user._id}"){
                           user_name
                           location
                      }
                    }
                  `
                }
              );
              this.setState({
                user_name: user.data.data.updateUser.user_name,
                location2: user.data.data.updateUser.location,
                processing: false
              });
              this.props.updateSetup(user.data.data.updateUser.user_name);
            }else{
              const user = await axios.post(
                url,
                {
                  query:`
                    mutation{
                      updateUser(userInput:{user_name:"${this.state.userName}"}, userId:"${this.state.user._id}"){
                           user_name
                           location
                      }
                    }
                  `
                }
              );
              this.setState({
                user_name: user.data.data.updateUser.user_name,
                processing: false
              });
              this.props.updateSetup(user.data.data.updateUser.user_name);
            }
           
            
         } catch (error) {
           throw error;
         }
      };
    render(){
        let user, userInterest, feedbacks, location, updateButton, updateProfile, interestList;
        if(this.state.complete && this.props.userSetup){
            if(this.state.location2){
                location = <p style={{padding:"3px", margin:"0"}}><span style={{margin:"5px", color:"rgb(143, 43, 6)"}}><FontAwesomeIcon icon={faMapMarkerAlt}/></span>{this.state.location2}</p>;
              }
                 if(this.state.user.reviews.length > 0){
                    feedbacks = this.state.user.reviews.map(review =>{
                        return<Feedback
                                key={review._id}
                                id={review._id}
                                text={review.text}
                                user={review.user}
                                product={review.productD}
                                date={review.date}
                                />
                    });
                 }else{
                     feedbacks = <div style={{width:"100%", height:"300px",borderRadius:"5px", background:"white", textAlign:"center"}}>
                                      <img style={{width:"150px", height:"150px", borderRadius:"100%", marginTop:"50px"}} src={NoFeedBack} alt="empty feedback"/>
                                      <h4>No feedbacks yet!</h4>
                                 </div>
                 }
                       
                      if(this.state.user._id === this.props.userId){
                        updateButton = <button onClick={this.updateProfileModalHandler} className="ProfileUpdateButton"><FontAwesomeIcon icon={faEdit}/> profile</button>
                        if(!this.state.location2){
                           location = <button className="baz-update-location-button" onClick={this.updateProfileModalHandler} style={{margin:"5px", padding:"7px 20px", cursor:"pointer"}}><span><FontAwesomeIcon icon={faMapMarkerAlt}/></span> Add location</button>
                        }
                        
                        if(this.state.user.interestedSubcat.length > 0){
                          interestList =   this.state.user.interestedSubcat.map(sub =>{
                            return<SubCategory
                            key={sub._id}
                            id={sub._id}
                            name={sub.name}
                            mainCategory={sub.mainCategory}
                            interested={true}
                            subCatSetHandler={this.subCatSetHandler}
                            coverImage={sub.coverImage}
                            />
                            });
                        }else{
                          interestList = <div style={{width:"97.5%", height:"230px", background:"white", borderRadius:"5px", textAlign:"center"}}>
                                                <img style={{height:"80px", width:"80px", marginTop:"10px"}} src={EmptyInterest} alt="empty-interest"/>
                                                <p style={{padding:"5px 0 5px 0"}}>Nothing have in your interest list</p>
                                                <Link to="/categories" className="ExploreLink">Explore</Link>
                                         </div>
                        }
                      
                        userInterest = <div className="baz-user-InterestMain">
                           <h3>You are intereted in</h3>
                          <Link to="/interests" className="SeeAllLink">See all</Link>
                          <div className="UserAllInterest">
                               {interestList}
                         </div>
                          
                    </div>;
                    updateProfile = 
                    <div className="UpdateProfile">
                      <div style={{width:"100%", height:"56px", borderBottom:"1px solid #eee"}}>
                        <div style={{width:"70%", float:"left", height:"100%"}}>
                          <button onClick={this.updateProfileModalHandler} className="CancelUpdateModal"><FontAwesomeIcon icon={faTimes}/></button>
                          <h4  style={{width:"80%", float:"right", margin:"0", padding:"18px 0"}}>Update profile</h4>
                        </div>
                        <button onClick={this.profileUpdateHandler} className="UpdateActionButton">Save</button>
                      </div>
                      <div style={{width:"90%", height:"auto", marginLeft:"5%", paddingTop:"50px"}}>
                         <h5>User name</h5>
                         <input onChange={this.handleChange} type="text" name="userName" placeholder="User name" defaultValue={this.state.user.user_name}/>
                         <h5>Location</h5>
                         <input onChange={this.handleChange} type="text" name="location" placeholder="city, country" defaultValue={this.state.user.location}/>
                      </div>
                     </div>
                  
                      };
             
              user = <div className="UserProfile">
                      {/* <div className={headerStyle}> </div> */}
                        <div className="HeaderMain">
                        <div style={{width:"100%", height:"60px"}}>
                          {updateButton}
                        </div>
                          <div className="ProfilePicture">
                            <div className="Picture">
                              <div style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
                                 <h1>{this.state.user_name[0]}</h1>
                              </div>
                            </div>
                             <h1 style={{margin:"0", padding:"0"}}>{this.state.user_name}</h1>
                               {location}
                          </div>
                          
                        </div>
                       {userInterest}
                       <div className="UserFeedbacks">
                          <h3>Feedbacks</h3>
                         <div className="baz-user-AllFeedBacks">
                           {feedbacks}
                         </div>
                          
                       </div>
                  </div>;
        }else{
            user = <OnLoad />
        }
        return(
            <div className="baz-user-profile">
                <ProcessingModal show={this.state.processing}></ProcessingModal>
                <UpdateProfileModal show={this.state.updateProfile}>
                    {updateProfile}
                </UpdateProfileModal>
               {user}
               <Footer />
            </div>
        );
    };
};

const mapStateToProps = state =>{
    return{
       userId: state.auth.userId,
       userSetup: state.auth.userSetup
    }
}
const mapDispatchToProps = (dispatch) => {
    return{
        updateSetup: async(userName) =>{
            dispatch({
                type: actionTypes.UPDATE_PROFILE,
                 userName: userName
            });  
        }
}
}


export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);