import React, { Component } from 'react';

import axios from 'axios';

import './ControlProducts.css';
import Product from '../../../components/ControlPanelSection/Product/Product';

import { url} from '../../../utlis/server';

class ControlProducts extends Component{
    state = {
        products:[],
        categories:[],
        complete: false,
        productCode:''
    }
    componentDidMount = async() =>{
        try {
            const products = await axios.post(
               url,
                {
                    query:`
                     query{
                         adminProducts{
                             products{
                                    _id
                                    images
                                    sales
                                    offer
                                    stock
                                    title
                                    productCode
                                    price
                                    views
                             }
                             categories{
                                 _id
                                 name
                             }
                         }
                     }
                    `
                }
                );
                this.setState({
                    products: products.data.data.adminProducts.products,
                    categories: products.data.data.adminProducts.categories,
                    complete: true
                });

        } catch (error) {
            throw error;
        }
    };
    handleChange = event =>{
        const {name, value} = event.target;
        this.setState({[name]:value})
    }
    keyHandler = async(e) => {
        try {
            if(e.keyCode === 13){
                const products = await axios.post(
                   url,
                    {
                        query:`
                           query{
                               productCodeFind(productCode:"${this.state.productCode}"){
                                    _id
                                   images
                                   sales
                                   offer
                                   stock
                                   title
                                   productCode
                                   price
                                   views
                               }
                           }
                        `
                    }
                );
                this.setState({
                    products: products.data.data.productCodeFind
                });
            }
        } catch (error) {
            throw error;
        }
      }
    findCategoryProduct = async(catId)=>{
        try {
            const products = await axios.post(
               url,
                {
                    query:`
                     query{
                        findCategoryProducts(catId:"${catId}"){
                            _id
                            images
                            sales
                            offer
                            stock
                            title
                            productCode
                            price
                            views
                         }
                     }
                    `
                }
            );
            this.setState({
                products: products.data.data.findCategoryProducts
            });
        } catch (error) {
            throw error;
        }
    }
    render(){
        let products, categories;
        if(this.state.complete){
            products = this.state.products.map(product => {
                return<Product
                         key={product._id}
                         id={product._id}
                         title={product.title}
                         code={product.productCode}
                         images={product.images}
                         offer={product.offer}
                         sales={product.sales}
                         stock={product.stock}
                         price={product.price}
                         views={product.views}
                        />
            });
            categories = this.state.categories.map(category =>{
                return<li key={category._id} onClick={()=>this.findCategoryProduct(category._id)}>{category.name}</li>
            });
        }
        return(
            <div className="ControlProducts">
               <div className="ProductsNav">
                   <input type="text" placeholder="product code" name="productCode" onChange={this.handleChange} onKeyUp={(e)=>this.keyHandler(e)} />
                   <div className="ProductCategory">
                       <h5>Product categories</h5>
                       <ul>
                           {categories}
                       </ul>
                   </div>
               </div>
               <div className="AllProducts">
                   <table>
                      <thead>
                        <tr style={{ height:"50px", marginBottom:"10px"}}>
                            <th style={{width:"30%"}}>Product</th>
                            <th style={{width:"10%"}}>Price</th>
                            <th style={{width:"10%"}}>Code</th>
                            <th style={{width:"10%"}}>Stock</th>
                            <th style={{width:"10%"}}>Offer</th>
                            <th style={{width:"10%"}}>Sales</th>
                            <th style={{width:"10%"}}>Views</th>
                        </tr>
                      </thead>
                       {products}
                      
                   </table>
               </div>
            </div>
        );
    };
};

export default ControlProducts;