import React from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import './FeedBack.css';

const feedback = (props) =>{
    let user, updateButton, deleteButton;
    if(props.user.image){
        user = <img src={props.user.image} alt={props.user.name}/>
    }else{
        user = <h1 style={{color:"white", padding:"0", margin:"0"}}>{props.user.user_name[0]}</h1>
    }
    if(props.user._id === props.current_user){
        updateButton = <button className="baz-feeedback-update-but" onClick={()=>props.updateReviewModalHandler(props.reviewId)}><FontAwesomeIcon icon={faEdit} /></button>;
        deleteButton = <button onClick ={()=>props.deleteReviewHandler(props.id,props.reviewId)} style={{padding:"10px 12px"}} className="baz-feeedback-delete-but"><FontAwesomeIcon icon={faTrash}/></button>
    }
    const date = props.date.split("T")[0];
    return(
        <div className="baz-Feedback">
            <div className="baz-FedUser">
                <div className="baz-FUser">
                   <Link to={"/profile/"+props.user._id}>
                       <div style={{width:"50px", height:"50px",float:"left",  background: "rgb(7, 131, 104)", borderRadius:"100%", display: "flex",justifyContent: "center",alignItems: "center"}}>
                          {user}
                        </div></Link>
                    <div className="baz-UInfo">
                    <Link style={{textDecoration:"none"}} to={"/profile/"+props.user._id}>
                        <h5 style={{margin:"0", padding:"4px 0 0 0", color:"rgb(54, 53, 53)"}}>{props.user.user_name}</h5>
                        <p  style={{margin:"0", padding:"0", fontSize:"13px", color:"black"}}>{date}</p>
                    </Link>
                    </div>
                </div>
              {updateButton}{deleteButton}
            </div>
            <p>{props.text}</p>
            {/* <button style={{margin:"5px", padding:"5px"}}>Helfull</button>
            <button  style={{margin:"5px", padding:"5px"}}>Not helfull</button> */}
        </div>
    );
};

export default feedback;