import React from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import './CartProduct.css';

const cartProduct = (props) =>{
    let title;
   if(props.product.title.length > 30){
     title = props.product.title.substring(0,30) + "..."
   }else{
       title = props.product.title
   }
    let total = Math.ceil((props.product.price -(props.product.offer*props.product.price)/100 )* props.quantity);
    let style= {}, notaVail, update,decButton, incButton;
    if(props.availability === "false"){
        style={
            border:"1px solid red"
        };
        notaVail = <p style={{color:"red"}}>Out of stock, remove item for checkout.</p>
    }else{
        if(props.quantity > 1){
           decButton = <button onClick={()=>props.quantityDecreaseHandler(props.cartId)} className="baz-quantity-button1"><FontAwesomeIcon icon={faMinus}/></button>;
        }else{
            decButton = <button className="baz-quantity-button2"><FontAwesomeIcon icon={faMinus}/></button>;
        }
       if(props.quantity < props.availQuantity){
            incButton =  <button onClick={()=>props.quantityIncreaseHandler(props.cartId)} className="baz-quantity-button1"><FontAwesomeIcon icon={faPlus}/></button>;

       }else{
           incButton = <button className="baz-quantity-button2"><FontAwesomeIcon icon={faPlus}/></button>;
       }
        update =  <div style={{width:"100%",padding:"10px 0"}}>
                    {decButton}
                    <span style={{padding:"10px"}}>{props.quantity}</span>
                    {incButton}
                </div>
    };
    return(
        <div style={style} className="CartProduct">
            <div className="CproductDetails">
                <Link onClick={props.cartHandler}  to={"/product/"+props.product._id} style={{width:"38%", height:"100%", float:"left", textAlign:"center"}}>
                    {/* <img src={props.product.coverImage} alt={props.product.name}/> */}
                    <img src="https://m.media-amazon.com/images/I/81rgY3ecZqL._AC_SX679_.jpg" alt={props.product.name}/>
                </Link>
                <div className="CDetails">
                 <Link onClick={props.cartHandler} to={"/product/"+props.product._id} style={{padding:"0", margin:"0", textDecoration:"none"}}>{title}</Link>
                {props.size}
                 <p style={{padding:"2px", margin:"0"}}>Price : {Math.ceil((props.product.price -(props.product.offer*props.product.price)/100 ))}<span style={{fontWeight:"bold", padding:"0 5px"}}><FontAwesomeIcon icon={faTimes}/></span>{ props.quantity} = {total}</p>
                  {notaVail}
                  {update}
                </div>
            </div>
            <button className="baz-cartitem-remove" onClick={()=>props.removeCart(props.id, props.cartId)}><FontAwesomeIcon icon={faTimes}/></button>
        </div>
    );
};

export default cartProduct;