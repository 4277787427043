import React, { Component } from 'react';

import axios from 'axios';
import { connect } from 'react-redux';
import * as actionTypes from '../../store/actions/actionTypes';
import { Link } from 'react-router-dom';

import EmptyInterest from '../../assets/JackInterest.svg';

import './interests.css';
import OnLoad from '../../components/InterestSection/OnLoadInterest/OnLoadInterest';
import SubCategory from '../../components/HomeSection/SubCategory/SubCategory';
import Product from '../../components/InterestSection/OnLoadInterest/Product/Product';
import Footer from '../../components/Footer/Footer';

import { url } from '../../utlis/server';

class Interests extends Component{
    state = {
        complete: false,
        interests:{}
    }
    componentDidMount = async()=>{
        try {
            document.title = "Interests-Bazban"
            window.scrollTo(0, 0);
               if(localStorage.TOKEN){
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.TOKEN
                  };
                const interests = await axios.post(
                    url,
                    {
                        query:`
                           query{
                               interests(userId:"${this.props.userId}"){
                                interestList{
                                    _id
                                    name
                                    mainCategory
                                    customers
                                    coverImage
                                  }
                                  suggestedList{
                                    _id
                                    name
                                    mainCategory
                                    customers
                                    coverImage
                                  }
                                  products{
                                    _id
                                    coverImage
                                    title
                                    price
                                    offer
                                  }
                               }
                           }
                        `
                    },
                    {"headers": headers}
                );
              
                this.setState({
                    interests: interests.data.data.interests,
                    complete: true
                });
               }else{
                   this.setState({
                       complete: true
                   });
               }
               if(localStorage.TOKEN){
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.TOKEN
                  };
                const user = await axios.post(
                    url,
                    {
                        query:`
                          query{
                              user{
                                  cartTotal
                                  newNotifications
                              }
                          }`
                    },
                     {"headers": headers}
                );
                this.props.userUpdateSetup(user.data.data.user);
               }
        } catch (error) {
            throw error;
        }
    };
    subCatSetHandler = (id) =>{
        localStorage.setItem("subCat", id);
    }
    render(){
      
        let interest, interestList, suggestList;
        if(this.state.complete){
            if(localStorage.TOKEN){
                  if(this.state.interests.interestList.length > 0){
                    const interests = this.state.interests.interestList.map(sub =>{
                        return<SubCategory 
                                  key={sub._id}
                                  id={sub._id}
                                  name={sub.name}
                                  mainCategory={sub.mainCategory}
                                  interested={true}
                                  subCatSetHandler={this.subCatSetHandler}
                                  coverImage={sub.coverImage}
                              />
                    });   
                    const products = this.state.interests.products.map(product =>{
                        return<Product
                                 key={product._id}
                                 id={product._id}
                                 title={product.title}
                                 coverImage={product.coverImage}
                                 price={product.price}
                                 offer={product.offer}
                               />
                    });
                    interestList = <div>
                                        <h3 className="baz-interest-headline">You are interested in</h3>
                                        <div className="baz-suggest-list">
                                           {interests}
                                        </div>
                                        <div className="baz-interests-product">
                                            {products}
                                        </div>
                                  </div>
                  }else{
                      interestList = <div className="baz-interest-0">
                                           <img style={{width:"100px", height:"100px", marginTop:"20px"}} src={EmptyInterest} alt="emty-interest"/>
                                           <h3>Nothing have in your interest list</h3>
                                           <p style={{padding:"0 20%"}}>Add favourite category to your interest list and customize your bazban.</p>
                                     </div>
                  }
                  suggestList = this.state.interests.suggestedList.map(sub =>{
                    return<SubCategory 
                              key={sub._id}
                              id={sub._id}
                              name={sub.name}
                              mainCategory={sub.mainCategory}
                              interested={false}
                              subCatSetHandler={this.subCatSetHandler}
                              coverImage={sub.coverImage}
                          />
                   });
                  interest = <div>
                                {interestList}
                                <div className="baz-suggest">
                                    <h3>Explore more interest</h3>
                                  <div className="baz-suggest-list">
                                     {suggestList}
                                   </div>
                                </div>
                           </div>
            }else{
                interest = <div className="baz-logout-interest">
                               <img style={{width:"150px", height:"150px"}} src={EmptyInterest} alt="Empty-interest"/>
                               <h2>Log in to see your Interests</h2>
                               <p>Without login interests aren't available. To see interests log in to your account.</p>
                               <Link to="/user/sign-in" className="baz-interest-login">Log in</Link>
                            </div>
            }
        }else{
            interest = <OnLoad />
        }
        return(
            <div className="baz-interestmain">
               {interest}
               <Footer />
            </div>
        );
    };
};

const mapStateToProps = state =>{
    return{
        userId: state.auth.userId,
        userSetup: state.auth.userSetup
    }
}
const mapDispatchToProps = (dispatch) => {
    return{
        userUpdateSetup: (data) =>{
            dispatch({
                type: actionTypes.USER_UPDATE_SETUP,
                data:data
            });
        }
}
}
export default connect(mapStateToProps, mapDispatchToProps)(Interests);