import React from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faChevronRight } from '@fortawesome/free-solid-svg-icons';

import  './CollectionView1.css';
import Product from '../ProductView1/ProductView1';

const CollectionView1 = (props) =>{
    let products = props.products.map(product =>{
        return<Product
                 key={product._id}
                 id={product._id}
                 title={product.title}
                 coverImage={product.coverImage}
                 price={product.price}
                 offer={product.offer}
               />
    });
    return(
        <div className="baz-collection1">
            <div className="baz-collection1-header">
                  <h3>{props.name}</h3>
                  <div className="baz-collection1-header-see-all">
                     <Link onClick={props.mainCatHandler} to={"/categories/"+props.id} className="collectionview1-seeall-link1">See All <span style={{fontSize:"15px", margin:"5px 0"}}><FontAwesomeIcon icon={faChevronRight}/></span></Link>
                     <Link onClick={props.mainCatHandler} to={"/categories/"+props.id} className="collectionview1-seeall-link2"><FontAwesomeIcon icon={faChevronRight}/></Link>
                  </div>
            </div>
            <div  className="baz-collection1-header-mobile">
                <Link style={{textDecoration:"none", color:"black"}} onClick={props.mainCatHandler} to={"/categories/"+props.id}>
                    <div style={{width:"80%", float:"left"}}>
                        {/* <img style={{width:"40px", height:"40px", float:"left"}} src={props.logo} alt={props.name} /> */}
                        <img style={{width:"40px", height:"40px", float:"left"}} src="https://cdn-icons-png.flaticon.com/512/3843/3843517.png" alt={props.name} />
                        <h4 className="baz-collection1-category-headername">{props.name}</h4>
                    </div>
                    <span style={{float:"right", padding:"9px 0", color:"rgb(7, 131, 104)"}}>See all  <FontAwesomeIcon icon={faChevronRight}/></span>
                </Link>
            </div>
           
            <div className="collectionview1-products-all">
                {products}
            </div>
        </div>
    );
};

export default CollectionView1;