import React from 'react';

import './MobileSearchModal.css';

import Backdrop from '../Backdrop/Backdrop';

const searchModal = (props) => (
   <div>
     <Backdrop show={props.show} clicked={props.searchHandler} />
     <div
     className="MobileSearchModal"
     style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1': '0'

    }}
     >
        {props.children}
    </div>
   </div>
);

export default searchModal;