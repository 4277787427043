import React from 'react';

import './OrderViewModal.css';

import Backdrop from '../BackDrop2/BackDrop2';

const adminOrderModal = (props) => (
   <div>
     <Backdrop show={props.show} clicked={props.orderViewHandler} />
     <div
     className="baz-OrderViewModal"
     style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1': '0'

    }}
     >
        {props.children}
    </div>
   </div>
);

export default  adminOrderModal;