import React from 'react';

import'./SingleCategory.css';
import SubCategory from '../../HomeSection/SubCategory/SubCategory';

const singleCategory = (props) =>{
    const allCats = props.subCategories.map(sub =>{
        let interested;
        if(props.interests.includes(sub._id)){
            interested = true
        }else{
            interested = false
        }
        return<SubCategory 
                  key={sub._id}
                  id={sub._id}
                  name={sub.name}
                  mainCategory={sub.mainCategory}
                  interested={interested}
                  subCatSetHandler={props.subCatSetHandler}
                  coverImage={sub.coverImage}
              />
       });
    return(
        <div className="baz-single-cat">
            <h3>{props.name}</h3>
            <div className="baz-single-cat-details">
                {allCats}
            </div>
        </div>
    );
};

export default singleCategory;