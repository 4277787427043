import React from 'react';

import './OnLoad.css';
import OnCat from './OnCat/OnCat';
import OnProduct from './OnProduct/OnPorduct';

const onLoad = () =>(
   <div className="OnLoadMain">
       <div className="OnLoadCategory-home">
          <OnCat />
          <OnCat />
          <OnCat />
          <OnCat />
          <OnCat />
          <OnCat />
       </div>
       <div className="OnLoadProducts">
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
           <OnProduct />
       </div>
   </div>
);

export default onLoad;