import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faSpinner } from '@fortawesome/free-solid-svg-icons';

import  './ProcessingModal.css';
import Backdrop from '../BackDrop2/BackDrop2';

const processingModal = (props) => (
   <div>
     <Backdrop show={props.show}/>
     <div
     className="ProcessingModal"
     style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1': '0'

    }}
     >
      <h1><FontAwesomeIcon icon={faSpinner}/></h1>
    </div>
   </div>
);

export default processingModal;