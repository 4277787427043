import React from 'react';

import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import './NotFound.css';

const notFound = () =>(
    <div className="baz-not-found">
        <h1 style={{margin:"0", padding:"0", color:"gray"}}><FontAwesomeIcon icon={faExclamationTriangle}/></h1>
        <h2>This page isn't Available</h2>
        <p>The link may be broken, or the page may have been removed. Check to see if the link you're trying to open is correct.</p>
        <Link to="/">Go to Home</Link>
    </div>
);

export default notFound;