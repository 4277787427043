import React, { Component } from "react";

import axios from "axios";
import { Redirect, Link } from "react-router-dom";

import { connect } from "react-redux";
import * as actionTypes from "../../store/actions/actionTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faUser,
  faTimes,
  faClock,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";

import NoFeedBack from "../../assets/noFeedback.svg";

import "./ProductView.css";
import OnLoad from "../../components/ProductViewSection/OnLoadView/OnLoadView";
import Feedback from "../../components/ProductViewSection/FeedBack/FeedBack";
import Related from "../../components/ProductViewSection/Related/Related";
import ImageViewer from "../../components/Modals/ProductImageViewer/ProductImageViewer";
import CreateReviewModal from "../../components/Modals/CreateReviewModal/CreateReviewModal";
import UpdateReviewModal from "../../components/Modals/ReviewUpdateModal/ReviewUpdateModal";
import AddToCartModal from "../../components/Modals/Loader/Loader";
// import SizeChartModal from '../../components/Modals/SizeChartModal/SizeChartModal';
import FeedbackDeleteModal from "../../components/Modals/FeedbackDeleteModal/FeedbackDeleteModal";
import Footer from "../../components/Footer/Footer";

import { url } from "../../utlis/server";
const imagesTest = [
  "https://m.media-amazon.com/images/I/81rgY3ecZqL._AC_SX679_.jpg",
  "https://m.media-amazon.com/images/I/81fp2gfGSTL._AC_SX679_.jpg",
  "https://m.media-amazon.com/images/I/716o623D7tL._AC_SX679_.jpg",
  "https://m.media-amazon.com/images/I/71epgU+RgEL._AC_SX679_.jpg"
];
class ProductView extends Component {
  state = {
    activeImage: 0,
    product: {},
    complete: false,
    superb: 0,
    userSuperb: false,
    productSave: false,
    reviewModal: false,
    redirect: null,
    sizeName: "",
    sizeId: "",
    quantity: 1,
    availQuantity: 1,
    specification: false,
    feedbacks: [],
    feedbackText: "",
    imageViewer: false,
    updateReviewModal: false,
    updateReview: {},
    updateReviewText: "",
    cartProcessing: false,
    deleteFeedback: false,
    delFeedbackId: null,
    delReviewId: null,
  };
  componentDidMount = async () => {
    try {
      window.scrollTo(0, 0);
      const product = await axios.post(url, {
        query: `
                      query{
                          product(productId:"${this.props.match.params.productId}"){
                              _id
                              title
                              images
                              price
                              superb
                              description
                              descriptionPoints
                              stock
                              category
                              categoryName
                              subCategory
                              subCategoryName
                              offer
                              brand
                              color
                              productCode
                              sizes{
                                  _id
                                  name
                                  quantity
                                  height
                                  width
                              }
                              reviews{
                                _id
                                text
                                date
                                user{
                                  _id
                                  user_name
                                  image
                                }
                              }
                              related{
                                  _id
                                  title
                                  price
                                  coverImage
                                  offer
                              }
                          }
                      }
                   `,
      });
      document.title = product.data.data.product.title;
      this.setState({
        product: product.data.data.product,
        superb: product.data.data.product.superb.length,
        feedbacks: product.data.data.product.reviews,
      });
      if (this.state.product.stock > 0) {
        if (this.state.product.sizes.length > 0) {
          const sizes = this.state.product.sizes.filter((size) => {
            return size.quantity > 0;
          });
          this.setState({
            sizeName: sizes[0].name,
            sizeId: sizes[0]._id,
            availQuantity: sizes[0].quantity,
            complete: true,
          });
        } else {
          this.setState({
            availQuantity: product.data.data.product.stock,
            complete: true,
          });
        }
      } else {
        this.setState({
          complete: true,
        });
      }

      if (this.state.product.superb.includes(this.props.userId)) {
        this.setState({
          userSuperb: true,
        });
      }
      if (localStorage.TOKEN) {
        let headers = {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.TOKEN,
        };
        const user = await axios.post(
          url,
          {
            query: `
                      query{
                          user{
                              cartTotal
                              newNotifications
                          }
                      }`,
          },
          { headers: headers }
        );
        this.props.userUpdateSetup(user.data.data.user);

        await axios.post(
          url,
          {
            query: `
                      mutation{
                        addHistory(productId:"${this.state.product._id}"){
                            _id
                        }
                      }
                    `,
          },
          { headers: headers }
        );
      }
    } catch (error) {
      throw error;
    }
  };
  componentDidUpdate = async (prevProps) => {
    try {
      if (
        this.props.match.params.productId !== prevProps.match.params.productId
      ) {
        window.scrollTo(0, 0);
        this.setState({
          complete: false,
          userSuperb: false,
        });
        const product = await axios.post(url, {
          query: `
                           query{
                               product(productId:"${this.props.match.params.productId}"){
                                   _id
                                   title
                                   images
                                   price
                                   offer
                                   superb
                                   description
                                   descriptionPoints
                                   stock
                                   category
                                   categoryName
                                   subCategory
                                   subCategoryName
                                   brand
                                   color
                                   productCode
                                   sizes{
                                       _id
                                       name
                                       quantity
                                       height
                                       width
                                   }
                                   reviews{
                                     _id
                                     text
                                     date
                                     user{
                                       _id
                                       user_name
                                       image
                                     }
                                   }
                                   related{
                                       _id
                                       title
                                       price
                                       coverImage
                                       offer
                                   }
                               }
                           }
                        `,
        });
        document.title = product.data.data.product.title;
        this.setState({
          product: product.data.data.product,
          superb: product.data.data.product.superb.length,
          feedbacks: product.data.data.product.reviews,
          activeImage: 0,
        });
        if (this.state.product.sizes.length > 0) {
          const sizes = this.state.product.sizes.filter((size) => {
            return size.quantity > 0;
          });
          this.setState({
            sizeName: sizes[0].name,
            sizeId: sizes[0]._id,
            availQuantity: sizes[0].quantity,
            complete: true,
          });
        } else {
          this.setState({
            availQuantity: product.data.data.product.stock,
            complete: true,
          });
        }

        if (this.state.product.superb.includes(this.props.userId)) {
          this.setState({
            userSuperb: true,
          });
        }
        if (localStorage.TOKEN) {
          let headers = {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.TOKEN,
          };
          const user = await axios.post(
            url,
            {
              query: `
                              query{
                                  user{
                                      cartTotal
                                      newNotifications
                                  }
                              }`,
            },
            { headers: headers }
          );
          this.props.userUpdateSetup(user.data.data.user);
          await axios.post(
            url,
            {
              query: `
                              mutation{
                                addHistory(productId:"${this.state.product._id}"){
                                    _id
                                }
                              }
                            `,
            },
            { headers: headers }
          );
        }
      }
    } catch (error) {
      throw error;
    }
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  imageChangeHandler = (id) => {
    this.setState({
      activeImage: id,
    });
  };

  superbAddHandler = async () => {
    try {
      if (localStorage.TOKEN) {
        this.setState({
          superb: this.state.superb + 1,
          userSuperb: true,
        });
        axios.post(url, {
          query: `
                        mutation{
                            addSuperb(productId:"${this.state.product._id}", userId:"${this.props.userId}"){
                                _id
                            }
                        }
                        
                    `,
        });
      }
    } catch (error) {
      throw error;
    }
  };
  superbRemoveHandler = async () => {
    try {
      if (localStorage.TOKEN) {
        this.setState({
          superb: this.state.superb - 1,
          userSuperb: false,
        });
        axios.post(url, {
          query: `
                            mutation{
                                removeSuperb(productId:"${this.state.product._id}", userId:"${this.props.userId}"){
                                    _id
                                }
                            }
                            
                        `,
        });
      }
    } catch (error) {
      throw error;
    }
  };

  reviewModalHandler = () => {
    if (localStorage.TOKEN) {
      this.setState({
        reviewModal: !this.state.reviewModal,
      });
    }
  };
  createFeedbackHandler = async () => {
    try {
      if (this.state.feedbackText) {
        this.setState({
          reviewModal: false,
        });
        let feedbackText = this.state.feedbackText.replace(
          /(?:\r\n|\r|\n)/g,
          ""
        );
        const feedback = await axios.post(url, {
          query: `
                            mutation{
                                createReview(
                                    userId:"${this.props.userId}"
                                    text:"${feedbackText}"
                                    product:"${this.state.product._id}"
                                    ){
                                    _id
                                    text
                                    date
                                    user{
                                        _id
                                        user_name
                                        image
                                      }
                                }
                            }
                        `,
        });
        this.setState({
          feedbacks: feedback.data.data.createReview,
          feedbackText: "",
        });
      }
    } catch (error) {
      throw error;
    }
  };
  quantityHandler = (q) => {
    this.setState({
      quantity: q,
    });
  };
  sizeHandler = (name, id, q) => {
    this.setState({
      sizeName: name,
      sizeId: id,
      availQuantity: q,
      quantity: 1,
    });
  };
  imageViewerHandler = () => {
    this.setState({
      imageViewer: !this.state.imageViewer,
    });
  };
  cartHandler = async () => {
    try {
      if (localStorage.TOKEN) {
        const cart_data = {
          query: `
                   mutation{
                       addToCart(cartInput:{
                           productId:"${this.state.product._id}"
                           sizeId:"${this.state.sizeId}"
                           quantity: ${this.state.quantity}
                           size:"${this.state.sizeName}"
                       }, userId:"${this.props.userId}"){
                         user_name
                       }
                   }
                `,
        };
        this.props.cartAdd();
        this.setState({
          cartProcessing: true,
          availQuantity: this.state.availQuantity - this.state.quantity,
        });
        axios.post(url, cart_data);
        setTimeout(() => {
          this.setState({
            cartProcessing: false,
          });
        }, 1000);
      } else {
        this.setState({
          redirect: <Redirect to="/user/sign-in" />,
        });
      }
    } catch (error) {
      throw error;
    }
  };
  subCatSetHandler = (id) => {
    localStorage.setItem("subCat", id);
  };
  mainCatHandler = () => {
    localStorage.removeItem("subCat");
  };
  updateReviewModalHandler = async (id) => {
    try {
      if (!this.state.updateReviewModal) {
        const review = await axios.post(url, {
          query: `
                       query{
                           review(reviewId:"${id}"){
                               _id
                               text
                           }
                       }
                    `,
        });
        this.setState({
          updateReview: review.data.data.review,
          updateReviewModal: !this.state.updateReviewModal,
        });
      } else {
        this.setState({
          updateReviewModal: false,
        });
      }
    } catch (error) {
      throw error;
    }
  };
  updateReviewHandler = async () => {
    try {
      const updateReview = await axios.post(url, {
        query: `
                      mutation{
                          updateReview(reviewId:"${this.state.updateReview._id}", userId:"${this.props.userId}", newText:"${this.state.updateReviewText}"){
                            _id
                            text
                            date
                            user{
                              _id
                              user_name
                              image
                            }
                          }
                      }
                   `,
      });
      this.setState({
        feedbacks: updateReview.data.data.updateReview,
        updateReviewModal: false,
      });
    } catch (error) {}
  };
  deleteReviewModalHandler = (delId, reviewId) => {
    try {
      this.setState({
        deleteFeedback: true,
        delFeedbackId: delId,
        delReviewId: reviewId,
      });
    } catch (error) {
      throw error;
    }
  };
  deleteReviewModalCancelHandler = () => {
    this.setState({
      deleteFeedback: false,
    });
  };
  deleteReviewHandler = () => {
    const feedbacksall = this.state.feedbacks.filter((porduct, id) => {
      return this.state.delFeedbackId !== id;
    });
    this.setState({
      feedbacks: feedbacksall,
      deleteFeedback: false,
    });
    axios.post(url, {
      query: `
                      mutation{
                          deleteReview(reviewId:"${this.state.delReviewId}", userId:"${this.props.userId}"){
                              _id
                          }
                      }
                  `,
    });
  };
  subCatSetHandler = (id) => {
    localStorage.setItem("subCat", id);
  };
  render() {
    let productView,
      cartButton,
      notInStock,
      selectQuantity,
      images,
      imageStyle,
      imageViewerName,
      excellentBtn,
      mobileImages,
      decLists,
      sizes,
      feedbacks,
      related,
      relatedView,
      user,
      imageViewer,
      price,
      brand,
      color,
      feedBackButton;
    if (this.state.complete && this.props.userSetup) {
      if (this.props.auth) {
        user = (
          <Link
            style={{
              textDecoration: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            to={"/profile/" + this.props.userId}
          >
            <h1 style={{ margin: "0", padding: "2px 0", color: "white" }}>
              {this.props.userName[0]}
            </h1>
          </Link>
        );
        feedBackButton = (
          <button onClick={this.reviewModalHandler}>Write a feedback</button>
        );
        if (this.state.product.stock > 0) {
          cartButton = (
            <button onClick={this.cartHandler} className="baz-AddCartButton">
              Add to cart
            </button>
          );
        } else {
          cartButton = (
            <button className="baz-AddCartButton-not-stock" disabled>
              Add to cart
            </button>
          );
        }

        if (this.state.userSuperb) {
          excellentBtn = (
            <button
              onClick={this.superbRemoveHandler}
              className="baz-ExcellentButton"
            >
              <FontAwesomeIcon icon={faPlus} />
              <span>{this.state.superb}</span>Wishlist
            </button>
          );
        } else {
          excellentBtn = (
            <button
              onClick={this.superbAddHandler}
              className="baz-ExcellentButton2"
            >
              <FontAwesomeIcon icon={faPlus} />
              <span>{this.state.superb}</span>Wishlist
            </button>
          );
        }
      } else {
        user = (
          <h1
            style={{
              margin: "0",
              padding: "2px 0",
              color: "white",
              textAlign: "center",
            }}
          >
            <FontAwesomeIcon icon={faUser} />
          </h1>
        );
        cartButton = (
          <Link className="baz-AddCartButton-signLink" to="/user/sign-in">
            Add to cart
          </Link>
        );
        excellentBtn = (
          <Link className="baz-WishButton-signLink" to="/user/sign-in">
            <FontAwesomeIcon icon={faHeart} />
            <span>{this.state.superb}</span>Wishlist
          </Link>
        );
        feedBackButton = (
          <Link
            className="feedBack-button-signLink"
            to="/user/sign-in"
            onClick={this.reviewModalHandler}
          >
            Write a feedback
          </Link>
        );
      }
      imageViewerName = this.state.product.title.substring(0, 18) + "..";
      if (this.state.product.offer > 0) {
        let offerPrice =
          this.state.product.price -
          (this.state.product.price * this.state.product.offer) / 100;
        price = (
          <h3
            style={{
              margin: "0",
              padding: "5px 0 15px 0",
              color: "rgb(7, 131, 104)",
            }}
          >
            {" "}
            BDT:{" "}
            <span
              style={{
                textDecoration: "line-through",
                color: "black",
                fontSize: "17px",
                margin: "0 5px 0 0",
              }}
            >
              {this.state.product.price}{" "}
            </span>{" "}
            {Math.ceil(offerPrice)}{" "}
            <span
              style={{
                margin: "0",
                fontWeight: "500",
                padding: "5px 0",
                color: " rgb(5, 156, 136)",
              }}
            >
              ({this.state.product.offer}% off)
            </span>
          </h3>
        );
      } else {
        price = (
          <h3
            style={{
              margin: "0",
              padding: "5px 0 15px 0",
              color: "rgb(7, 131, 104)",
            }}
          >
            {" "}
            BDT: {this.state.product.price}
          </h3>
        );
      }
      if (this.state.product.brand) {
        brand = (
          <p style={{ padding: 0, margin: 0 }}>
            <span style={{ fontWeight: "500" }}>Brand : </span>
            {this.state.product.brand}
          </p>
        );
      }
      if (this.state.product.color) {
        color = (
          <p style={{ padding: 0, margin: 0 }}>
            <span style={{ fontWeight: "500" }}>Color : </span>
            {this.state.product.color}
          </p>
        );
      }

      images =
        // this.state.product.images.
        imagesTest.map((img, id) => {
          if (this.state.activeImage === id) {
            imageStyle = "baz-ActiveImage";
          } else {
            imageStyle = "baz-NotActiveImage";
          }
          return (
            <img
              className={imageStyle}
              key={id}
              style={{ marginBottom: "10px" }}
              onMouseEnter={() => this.imageChangeHandler(id)}
              src={img}
              alt="product images"
            />
          );
        });
      mobileImages =
        // this.state.product.images.map
        imagesTest.map((img, id) => {
          return (
            <div className="baz-mobile-image-container" key={id}>
              <img onClick={this.imageViewerHandler} src={img} alt="img" />
            </div>
          );
        });
      decLists = this.state.product.descriptionPoints.map((dec, id) => {
        return (
          <li key={id} style={{ color: "rgb(71, 69, 69)" }}>
            {dec}
          </li>
        );
      });
      if (this.state.product.sizes.length > 0) {
        let buttonStyle;
        let allSizes = this.state.product.sizes.map((size, id) => {
          buttonStyle =
            this.state.sizeId === size._id
              ? (buttonStyle = "baz-Button2")
              : "baz-Button1";
          if (size.quantity > 0) {
            return (
              <button
                key={id}
                className={buttonStyle}
                onClick={() =>
                  this.sizeHandler(size.name, size._id, size.quantity)
                }
              >
                {size.name}
              </button>
            );
          } else {
            return null;
          }
        });
        sizes = (
          <div className="baz-Sizes">
            <h5>Select Size</h5>
            {allSizes}
          </div>
        );
      }
      let availQuantity = [];
      let buttonStyle;
      for (let i = 1; i <= this.state.availQuantity; i++) {
        buttonStyle =
          this.state.quantity === i
            ? (buttonStyle = "baz-Button2")
            : "baz-Button1";
        availQuantity.push(
          <button
            key={i}
            className={buttonStyle}
            onClick={() => this.quantityHandler(i)}
          >
            {i}
          </button>
        );
      }
      if (this.state.product.stock > 0) {
        selectQuantity = (
          <div className="baz-SelectQuantity">
            <h5>Select Quantity</h5>
            {availQuantity}
          </div>
        );
      } else {
        notInStock = (
          <p style={{ margin: "0", padding: "0 0 15px 0", color: "red" }}>
            Out of Stock
          </p>
        );
      }
      if (this.state.feedbacks.length > 0) {
        feedbacks = this.state.feedbacks.map((review, id) => {
          return (
            <Feedback
              key={review._id}
              id={id}
              reviewId={review._id}
              text={review.text}
              user={review.user}
              date={review.date}
              current_user={this.props.userId}
              updateReviewModalHandler={this.updateReviewModalHandler}
              deleteReviewHandler={this.deleteReviewModalHandler}
            />
          );
        });
      } else {
        feedbacks = (
          <div
            style={{
              width: "100%",
              padding: "30px 0",
              background: "white",
              borderRadius: "2px",
              textAlign: "center",
            }}
          >
            <img
              style={{ width: "150px", height: "150px", borderRadius: "100%" }}
              src={NoFeedBack}
              alt="empty feedback"
            />
            <h4>No feedbacks yet!</h4>
          </div>
        );
      }
      related = this.state.product.related.map((product) => {
        if (this.state.product._id !== product._id) {
          return (
            <Related
              key={product._id}
              id={product._id}
              title={product.title}
              price={product.price}
              offer={product.offer}
              coverImage={product.coverImage}
            />
          );
        } else {
          return null;
        }
      });

      productView = (
        <div className="baz-ProductMain">
          <div className="baz-ProductOverview">
            <div className="baz-ProductImagesMobile">{mobileImages}</div>
            <div className="baz-ProductImages">
              <div className="baz-ImagesList">{images}</div>
              <div className="baz-ImageCanvas">
                <img
                  onClick={this.imageViewerHandler}
                  // src={this.state.product.images[this.state.activeImage]}
                  src={imagesTest[this.state.activeImage]}
                  alt="img"
                />
              </div>
            </div>
            <div className="baz-ProductDetails">
              <div style={{ padding: "20px 0 10px 0" }}>
                <Link
                  className="product-view-cat-link"
                  onClick={this.mainCatHandler}
                  to={"/categories/" + this.state.product.category}
                >
                  {this.state.product.categoryName}
                </Link>{" "}
                /{" "}
                <Link
                  className="product-view-cat-link"
                  onClick={() =>
                    this.subCatSetHandler(this.state.product.subCategory)
                  }
                  to={"/categories/" + this.state.product.category}
                >
                  {this.state.product.subCategoryName}
                </Link>
              </div>
              <h4
                style={{
                  margin: "0",
                  padding: " 0 0 10px 0",
                  fontSize: "23px",
                }}
              >
                VTech Challenger Laptop, Blue, Kids Laptop with Vocabulary, Maths & French Learning Games, 2 Player Kids Computer, 
                Educational Toy Computer for Kids, Fun Laptop, Boys and Girls Ages 4 Years +
                {/* {this.state.product.title} */}
              </h4>
              {price}
              <p style={{ margin: 0, padding: 0 }}>
                Product code:{" "}
                <span style={{ fontWeight: "500" }}>
                  {this.state.product.productCode}
                </span>
              </p>
              <div style={{ padding: "10px 0" }}>
                {brand}
                {color}
              </div>

              {sizes}
              <div className="baz-SelectQuantity">{selectQuantity}</div>
              {notInStock}
              <div className="baz-Buttons">
                {excellentBtn}
                {cartButton}
              </div>
              <p style={{ padding: "0 15px 20px 0", margin: "0" }}>
                <span style={{ fontSize: "20px", color: "rgb(16, 131, 112)" }}>
                  <FontAwesomeIcon icon={faClock} />
                </span>
                <span>
                  {" "}
                  Delivey will be made within 1 to 7 days depending on product
                  and place. Cash on delivery available.
                </span>
              </p>
            </div>
          </div>
          <div className="baz-ProductOthers">
            <div
              style={{ width: "100%", height: "auto", margin: "10px 0 0 0" }}
            >
              <h3 style={{ margin: "0", padding: "20px 0 0 0" }}>
                Description
              </h3>
              <p>{this.state.product.description}</p>
              <ul>{decLists}</ul>
            </div>
            <h3>Customers feedback</h3>
            <div className="baz-CreateFeedback">
              <div
                to={"/profile/" + this.props.userId}
                className="baz-FeedUser"
              >
                {user}
              </div>
              {feedBackButton}
            </div>
            <div className="baz-AllFeedbacks">{feedbacks}</div>
          </div>
        </div>
      );
      relatedView = (
        <div>
          <h3 className="relatedview-headline">Related products</h3>
          <div className="baz-RelatedProducts">{related}</div>
        </div>
      );
      imageViewer = (
        <div style={{ width: "100%", height: "100%" }}>
          <div className="baz-imageview-canvas-header">
            <h3
              style={{
                float: "left",
                padding: "10px",
                margin: "0",
                height: "50%",
                overflow: "hidden",
                width: "65%",
              }}
            >
              {imageViewerName}
            </h3>
            <button
              onClick={this.imageViewerHandler}
              className="image-canvas-closer"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          <div className="baz-imageview-canvas">
            <div className="baz-imageview-canvas-main">
              <img
                // src={this.state.product.images[this.state.activeImage]}
                src={imagesTest[this.state.activeImage]}
                alt="images-view"
              />
            </div>
          </div>
          <div className="baz-imageview-all-images">{images}</div>
        </div>
      );
    } else {
      productView = <OnLoad />;
    }

    return (
      <div className="single-product">
        <FeedbackDeleteModal
          deleteReviewHandler={this.deleteReviewHandler}
          show={this.state.deleteFeedback}
          cancelHandler={this.deleteReviewModalCancelHandler}
        ></FeedbackDeleteModal>
        <AddToCartModal show={this.state.cartProcessing}></AddToCartModal>
        <UpdateReviewModal
          show={this.state.updateReviewModal}
          updateReviewHandler={this.updateReviewModalHandler}
        >
          <div style={{ width: "95%", height: "90%", margin: "1.5% 0 0 2.5%" }}>
            <h4 style={{ margin: "0", padding: "3px 0 0 0", float: "left" }}>
              Update feedback
            </h4>
            <button
              onClick={() => this.updateReviewModalHandler(1)}
              className="baz-CancelReviewUpdateModal"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <textarea
              style={{
                width: "96%",
                padding: "10px",
                height: "50%",
                font: "inherit",
                borderRadius: "5px",
                margin: "10px 0",
                resize: "none",
                outline: "none",
                border: "1px solid #eee",
              }}
              name="updateReviewText"
              onChange={this.handleChange}
              defaultValue={this.state.updateReview.text}
            />
            <button
              onClick={this.updateReviewHandler}
              className="baz-updatefeedback-button"
            >
              Update feedback
            </button>
          </div>
        </UpdateReviewModal>
        <CreateReviewModal
          show={this.state.reviewModal}
          createReviewHandler={this.reviewModalHandler}
        >
          <div className="ReviewForm">
            <div style={{ width: "100%", paddingTop: "10px", height: "60px" }}>
              <div className="RevUser">
                <div
                  style={{
                    width: "50px",
                    height: "50px",
                    background: "rgb(7, 131, 104)",
                    borderRadius: "100%",
                    float: "left",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {user}
                </div>
              </div>
              <button
                onClick={this.reviewModalHandler}
                className="baz-CancelReviewModal"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
            <textarea
              name="feedbackText"
              onChange={this.handleChange}
              placeholder="Write feedback"
            />
            <button
              onClick={this.createFeedbackHandler}
              className="FeedBackButton"
            >
              Feedback
            </button>
          </div>
        </CreateReviewModal>
        <ImageViewer
          show={this.state.imageViewer}
          imageViewHandler={this.imageViewerHandler}
        >
          {imageViewer}
        </ImageViewer>
        {productView}
        {relatedView}
        <Footer />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth,
    userId: state.auth.userId,
    userName: state.auth.userName,
    cartItem: state.auth.cartItem,
    userSetup: state.auth.userSetup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cartAdd: async (quantity) => {
      dispatch({
        type: actionTypes.CART_ADD,
        quantity: quantity,
      });
    },
    userUpdateSetup: (data) => {
      dispatch({
        type: actionTypes.USER_UPDATE_SETUP,
        data: data,
      });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductView);
