import React from 'react';

import { Link } from 'react-router-dom';
import './Order.css';

const order = (props) =>{
    const products = props.order.orderedProducts.map((product,id) =>{
        return<img key={id} src={product.product.images[0]} alt="p_image"/>
    });
    return(
        <div onClick={()=>props.orderViewHandler(props.id)} className="AdminOrder">
        
            <h4 style={{padding:" 20px 0 10px 10px"}}>{props.order.orderNo}</h4>
            <Link to={"/profile/"+props.order.user._id}>{props.order.user.user_name}</Link>
            <p style={{padding:"5px 10px", margin:"0"}}>Deliver at: {props.order.country}, {props.order.city}, {props.order.address}</p>
          <p style={{padding:"5px 10px", margin:"0"}}>Ordered {props.order.orderedProducts.length} products</p>
          <div className="baz-ad-AllPro">
             {products}
          </div>
        </div>
    );
};

export default order;