import React from 'react';

import './OnLoadInterest.css';

const onLoadInterest = () =>(
    <div style={{width:"100%"}}>
        <div className="on-load-interest-header"></div>
        <div className="OnLoadInterests">
            <div className="OnLoadIn"></div>
            <div className="OnLoadIn"></div>
            <div className="OnLoadIn"></div>
            <div className="OnLoadIn"></div>
            <div className="OnLoadIn"></div>
        </div>
        <div className="OnLoadInterestsProducts">
           <div className="OnLoadInProduct"></div>
           <div className="OnLoadInProduct"></div>
           <div className="OnLoadInProduct"></div>
           <div className="OnLoadInProduct"></div>
           <div className="OnLoadInProduct"></div>
           <div className="OnLoadInProduct"></div>
           <div className="OnLoadInProduct"></div>
           <div className="OnLoadInProduct"></div>
           
        </div>
    </div>
);

export default onLoadInterest;