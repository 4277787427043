import React,{Component} from 'react';
import {Route, NavLink, Redirect} from 'react-router-dom';

import axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import './ControlOrders.css';
import PendingOrders from './PendingOrders/PendingOrders';
import ConfirmedOrders from './ConfirmedOrders/ConfirmedOrders';
import ShippedOrders from './ShippedOrders/ShippedOrders';
import DeliveredOrders from './DeliveredOrders/DeliveredOrders';
import ReturnedOrders from './ReturnedOrders/ReturnedOrders';
import CancelledOrders from './CancelledOrders/CancelledOrders';
import OrderSearch from './OrderSearch/OrderSearch';

import { url} from '../../../utlis/server';

class ControlOrders extends Component{
    state = {
        redirect:null,
        orderNo:'',
        orders:[]
    }
    handleChange = event => {
        const { name, value} = event.target
        this.setState({ [name]: value})
    }
    orderSearchHandler = async() =>{
        try {
           if(this.state.orderNo){
            const orders = await axios.post(
               url,
                {
                    query:`
                       query{
                         adminOrderSearch(orderNo:"${this.state.orderNo}"){
                            _id
                            orderNo
                            subTotal
                            deliveryFee
                            total
                            paid
                            date
                            city
                            address
                            country
                            shipped
                            pending
                            confirmed
                            contact_name
                            contact_phone
                            user{
                              _id
                              user_name
                            }
                            orderedProducts{
                                quantity
                                size
                                product{
                                  _id
                                  images
                                  price
                                }
                              }
                           }
                       }
                    `
                }
            );
            this.setState({
                orders: orders.data.data.adminOrderSearch,
                redirect: <Redirect to="/dek-ki-gotce/aj-k/move-forward/orders/search"/>
            });
           }
        } catch (error) {
            throw error;
        }
    }
    render(){
        return(
            <div style={{width:"100%"}}>
                {this.state.redirect}
                <div className="baz-control-order-nav">
                  <div style={{float:"left"}}>
                   <NavLink activeStyle={{color:"green", borderBottom:"1px solid green"}} exact to="/dek-ki-gotce/aj-k/move-forward/orders">Pending</NavLink>
                    <NavLink activeStyle={{color:"green", borderBottom:"1px solid green"}}  exact to="/dek-ki-gotce/aj-k/move-forward/orders/confirmed">Confirmed</NavLink>
                    <NavLink activeStyle={{color:"green", borderBottom:"1px solid green"}}  exact to="/dek-ki-gotce/aj-k/move-forward/orders/shipped">Shipped</NavLink>
                    <NavLink activeStyle={{color:"green", borderBottom:"1px solid green"}}  exact to="/dek-ki-gotce/aj-k/move-forward/orders/delivered">Delivered</NavLink>
                    <NavLink activeStyle={{color:"green", borderBottom:"1px solid green"}}  exact to="/dek-ki-gotce/aj-k/move-forward/orders/returned">Returned</NavLink>
                    <NavLink activeStyle={{color:"green", borderBottom:"1px solid green"}}  exact to="/dek-ki-gotce/aj-k/move-forward/orders/cancelled">Cancelled</NavLink>
                  </div>
                  <div style={{float:"right"}}>
                    <input name="orderNo" onChange={this.handleChange} type="text" placeholder="Order no"/>
                    <button onClick={this.orderSearchHandler} to={"/control-panel/orders/"+this.state.orderNo} style={{padding:"5px", margin:"0 10px 0 0", border:"1px solid #ccc"}}><FontAwesomeIcon icon={faSearch}/></button>
                  </div>
                </div>
                 <div style={{width:"95%",height:"auto", marginLeft:"2.5%"}}>
                     <Route path="/dek-ki-gotce/aj-k/move-forward/orders" exact component={PendingOrders}/>
                     <Route path="/dek-ki-gotce/aj-k/move-forward/orders/confirmed" exact component={ConfirmedOrders}/>
                     <Route path="/dek-ki-gotce/aj-k/move-forward/orders/shipped" exact component={ShippedOrders}/>
                     <Route path="/dek-ki-gotce/aj-k/move-forward/orders/delivered" exact component={DeliveredOrders}/>
                     <Route path="/dek-ki-gotce/aj-k/move-forward/orders/returned" exact component={ReturnedOrders}/>
                     <Route path="/dek-ki-gotce/aj-k/move-forward/orders/cancelled" exact component={CancelledOrders}/>
                     <Route
                        path='/dek-ki-gotce/aj-k/move-forward/orders/search'
                        render={(props) => (
                            <OrderSearch {...props} orders={this.state.orders} />
                        )}
                        />
                </div>
            </div>
        );
    }
}

export default ControlOrders;