import React, { useEffect, useState } from "react";
import { url } from "../../../utlis/server";
import axios from "axios";
import "./ControlCategories.css";
import SubCat from "./subcat";

function ControlCategories() {
  const [categories, setCategories] = useState([]);
  const [complete, setComplete] = useState(false);
  const fetchCategories = async () => {
    const categories = await axios.post(url, {
      query: `
             query{
                categories{
                    _id
                    name
                    logo
                    subCategories{
                        _id
                        name
                        mainCategory
                        customers
                        coverImage
                    }
                  }
             }
            `,
    });
    if (categories.data.data.categories) {
      setCategories(categories.data.data.categories);
      setComplete(true);
    }
  };
  useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <div className="Control-categories">
      {complete ? (
        categories.map((cat) => {
          return (
            <div className="cat-manage">
              <h3>{cat.name}</h3>
              <img src={cat.logo} alt="logo" />
              <div className="subcat-manage">
                <SubCat />
                <SubCat />
                <SubCat />
                <SubCat />
                {/* <SubCat /> */}
                <div className="addmore-subcat"></div>
              </div>
            </div>
          );
        })
      ) : (
        <div>
          <p>Loading...</p>
        </div>
      )}
    </div>
  );
}

export default ControlCategories;
