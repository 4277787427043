import React from 'react';

import './OnLoadProfile.css';

const onLoadProfile = ()=>(
    <div className="OnLoadProfile">
        <div style={{width:"100%", height:"50vh", background:"white", borderRadius:"5px", textAlign:"center"}}>
            <div className="on-load-profile-pic"></div>
            <div style={{width:"45%", height:"40px", borderRadius:"5px", background:"#dad8d8", margin:" 10px 0 0 27%"}}></div>
            <div style={{width:"35%", height:"40px",borderRadius:"5px", background:"#dad8d8", margin:" 10px 0 0 32%"}}></div>
        </div>
    </div>
);

export default onLoadProfile;