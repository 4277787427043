import React from 'react';

import { Link } from 'react-router-dom';

import './HistoryProduct.css';

const bazProduct = (props) => {
    let offer, price;
    if(props.offer> 0){
       offer = <h4 style={{padding:"0", margin:"0", color:"rgb(5, 156, 136)"}}>{props.offer}% off</h4>;
        let offerPrice = props.price - ((props.price * props.offer) / 100);
        price = <h4 style={{padding:"4px 0", margin:"0"}}> BDT: <span style={{textDecoration:"line-through", color:"gray", fontSize:"12px"}}>{props.price} </span> {Math.ceil(offerPrice)}</h4>
    }else{
       price = <h4 style={{padding:"4px 0", margin:"0"}}> BDT: {props.price}</h4>
    }
    return(
        <div className="baz-history-product">
         <Link to={"/product/"+props.id} >
         <div style={{width:"35%",height:"100%", float:"left", position:"relative"}}>
             <img src={props.coverImage} alt="P_image" loading="lazy"/>
         </div>
            <div className="baz-history-product-others">
              <p style={{margin:"0", padding:"10px 10px 5px 0"}}>{props.title}</p>
              {offer}
              {price}
            </div>
         </Link>
        </div>
        
    );
};

export default bazProduct;