import React from 'react';

import './OnLoadView.css';

const onLoadView = () =>(
    <div style={{width:"93%", marginLeft:"3.5%", height:"82vh"}}>
      <div className="baz-product-skeleton-web">
       <div className="OnLoadViewLeft">
            <div className="OnLoadViewLeftImages">
                <div style={{width:"100%", height:"80px", background:"white", marginBottom:"10px", borderRadius:"5px",animation:"mymove 1s infinite"}}></div>
                <div style={{width:"100%", height:"80px", background:"white", marginBottom:"10px", borderRadius:"5px",animation:"mymove 1s infinite"}}></div>
                <div style={{width:"100%", height:"80px", background:"white", marginBottom:"10px", borderRadius:"5px",animation:"mymove 1s infinite"}}></div>
                <div style={{width:"100%", height:"80px", background:"white", marginBottom:"10px", borderRadius:"5px",animation:"mymove 1s infinite"}}></div>
                 <div style={{width:"100%", height:"80px", background:"white", marginBottom:"10px", borderRadius:"5px",animation:"mymove 1s infinite"}}></div>
            </div>
            <div className="OnLoadViewLeftImage">
              
            </div>
           
        </div>
      
        <div className="OnLoadViewRight">
           <div style={{width:"50%",height:"40px",background:"white", marginTop:"10px", borderRadius:"5px",animation:"mymove 1s infinite"}}></div>
           <div style={{width:"100%",height:"40px",background:"white", marginTop:"10px", borderRadius:"5px",animation:"mymove 1s infinite"}}></div>
           <div style={{width:"60%",height:"40px",background:"white", marginTop:"10px", borderRadius:"5px",animation:"mymove 1s infinite"}}></div>

           <div style={{width:"100%",height:"auto", marginTop:"30px", borderRadius:"5px", textAlign:"left"}}>
             <button style={{padding:"23px",background:"white", border:"none", outline:"none", borderRadius:"5px", marginRight:"8px",animation:"mymove 1s infinite"}}></button>
             <button style={{padding:"23px",background:"white", border:"none", outline:"none", borderRadius:"5px",marginRight:"8px",animation:"mymove 1s infinite"}}></button>
             <button style={{padding:"23px",background:"white", border:"none", outline:"none", borderRadius:"5px",marginRight:"8px",animation:"mymove 1s infinite"}}></button>
             <button style={{padding:"23px",background:"white", border:"none", outline:"none", borderRadius:"5px",marginRight:"8px",animation:"mymove 1s infinite"}}></button>
             <button style={{padding:"23px",background:"white", border:"none", outline:"none", borderRadius:"5px",marginRight:"8px",animation:"mymove 1s infinite"}}></button>
             <button style={{padding:"23px",background:"white", border:"none", outline:"none", borderRadius:"5px",marginRight:"8px",animation:"mymove 1s infinite"}}></button>
           </div>
           <div style={{width:"100%",height:"auto", marginTop:"40px", borderRadius:"5px", textAlign:"left"}}>
             <button style={{padding:"23px 0", width:"21%",background:"white", border:"none", outline:"none", borderRadius:"5px", marginRight:"8px",animation:"mymove 1s infinite"}}></button>
             <button style={{padding:"23px 0", width:"21%",background:"white", border:"none", outline:"none", borderRadius:"5px",marginRight:"8px",animation:"mymove 1s infinite"}}></button>
             <button style={{padding:"23px 0",width:"50%",background:"white", border:"none", outline:"none", borderRadius:"5px",marginRight:"8px",animation:"mymove 1s infinite"}}></button>
           </div>
           
        </div>
       </div>
      <div  className="baz-product-skeleton-mobile">
          <div style={{width:"100%", height:"350px", background:"white",marginBottom:"15px", borderRadius:"5px",}}></div>
          <div style={{width:"100%", height:"50px", background:"white",marginBottom:"15px", borderRadius:"5px",}}></div>
          <div style={{width:"90%", height:"50px", background:"white", borderRadius:"5px"}}></div>
      </div>
       
    </div>
);

export default onLoadView;