import React from 'react';

import './ReviewUpdateModal.css';

import Backdrop from '../BackDrop2/BackDrop2';

const createReviewModal = (props) => (
   <div>
     <Backdrop show={props.show} clicked={props.updateReviewHandler} />
     <div
     className="ReviewUpdateModal"
     style={{
        transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1': '0'

    }}
     >
        {props.children}
    </div>
   </div>
);

export default createReviewModal;