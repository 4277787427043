import React from 'react';

import './Product.css';

const product = (props) =>{
    return(
        <div className="ex-ProductCatOption"></div>
    );
};

export default product;