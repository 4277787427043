import React from 'react';

import { Link } from 'react-router-dom';

import './Notification.css';

const notification = (props) => {
    let notify;
    if(props.orderId){
       notify=<p>Your order <span style={{fontWeight:"500"}}>{props.orderId}</span> {props.notifyText}</p>
    }else{
       notify = <p>{props.notifyText}</p>
    }
    return(
       <div className="Notification"onClick={props.notificationHandler}>
        <Link to={props.link} >
            <div className="baz-notification-img-container">
              <img src={props.imgLink} alt="notiImg" loading="lazy"/>
            </div>
            {notify}
        </Link>
        </div>
    );
};

export default notification;