import React from 'react';

import Footer from '../../Footer/Footer';

const privacyPolicy = ()=>{
    return(
        <div>
            <div style={{width:"93%", marginLeft:"3.5%"}}>
            <h2 style={{textAlign:"center"}}>Privacy policy</h2>
            <p>
            Welcome to Bazban. Thank you for supporting bazban. In order to protect your personal data when you enjoy shopping at bazban, we draw up this privacy policy, which is actually a contract governing your using of our services.
            Personal data is information that relates to an identified or identifiable individual. Personal information is data that can be used to uniquely identify or contact a single person. You may be asked to provide your personal information anytime you are in contact with www.Bazban.com. Bazban may share this personal information with each other and use it consistent with this Privacy Policy. They may also combine it with other information to provide and improve our products, services, content, and advertising. Bazban may collect the following information:
            Name
            Gender
            Phone number
            Address 
            In order to provide personal information, Bazban send your details to a third party (to courier company) to deliver the order to you. In that case you have to give the correct information, your correct information is always desirable and you must correct it again when you change the information.
            Bazban always hide your information. There is an opportunity to correct your information. If you want to close the account, Please contact bazbanhelps@gmail.com.


            </p>
            We can use your information for some of Bazban working process-
            <ul>
                <li>Process order including purchase & redemption tracking.</li>
                <li>
                For special offer, Delivery, coupon voucher also for notify when Bazban share update news or others. 
                </li>
                <li>
                Security alerts, hotline message.
                </li>
                <li>
                Provide advertisements your facebook, email, phone number message. Show some time video marketing in your phone or other device.
                </li>
                <li>
                When Bazban make any administer survey, sponsor & promotional activities that time we will knock you.
                </li>
            </ul>
            Note: Not applicable at the time of delivery because bazban will give your information to the delivery company while courier the product.

         
        </div>
        <Footer />
        </div>
    );
};

export default privacyPolicy;