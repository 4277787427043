import React from 'react';

import './CartModal.css';
import Backdrop from '../BackDrop2/BackDrop2';

const cartModal = (props) =>(
    <div>
          <Backdrop show={props.show} clicked={props.cartHandler} />
        <div 
           className="CartModal"
           style={{
            transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: props.show ? '1': '0'
    
          }}
           >
          {props.children}
         </div>
    </div>
);

export default cartModal;