import React, { Component } from 'react';

import axios from 'axios';

import './ConfirmedOrders.css';
import Order from '../../../../components/ControlPanelSection/Order/Order';
import OrderViewModal from '../../../../components/Modals/OrderViewModal/OrderViewModal';
import PendingOrder from '../../../../components/ControlPanelSection/PendingOrder/PendingOrder';

import { url} from '../../../../utlis/server';

class ConfirmedOrders extends Component{
    state = {
        orders:[],
        complete: false,
        singleOrder:{},
        orderView: false
    }
    componentDidMount = async() =>{
        try {
            const orders = await axios.post(
               url,
                {
                    query:`
                       query{
                           adminConfirmedOrders{
                            _id
                            orderNo
                            subTotal
                            deliveryFee
                            total
                            paid
                            date
                            city
                            address
                            country
                            shipped
                            pending
                            confirmed
                            contact_name
                            contact_phone
                            user{
                              _id
                              user_name
                            }
                            orderedProducts{
                              quantity
                              size
                              product{
                                _id
                                images
                                price
                              }
                            }
                           }
                       }
                    `
                }
            );
            this.setState({
                orders: orders.data.data.adminConfirmedOrders,
                complete: true
            });
        } catch (error) {
            throw error;
        }
    };
    orderViewHandler = async(id) =>{
        const order = await axios.post(
           url,
            {
                query:`
                   query{
                       order(orderId:"${id}"){
                        _id
                        orderNo
                        subTotal
                        deliveryFee
                        total
                        paid
                        date
                        city
                        address
                        country
                        shipped
                        pending
                        confirmed
                        delivered
                        packaged
                        cancelled
                        returned
                        contact_name
                        contact_phone
                        user{
                          _id
                          user_name
                          location
                        }
                        orderedProducts{
                          quantity
                          size
                          product{
                            title
                            _id
                            images
                            price
                            productCode
                          }
                        }
                       }
                   }
                `
            }
        );
        this.setState({
            singleOrder: order.data.data.order,
            orderView: !this.state.orderView
        });
    }
    orderCloseHandler = () =>{
        this.setState({
            orderView: false
        });
    };
    orderShippedHandler = async(id) =>{
        try {
            const order = await axios.post(
               url,
                {
                    query:`
                       mutation{
                        shippedOrder(orderId:"${id}"){
                            _id
                            orderNo
                            subTotal
                            deliveryFee
                            total
                            paid
                            date
                            city
                            address
                            country
                            shipped
                            pending
                            confirmed
                            contact_name
                            contact_phone
                            user{
                              _id
                              user_name
                            }
                            orderedProducts{
                              quantity
                              size
                              product{
                                _id
                                images
                                price
                              }
                            }
                        }
                       }
                    `
                }
            );
            this.setState({
                orders: order.data.data.shippedOrder,
                orderView: false

            });
        } catch (error) {
            throw error;
        }
    }
    render(){
        let orders, header, orderView;
        if(this.state.complete){
            if(this.state.orders.length > 0){
                orders = this.state.orders.map(order => {
                    return<Order
                                key={order._id}  
                                id={order._id}
                                order={order}
                                orderViewHandler={this.orderViewHandler}
                             
                              />
                });
            }
            
            this.state.orders.length > 0 ? header = <h4>{this.state.orders.length} confirmed orders</h4>: header=<h4>No confirmed order</h4> 
        };
        if(this.state.orderView){
            orderView =   <PendingOrder 
                        order={this.state.singleOrder}
                        user={this.state.singleOrder.user}
                        orderViewHandler={this.orderCloseHandler}
                        shippedHandler ={this.orderShippedHandler}
                        />
        }
        return(
            <div className="AdminConfirmedOrders">
                 <OrderViewModal show={this.state.orderView} orderViewHandler={this.orderCloseHandler}>
                    {orderView}
                </OrderViewModal>
              {header}
              <div className="baz-ad-AllOrders">
                   {orders}
              </div>
            </div>
        );
    };
};

export default ConfirmedOrders;