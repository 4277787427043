import React, { Component } from "react";

import { Route, NavLink, Redirect } from "react-router-dom";

import "./ControlPanel.css";
import ControlProducts from "./ControlProducts/ControlProducts";
import ControlOrders from "./ControlOrders/ControlOrders";
import ControlCategories from "./ControlCategories/ControlCategories";
import AddProduct from "./AddProduct/AddProduct";
// import ControlUsers from './ControlUsers/ControlUsers';

import AdminCheckModal from "../../components/Modals/AdminCheckModal/AdminCheckModal";

class ControlPanel extends Component {
  state = {
    show: true,
    complete: false,
    checkInput: "",
    redirect: null,
  };
  componentDidMount = async () => {
    try {
      if (localStorage.hotathcheck === "amitumakevalobasina") {
        this.setState({
          show: false,
          complete: true,
        });
      } else {
        this.setState({
          show: true,
          complete: true,
        });
      }
    } catch (error) {
      throw error;
    }
  };
  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };
  adminCheckHandler = () => {
    if (
      this.state.checkInput ===
      "sobmoulariccharesiyamdekisekdinhobeamraoparbo-valotakis"
    ) {
      localStorage.setItem("hotathcheck", "amitumakevalobasina");
      this.setState({
        show: false,
      });
    }
  };
  adminLogOutHandler = () => {
    localStorage.removeItem("hotathcheck");
    this.setState({
      show: true,
      redirect: <Redirect to="/" />,
    });
  };
  render() {
    let admin;
    if (this.state.complete) {
      admin = (
        <div className="baz-control-panel-main">
          {this.state.redirect}
          <AdminCheckModal show={this.state.show}>
            <div className="baz-control-panel-check">
              <input
                type="text"
                placeholder="What do you want?"
                name="checkInput"
                onChange={this.handleChange}
              />
              <button onClick={this.adminCheckHandler}>Search</button>
            </div>
          </AdminCheckModal>
          <div className="baz-ad-nav">
            <NavLink
              activeStyle={{ borderBottom: "2px solid green" }}
              exact
              to="/dek-ki-gotce/aj-k/move-forward"
            >
              Products
            </NavLink>
            <NavLink
              activeStyle={{ borderBottom: "2px solid green" }}
              to="/dek-ki-gotce/aj-k/move-forward/orders"
            >
              Orders
            </NavLink>
            <NavLink
              activeStyle={{ borderBottom: "2px solid green" }}
              exact
              to="/dek-ki-gotce/aj-k/move-forward/users"
            >
              Users
            </NavLink>
            <NavLink
              activeStyle={{ borderBottom: "2px solid green" }}
              exact
              to="/dek-ki-gotce/aj-k/move-forward/add-product"
            >
              Add product
            </NavLink>
            <NavLink
              activeStyle={{ borderBottom: "2px solid green" }}
              exact
              to="/dek-ki-gotce/aj-k/move-forward/categories"
            >
              Categories
            </NavLink>
            <button onClick={this.adminLogOutHandler}>Log out</button>
          </div>
          <div style={{ width: "100%", paddingTop: "60px" }}>
            <Route
              path="/dek-ki-gotce/aj-k/move-forward"
              exact
              component={ControlProducts}
            />
            <Route
              path="/dek-ki-gotce/aj-k/move-forward/orders"
              component={ControlOrders}
            />
            <Route
              path="/dek-ki-gotce/aj-k/move-forward/categories"
              component={ControlCategories}
            />
            <Route
              path="/dek-ki-gotce/aj-k/move-forward/add-product"
              component={AddProduct}
            />
            {/* <Route path="/dek-ki-gotce/aj-k/move-forword/users" exact component={ControlUsers}/> */}
          </div>
        </div>
      );
    } else {
      admin = (
        <h1 style={{ textAlign: "center", paddingTop: "15%" }}>Processing</h1>
      );
    }
    return admin;
  }
}

export default ControlPanel;
