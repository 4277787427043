import React, {Component} from 'react';

import axios from 'axios';

import Order from '../../../../components/ControlPanelSection/Order/Order';
import OrderViewModal from '../../../../components/Modals/OrderViewModal/OrderViewModal';
import PendingOrder from '../../../../components/ControlPanelSection/PendingOrder/PendingOrder';

import { url} from '../../../../utlis/server';

class OrderSearch extends Component{
    state={
        singleOrder:{},
        orderView: false
    }
    orderViewHandler = async(id) =>{
        const order = await axios.post(
           url,
            {
                query:`
                   query{
                       order(orderId:"${id}"){
                        _id
                        orderNo
                        subTotal
                        deliveryFee
                        total
                        paid
                        date
                        city
                        address
                        country
                        shipped
                        pending
                        confirmed
                        contact_name
                        contact_phone
                        user{
                          _id
                          user_name
                          location
                        }
                        orderedProducts{
                          quantity
                          size
                          product{
                            title
                            _id
                            images
                            price
                            productCode
                          }
                        }
                       }
                   }
                `
            }
        );
        this.setState({
            singleOrder: order.data.data.order,
            orderView: !this.state.orderView
        });
    }
    orderCloseHandler = () =>{
        this.setState({
            orderView: false
        });
    }
    orderShippedHandler = async(id) =>{
        try {
           await axios.post(
           url,
                {
                    query:`
                       mutation{
                        shippedOrder(orderId:"${id}"){
                            _id
                        }
                       }
                    `
                }
            );
            this.setState({
                orderView: false

            });
        } catch (error) {
            throw error;
        }
    };
    orderConfirmHandler = async(id) =>{
        try {
            await axios.post(
               url,
                {
                    query:`
                      mutation{
                        confirmOrder(orderId:"${id}"){
                            _id
                        }
                      }
                    `
                }
            );
           this.setState({
               orderView: false
           });
        } catch (error) {
            throw error;
        }
    }
    orderCancelHandler = async(id) =>{
         await axios.post(
           url,
            {
                query:`
                  mutation{
                    cancelOrder(orderId:"${id}"){
                        _id
                    }
                  }
                `
            }
        );
       this.setState({
           orderView: false
       });
    };
    orderDeliverHandler = async(id) =>{
        try {
             await axios.post(
               url,
                {
                    query:`
                      mutation{
                          deliverOrder(orderId:"${id}"){
                             _id
                          }
                      }
                    `
                }
            );
            this.setState({
                orderView: false
            });
            
        } catch (error) {
            throw error;
        }
     };
     
    orderReturnHandler = async(id) =>{
        try {
            await axios.post(
               url,
                {
                    query:`
                      mutation{
                         returnedOrder(orderId:"${id}"){
                             _id
                          }
                      }
                    `
                }
            );
            this.setState({
                orderView: false
            });
            
        } catch (error) {
            throw error;
        }
     };
    render(){
        let orders, orderView;
        if(this.state.orderView){
            orderView =   <PendingOrder 
            order={this.state.singleOrder}
            user={this.state.singleOrder.user}
            orderConfirmHandler={this.orderConfirmHandler}  
            orderCancelHandler={this.orderCancelHandler}
            shippedHandler={this.orderShippedHandler}
            orderDeliverHandler={this.orderDeliverHandler}
            orderViewHandler={this.orderCloseHandler}
            orderReturnHandler={this.orderReturnHandler}
            />;
        }
        if(this.props.orders.length > 0){
           orders = this.props.orders.map(order => {
                return<Order
                          key={order._id}  
                          id={order._id}
                          order={order}
                          orderViewHandler={this.orderViewHandler}
                          />
            });
        }else{
            orders = <div>
                          <h1>Orders not found</h1>
                     </div>
        }
        
        return(
            <div style={{width:"100%"}}>
                 <OrderViewModal show={this.state.orderView} orderViewHandler={this.orderCloseHandler}>
                    {orderView}
                </OrderViewModal>
               <div className="baz-ad-AllOrders">
                   {orders}
               </div>
            </div>
        );
    };
    }

   

export default OrderSearch;