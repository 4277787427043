import React from 'react';

import './NotificationModal.css';
import Backdrop from '../Backdrop/Backdrop';

const notificationModal = (props) =>(
    <div>
          <Backdrop show={props.show} clicked={props.notificationHandler} />
        <div 
           className="NotificationModal"
           style={{
            transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: props.show ? '1': '0'
    
          }}
           >
          {props.children}
         </div>
    </div>
);

export default notificationModal;