import React from 'react';

import './AdminCheckModal.css';

import Backdrop from '../BackDrop3/BackDrop3';

const adminCheckModal = (props) => (
   <div>
     <Backdrop show={props.show}/>
     <div
     className="AdminCheckModal"
     style={{
        transform: props.show  ? 'translateY(0)' : 'translateY(-100vh)',
        opacity: props.show ? '1': '0'

    }}
     >
        {props.children}
    </div>
   </div>
);

export default adminCheckModal;