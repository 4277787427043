import React,{ useEffect} from 'react';

import {BrowserRouter, Route, Switch} from 'react-router-dom';
import ReactGa from 'react-ga';

import './App.css';
import AppLayout from './containers/Layout/Layout';
import Home from './containers/Home/Home';
import Interests from './containers/Interests/interests';
import Category from './containers/AllCategories/allCategories';
import ProductView from './containers/ProductView/ProductView';
import UserProfile from './containers/UserProfile/UserProfile';
import ExploreCategory from './containers/ExploreCategory/ExploreCategory';
import Orders from './containers/Orders/Orders';
import SignIn from './containers/UserControl/SignIn/Signin';
import SignUp from './containers/UserControl/SignUp/SignUp';
import SearchResult from './containers/SearchResult/SearchResult';
import PrivacyAndSettings from './containers/UserPrivacyAndSettings/UserPrivacyAndSettings';
import WishList from './containers/WishList/WishList';
import ControlPanel from './containers/ControlPanel/ControlPanel';
import About from './components/Baz/About/About';
import History from './containers/UserHistory/UserHistory';
import NotFound from './components/NotFound/NotFound';
import PrivacyPolicy from './components/Baz/PrivacyPolicy/PrivacyPolicy';
import Terms from './components/Baz/Terms/Terms';
import Conatct from './components/Baz/Contact/Contact';

import { io } from "socket.io-client";

function App() {
  useEffect( () => {
       ReactGa.initialize('UA-180094154-1');

       ReactGa.pageview('/');
  },[]);

  let privacySettings;
  if(localStorage.TOKEN){
    privacySettings = <Route path="/privacy-settings" exact component={PrivacyAndSettings}/>;
  }
  const socket = io();
  socket.emit('chat message', "input.value")
  console.log("Socket", socket);
  return (
    <BrowserRouter>
       <div className="App">
        <AppLayout>
          <Switch>
           <Route path="/" exact component={Home}/>
           <Route path="/categories" exact component={Category}/>
           <Route path="/interests" exact component={Interests}/>
           <Route path="/product/:productId" exact component={ProductView}/>
           <Route path="/profile/:userId" exact component={UserProfile}/>
           <Route path="/categories/:catId" exact component={ExploreCategory} />
           <Route path="/orders"  component={Orders}/>
           <Route path="/user/sign-in" exact component={SignIn}/>
           <Route path="/user/sign-up" exact component={SignUp}/>
           <Route path="/results/:searchKey" exact component={SearchResult}/>
           {privacySettings}
           <Route path="/wishlist" exact component={WishList}/>
           <Route path="/dek-ki-gotce/aj-k/move-forward" component={ControlPanel}/>
           <Route path="/about" exact component={About}/>
           <Route path="/history" exact component={History}/>
           <Route path="/privacy-policy" exact component={PrivacyPolicy}/>
           <Route path="/terms-conditions" exact component={Terms}/>
           <Route path="/contact" exact component={Conatct}/>
           <Route component={NotFound}/>
          </Switch>
          {
            console.log('%Hello world', 'color: blue')
          }
        </AppLayout>
       </div>
    </BrowserRouter>
  );
}

export default App;
