import React from 'react';

import Footer from '../../Footer/Footer';
import Logo from '../../../assets/logo.svg';

import './Contact.css';

const contact = () =>{
    return(
        <div>
             <div className="baz-contact">
                {/* <h2>Contact us</h2> */}
                <img className="baz-contact-logo" src={Logo} alt="bazban"/>
                <p>Contact no : 01571-731874</p>
                <p>Email: bazbanoffice@gmail.com</p>
            </div>
            <Footer />
        </div>
    );
};

export default contact;