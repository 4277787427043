import React from 'react';
import {Link} from 'react-router-dom';
import  './Product.css';

const product = (props) => {
    return(
        <tbody>
            <tr className="CProduct">
                <td>
                    <Link to={"/product/"+props.id}>
                      <img src={props.images[0]} alt="img"/>
                      <h5>{props.title}</h5>
                    </Link>
                </td>
                <td style={{textAlign:"center"}}>{props.price}</td>
                <td  style={{textAlign:"center"}}>{props.code}</td>
                <td  style={{textAlign:"center"}}>{props.stock}</td>
                <td  style={{textAlign:"center"}}>{props.offer}%</td>
                <td  style={{textAlign:"center"}}>{props.sales}</td>
               <td  style={{textAlign:"center"}}>{props.views}</td>
           </tr>
        </tbody>
    );

};

export default product;