import React from 'react';

import './OnCat.css';

const onCat = ()=>(
    <div className="HomeOnCat">
        <div className="onload-cat-home-logo"></div>
        <div className="onload-cat-home"></div>
    </div>
);

export default onCat;