import React, { Component } from 'react';

import axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import * as actionTypes from '../../store/actions/actionTypes';
import 'react-lazy-load-image-component/src/effects/blur.css';

import './Home.css';
import OnLoad from '../../components/HomeSection/OnLoad/OnLoad';
import Category from '../../components/HomeSection/Category/Category';
import ProductView1 from '../../components/HomeSection/ProductView1/ProductView1';
import CollectionView1 from '../../components/HomeSection/CollectionView1/CollectionView1';
import CollectionView2 from '../../components/HomeSection/CollectionView2/CollectionView2';
import SubCategory from '../../components/HomeSection/SubCategory/SubCategory';
import Footer from '../../components/Footer/Footer';

import { url} from '../../utlis/server';

class Home extends Component{
    state = {
        complete: false
    }
    componentDidMount = async() => {
        try {
            window.scrollTo(0, 0);
            document.title = "Bazban"
            if(!this.props.homeComplete){
                const newsFeed = await axios.post(
                    url,
                    {
                        query:`
                          query{
                            newsFeed{
                                categories{
                                    _id
                                    name
                                    logo
                                  }
                                  offers{
                                    _id
                                   }
                                   recommended{
                                    _id
                                    title
                                    coverImage
                                    price
                                    offer
                                  }
                                  allSubCategories{
                                    _id
                                    name
                                    mainCategory
                                    customers
                                    coverImage
                                  }
                                 catProducts{
                                  _id
                                  name
                                  logo
                                  feturedProducts{
                                    _id
                                    title
                                    coverImage
                                    price
                                    offer
                                  }
                                  
                                }
                            }
                          }
                        `
                    }
                );
                this.props.homeSetup(newsFeed.data.data.newsFeed);
            }
            if(localStorage.TOKEN){
                let headers = {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer '+localStorage.TOKEN
                  };
                const user = await axios.post(
                    url,
                    {
                        query:`
                          query{
                              user{
                                  cartTotal
                                  newNotifications
                              }
                          }`
                    },
                     {"headers": headers}
                );
                this.props.userUpdateSetup(user.data.data.user);
            }
        } catch (error) {
            throw error;
        }
       
    };
    mainCatHandler = () =>{
        localStorage.removeItem("subCat");
    }
    subCatSetHandler = (id) =>{
        localStorage.setItem("subCat", id);
    }

    render(){
        let home, categories, recommended, catProducts, subCategories, mobileSub;
        // if(!localStorage.TOKEN){
        //     userCollect = <div className="baz-user-collect"></div>
        // }
        if(this.props.homeComplete){
            categories = this.props.newsFeed.categories.map(category => {
                return<Category 
                        key={category._id}
                        name={category.name}
                        id={category._id}
                        mainCatHandler={this.mainCatHandler}
                        logo={category.logo}
                        />
            });
            recommended = this.props.newsFeed.recommended.map(reco =>{
                return<ProductView1
                         key={reco._id}
                         id={reco._id}
                         coverImage={reco.coverImage}
                         price={reco.price}
                         title={reco.title}
                         offer={reco.offer}
                         />
          });
          catProducts = this.props.newsFeed.catProducts.map((catProduct, id)=>{
            if(id%2===1 && id<3){
                return<CollectionView1 
                         key={catProduct._id}
                         id={catProduct._id}
                         name={catProduct.name}
                         logo={catProduct.logo}
                         products={catProduct.feturedProducts}
                         mainCatHandler={this.mainCatHandler}
                      />;
            }else if(id%2===0 && id<3){
                return<CollectionView2
                            key={catProduct._id}
                            id={catProduct._id}
                            name={catProduct.name}
                            logo={catProduct.logo}
                            products={catProduct.feturedProducts}
                            mainCatHandler={this.mainCatHandler}
                      />;
            }
            
        });
        subCategories = this.props.newsFeed.allSubCategories.slice(0, 5).map(sub=>{
            let interested;
            if(this.props.interests.includes(sub._id)){
                interested = true
            }else{
                interested = false
            }
            return<SubCategory 
                      key={sub._id}
                      id={sub._id}
                      name={sub.name}
                      mainCategory={sub.mainCategory}
                      interested={interested}
                      subCatSetHandler={this.subCatSetHandler}
                      coverImage={sub.coverImage}
                  />
        });
        mobileSub = this.props.newsFeed.allSubCategories.map(sub=>{
            let interested;
            if(this.props.interests.includes(sub._id)){
                interested = true
            }else{
                interested = false
            }
            return<SubCategory 
                      key={sub._id}
                      id={sub._id}
                      name={sub.name}
                      mainCategory={sub.mainCategory}
                      interested={interested}
                      subCatSetHandler={this.subCatSetHandler}
                      coverImage={sub.coverImage}
                  />
        });
            home=<div className="Home">
                     <div className="baz-home-categories">
                         {categories}
                     </div>
                  {/* <img className="header-img" src="https://img.freepik.com/free-photo/beautiful-asian-woman-carrying-colorful-bags-shopping-online-with-mobile-phone_8087-3877.jpg?size=626&ext=jpg" alt="img"/> */}
                     <div>
                            <h3 className="baz-reco-headline" style={{color:"black", fontSize:"22px"}}>New Arrivals</h3>
                            <div className="RecommendedProducts">
                                  {recommended}
                                  {/* {recommended}
                                  {recommended} */}
                             </div>
                             
                     </div>
                    
                     {catProducts}
                     <div className="baz-allsub-cat-home">
                         <div className="baz-collection2-header">
                           <h3 style={{fontSize:"22px"}}>What are you looking for ?</h3>
                           <div className="baz-collection1-header-see-all">
                              <Link to="/categories" className="collectionview1-seeall-link1">See All <span style={{fontSize:"15px", margin:"5px 0"}}><FontAwesomeIcon icon={faChevronRight}/></span></Link>
                              <Link onClick={this.mainCatHandler} to="/categories" className="collectionview1-seeall-link2"><FontAwesomeIcon icon={faChevronRight}/></Link>
                           </div>
                         </div>
                         <div  className="baz-collection1-header-mobile" style={{width:"94%", marginLeft:"2.5%"}}>
                             
                                <Link style={{textDecoration:"none", color:"black"}} onClick={this.props.mainCatHandler} to="/categories">
                                        <h4 style={{padding:"8px 0", margin:"0", fontSize:"22px",float:"left", width:"81%"}}>What are you looking for ?</h4>
                                    <span style={{float:"right", padding:"12px 0", color:"rgb(7, 131, 104)"}}>See all <FontAwesomeIcon icon={faChevronRight}/></span>
                                </Link>
                          </div>
                         <div className="baz-all-sub">
                             {subCategories}
                         </div>
                         <div className="mobile-sub-home">
                               {mobileSub}
                           </div>
                     </div>
                     <Footer />
                 </div>
        }else{
            home = <OnLoad />
        }
        return(
            <div>
               {home}
            </div>
        );
    };
};

const mapStateToProps = state =>{
    return{
         homeComplete: state.data.homeComplete,
         userSetup: state.auth.userSetup,
         interests:state.auth.interests,
         newsFeed: state.data.newsFeed
    }
}
const mapDispatchToProps = (dispatch) => {
    return{
        homeSetup: (data) =>{
            dispatch({
                type: actionTypes.HOME_DATA,
                data: data
            });  
        },
        userUpdateSetup: (data) =>{
            dispatch({
                type: actionTypes.USER_UPDATE_SETUP,
                data:data
            });
        }
}
}


export default connect(mapStateToProps, mapDispatchToProps)(Home);