import * as actionTypes from '../actions/actionTypes';
import React from 'react';
import {Redirect} from 'react-router-dom';

const initialState ={
    auth: false,
    userId:null,
    userName: null,
    login_error:null,
    signup_error: null,
    phone:null,
    redirectUser: null,
    cartItem:0,
    notification:0,
    interests:[],
    userSetup: false
}
const authReducer = (state=initialState, action ) => {
    switch(action.type){
        case actionTypes.AUTH_SIGN_UP:
           return{
               ...state,
               auth:true,
               userId:action.userId,
               userName:action.userName,
               login_error:null,
               redirectUser: <Redirect to="/"/>,
               cartItem:0,
               phone: action.phone
         
           }
        case actionTypes.LOGIN_ERROR:
            return{
                ...state,
                login_error: action.message
            }
        case actionTypes.SIGNUP_ERROR:
        return{
            ...state,
            signup_error: action.message
        } 
        case actionTypes.AUTH_SIGNIN:
           return{
               ...state, 
               auth:true,
               userId:action.userId,
               userName:action.userName,
               notification:action.notification,
               error:null,
               redirectUser: <Redirect to="/"/>,
               cartItem: action.cartTotal,
               interests:action.interests,
               phone: action.phone
           }
        case actionTypes.USER_UPDATE_SETUP:
            return{
                ...state,
                cartItem: action.data.cartTotal,
                notification:action.data.newNotifications
            }
        case actionTypes.USER_SETUP:
           return{
               ...state, 
               auth:true,
               userId:action.userId,
               userName:action.userName,
               notification:action.notification,
               cartItem: action.cartTotal,
               interests:action.interests,
               userSetup: true,
               phone:action.phone,
               redirectUser: <Redirect to="/"/>
           }
        case actionTypes.USER_SETUP2:
            return{
                ...state,
                userSetup: true
            }
        case actionTypes.LOG_OUT:
            return{
                ...state,
                auth: false,
                userId: null,
                userName: null,
                redirectUser:null,
                interests: []
            }
        case actionTypes.CART_ADD:
            return{
                ...state,
                cartItem: state.cartItem + 1
            }
        case actionTypes.DELETE_CART:
            return{
                ...state,
                cartItem : state.cartItem - 1
            }
        case actionTypes.EMPTY_CART:
            return{
                ...state,
                cartItem: 0
            }
       case actionTypes.CREATE_ORDER:
           return{
               ...state,
               cartItem: 0
           }
        case actionTypes.NOTICATION_HANDLE:
            return{
                ...state,
                notification: 0
            }
        
        case actionTypes.UP_INTEREST:
            return{
                ...state,
                interests: action.userInterests
        }
        case actionTypes.UPDATE_PROFILE:
            return{
                ...state,
                userName: action.userName
            }
        default:
            return state;
    }
}

export default authReducer;