import React from "react";
import { Link } from "react-router-dom";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "./ProductView1.css";
const imageTest ="https://d1jqecz1iy566e.cloudfront.net/large/fa088.jpg";
const productView1 = (props) => {
  let offer, price, title;
  if (props.title.length > 20) {
    title = props.title.substring(0, 20) + "..";
  } else {
    title = props.title;
  }
  if (props.offer > 0) {
    offer = (
      <span style={{ fontWeight: "400", fontSize: "14px", color: "#0DA591" }}>
        {" "}
        ({props.offer}% off)
      </span>
    );
    let offerPrice = props.price - (props.price * props.offer) / 100;
    price = (
      <h4 className="baz-product1-price">
        BDT:{" "}
        <span
          style={{
            textDecoration: "line-through",
            color: "gray",
            fontSize: "12px",
          }}
        >
          {props.price}{" "}
        </span>{" "}
        {Math.ceil(offerPrice)}
        {offer}
      </h4>
    );
  } else {
    price = <h4 className="baz-product1-price"> BDT: {props.price}</h4>;
  }
  return (
    <Link to={"/product/" + props.id} className="baz-productview-1">
      <div className="baz-productview-1-imagecontainer">
        <LazyLoadImage
          // effect="blur"
          alt={"img"}
          // src={props.coverImage}
          src={imageTest}
          // placeholderSrc="LoadingImageStyle"
        />
      </div>
      <div className="baz-productview-1-imagecontainer-details">
      <p>Test Title</p>
        {price}
      </div>
    </Link>
  );
};

export default productView1;
