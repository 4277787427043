import React, { Component} from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import './CollectionView2.css';
import Product from '../ProductView2/ProductView2';

class CollectionView2 extends Component{
    state={
        start:0,
        end:4
    }
    forwardHandler = () =>{
        this.setState({
            start:4,
            end: 8
        });
    };
    backwardHandler = () =>{
        this.setState({
            start:0,
            end: 4
        });
    };
  render(){
    let products = this.props.products.slice(this.state.start, this.state.end);
    const activeProducts = products.map(product =>{
        return<Product
                 key={product._id}
                 id={product._id}
                 title={product.title}
                 coverImage={product.coverImage}
                 price={product.price}
                 offer={product.offer}
                 historyHandler={this.props.historyHandler}
              />
    });
    let backStyle, forStyle;
    this.state.start === 0 ? backStyle = "DisplayNone" : backStyle = "BackSwitcher";
    this.state.end === 8 ? forStyle = "DisplayNone" : forStyle =" ForwardSwitcher";
    const mobileProducts =this.props.products.map(product =>{
        return<Product
        key={product._id}
        id={product._id}
        title={product.title}
        coverImage={product.coverImage}
        price={product.price}
        offer={product.offer}
        historyHandler={this.props.historyHandler}
     />
    });

    return(
        <div className="baz-collectionview2">
            <div className="baz-collection2-header">
                  <h3>{this.props.name}</h3>
                  <div className="baz-collection1-header-see-all">
                     <Link onClick={this.props.mainCatHandler} to={"/categories/"+this.props.id} className="collectionview1-seeall-link1">See All <span style={{fontSize:"15px", margin:"5px 0"}}><FontAwesomeIcon icon={faChevronRight}/></span></Link>
                     <Link onClick={this.props.mainCatHandler} to={"/categories/"+this.props.id} className="collectionview1-seeall-link2"><FontAwesomeIcon icon={faChevronRight}/></Link>
                  </div>
            </div>
            <div  className="baz-collection1-header-mobile" style={{width:"94%", marginLeft:"2.5%"}}>
                <Link style={{textDecoration:"none", color:"black"}} onClick={this.props.mainCatHandler} to={"/categories/"+this.props.id}>
                    <div style={{width:"80%", float:"left"}}>
                        {/* <img style={{width:"40px", height:"40px", float:"left"}} src={this.props.logo} alt={this.props.name} /> */}
                        <img style={{width:"40px", height:"40px", float:"left"}} src="https://cdn-icons-png.flaticon.com/512/3843/3843517.png" alt={this.props.name} />
                        <h4 className="baz-collection1-category-headername">{this.props.name}</h4>
                    </div>
                    <span style={{float:"right", padding:"9px 0", color:"rgb(7, 131, 104)"}}>See all  <FontAwesomeIcon icon={faChevronRight}/></span>
                </Link>
            </div>
            <div className="Switcher">
                <button onClick={this.backwardHandler} className={backStyle}><FontAwesomeIcon icon={faChevronLeft}/></button>
                <button onClick={this.forwardHandler} className={forStyle}><FontAwesomeIcon icon={faChevronRight}/></button>
            </div>
            <div className="collectionview2-products-all">
               {activeProducts}
            </div>
            <div className="collectionview2-products-all-mobile">
                {mobileProducts}
            </div>
        </div>
    );
  }
};

export default CollectionView2;