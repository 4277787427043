import React from "react";

import { Link } from "react-router-dom";

import EmptyImg from "../../assets/JackBag.svg";
import "./Cart.css";
import CartProduct from "./CartProduct/CartProduct";

const cart = (props) => {
  let cart,
    cartProducts,
    cartTotal = 0;
  if (props.auth) {
    if (props.cartItem > 0) {
      cartProducts = props.cartProducts.map((cart, id) => {
        let price = Math.ceil(
          cart.quantity *
            (cart.product.price -
              (cart.product.price * cart.product.offer) / 100)
        );
        cartTotal = cartTotal + price;
        let size,
          availability,
          availQuantity = 0;
        if (cart.quantity <= cart.product.stock) {
          availability = "true";
          availQuantity = cart.product.stock;
        } else {
          availability = "false";
        }
        if (cart.size) {
          size = (
            <p style={{ padding: "0", margin: "0" }}>Size : {cart.size}</p>
          );
          for (let size of cart.product.sizes) {
            if (size._id === cart.sizeId) {
              if (cart.quantity <= size.quantity) {
                availability = "true";
                availQuantity = size.quantity;
              } else {
                availability = "false";
              }
            }
          }
        }
        return (
          <CartProduct
            key={id}
            id={id}
            productId={cart.productId}
            cartId={cart._id}
            product={cart.product}
            quantity={cart.quantity}
            size={size}
            sizeId={cart}
            removeCart={props.removeCart}
            availability={availability}
            availQuantity={availQuantity}
            quantityIncreaseHandler={props.quantityIncreaseHandler}
            quantityDecreaseHandler={props.quantityDecreaseHandler}
            cartHandler={props.cartHandler}
          />
        );
      });
      cart = (
        <div className="Cart">
          {/* <div className="CartMain"> */}
          <div className="CartProductsAll">
            {cartProducts}
            <button onClick={props.cartHandler} className="AddMoreButton">
              Add more
            </button>
          </div>
          <div className="MobileCheckout">
            <h4
              style={{
                padding: "25px 15px",
                margin: "0",
                float: "left",
                color: "rgb(6, 109, 87)",
              }}
            >
              Total - BDT : {cartTotal}
            </h4>
            <button
              style={{ float: "right", margin: "15px" }}
              className="CheckOutLink"
              onClick={props.checkOutHandler}
            >
              Checkout
            </button>
          </div>
          {/* <div className="CartCheckOut">
                   <h5 style={{padding:"60% 0 5px 0", margin:"0", fontWeight:"500", color:"gray"}}>CART TOTAL</h5>
                   <h3 style={{padding:"0", margin:"0"}}> BDT: {cartTotal}</h3>
                   <p style={{fontSize:"15px", color:"gray"}}>Delivery service fee calculated at checkout.</p>
                   <input  type="radio" id="male" name="gender" value="male" defaultChecked/>
                   <label onClick={props.cartHandler} style={{fontSize:"14px"}}  htmlFor="male">I agree to <Link style={{color:"rgb(8, 151, 127)", textDecoration:"none"}} to="/terms-conditions">Terms and conditions </Link></label>
                   <div style={{width:"100%", height:"60px"}}></div>
                   <button className="CheckOutLink "onClick={props.checkOutHandler}>Checkout</button>
               </div> */}
          {/* </div> */}
        </div>
      );
    } else {
      cart = (
        <div className="EmptyCart">
          <div style={{ textAlign: "center" }}>
            <img src={EmptyImg} alt="empty-bag" />
            <h3>Your cart is empty</h3>
            {/* <button onClick={props.cartHandler}>Explore</button> */}
          </div>
        </div>
      );
    }
  } else {
    cart = (
      <div style={{ width: "100%", height: "100%" }}>
        <div style={{ textAlign: "center" }}>
          <h2 className="baz-logout-bag-headline">Log in to see your cart</h2>
          <Link
            onClick={props.cartHandler}
            to="/user/sign-in"
            className="login-to-bag"
          >
            Log in
          </Link>
        </div>
      </div>
    );
  }
  return cart;
};

export default cart;
