import React from 'react';
import {Route, Switch, NavLink, Link} from 'react-router-dom';

import './Orders.css';
import UserPendingOrders from './UserPendingOrders/UserPendingOrders';
import UserConfirmedOrders from './UserConfirmedOrders/UserConfirmedOrders';
import UserDeliveredOrders from './UserDeliveredOrders/UserDeliveredOrders';
import UserShippedOrders from './UserShippedOrders/UserShippedOrders';
import UserCancelledOrders from './UserCancelledOrders/UserCancelledOrders';
import UserReturnedOrders from './UserReturnedOrders/UserReturnedOrders';
import NotFound from '../../components/NotFound/NotFound';

const orders = () =>{
  let orders;
  if(localStorage.TOKEN){
    orders = <div className="OrdersMain">
    <div className="OrdersNav">
      <li>
        <NavLink to="/orders" exact activeStyle={{ color:"green", background:"#F0F2F5"}}>Pending</NavLink>
      </li>
      <li>
        <NavLink to="/orders/confirmed" exact  activeStyle={{ color:"green", background:"#F0F2F5"}}>Confirmed</NavLink>
      </li>
      <li>
        <NavLink to="/orders/shipped" exact  activeStyle={{ color:"green", background:"#F0F2F5"}}>Shipped</NavLink>
      </li>
      <li>
        <NavLink to="/orders/delivered" exact  activeStyle={{ color:"green", background:"#F0F2F5"}}>Delivered</NavLink>
      </li>
      <li>
        <NavLink to="/orders/cancelled" exact  activeStyle={{ color:"green", background:"#F0F2F5"}}>Cancelled</NavLink>
      </li>
      <li>
        <NavLink to="/orders/returned" exact  activeStyle={{ color:"green", background:"#F0F2F5"}}>Returned</NavLink>
      </li>
    </div>
    <div className="OrdersNav2">
        <NavLink to="/orders" exact activeStyle={{borderBottom:"4px solid green", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px", color:"black"}}>Pending</NavLink>
        <NavLink to="/orders/confirmed" exact activeStyle={{borderBottom:"4px solid green", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px",color:"black"}}>Confirmed</NavLink>
        <NavLink to="/orders/shipped" exact activeStyle={{borderBottom:"4px solid green", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px",color:"black"}}>Shipped</NavLink>
        <NavLink to="/orders/delivered" exact activeStyle={{borderBottom:"4px solid green", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px",color:"black"}}>Delivered</NavLink>
        <NavLink to="/orders/cancelled" exact activeStyle={{borderBottom:"4px solid green", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px",color:"black"}}>Cancelled</NavLink>
        <NavLink to="/orders/returned" exact activeStyle={{borderBottom:"4px solid green", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px",color:"black"}}>Returned</NavLink>
    </div>
    <div className="UserOrders">
        <Switch>
            <Route path="/orders" exact component={UserPendingOrders}/>
            <Route path="/orders/confirmed" exact component={UserConfirmedOrders}/>
            <Route path="/orders/delivered" exact component={UserDeliveredOrders}/>
            <Route path="/orders/shipped" exact component={UserShippedOrders}/>
            <Route path="/orders/cancelled" exact component={UserCancelledOrders}/>
            <Route path="/orders/returned" exact component={UserReturnedOrders}/>
            <Route  component={NotFound}/>
        </Switch>
    </div>
</div>
  }else{
    orders = <div className="baz-logout-interest">
                <h2>Log in to see your Orders</h2>
                <p>Without login orders aren't available. To see orders log in to your account.</p>
                <Link to="/user/sign-in" className="baz-interest-login">Log in</Link>
            </div>
  }
    return(
        <div className="Orders">
            {orders}
        </div>
    );
}

export default orders;