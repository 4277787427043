import React from 'react';

import './MenuModal.css';
import Backdrop from '../Backdrop/Backdrop';

const menuModal = (props) =>(
    <div>
          <Backdrop show={props.show} clicked={props.menuHandler} />
        <div 
           className="baz-MenuModal"
           style={{
            transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
            opacity: props.show ? '1': '0'
    
          }}
           >
          {props.children}
         </div>
    </div>
);

export default menuModal;