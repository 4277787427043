import React from 'react';

import  './CheckOutModal.css';

import Backdrop from '../BackDrop2/BackDrop2';

function checkOutModal (props){
   // useEffect(() => {
   //     props.show && document.body.style.overflow === 'hidden';
   //     !props.show && document.body.style.overflow === 'unset';
   // }, [props.show ]);
   return(
      <div>
        <Backdrop show={props.show} clicked={props.checkOutHandler} />
        <div
        className="CheckOutModal"
        style={{
           transform: props.show ? 'translateY(0)' : 'translateY(-100vh)',
           opacity: props.show ? '1': '0'
   
       }}
        >
           {props.children}
       </div>
      </div>
   );
}

export default checkOutModal;