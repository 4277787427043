import React from 'react';

import './Order.css';

const Order = (props) =>{
    let button;
    const images = props.products.map((product, id)=>{
        if(id < 4){
            return<img key={id} src={product.product.images[0]} alt="P_image"/>
        }else{
            return null
        }
    });
    if(props.products.length > 4){
         button = <button>+{props.products.length - 4}</button>
    }
    return(
        <div className="UOrder" onClick={()=>props.orderDetailsHandler(props.id)}>
            <h5 style={{margin:'0', padding:"20px 20px 10px 20px"}}>Order ID : {props.orderNo}</h5>
            <h4 style={{margin:'0', padding:"0 20px"}}>BDT : {props.total}</h4>
             <p  style={{margin:'2px 0 10px 0', padding:"0 20px", fontSize:"14px", color:"gray"}}>Ordered {props.products.length} products</p>
             <div className="AllProd">
                <div className="AllImgs">
                   {images}
                </div>
                 {button}
             </div>
        </div>
    );
};

export default Order;

